import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSize } from '../../../slices/productSlice';

const SIZES = ['Small', 'Medium', 'Large'];

const SelectSize = () => {
  const {size} = useSelector((state) => state.product);
  const dispatch = useDispatch();
  return (
    <div className='flex flex-col gap-y-2 relative'>
      <h2 className='sticky top-0 left-0 right-0  font-semibold font-Lato text-sm sm:text-base py-3 flex items-center gap-x-1'>
        <p className='white'>
          Select Size : 
        </p>
        <p className='text-[#CFFF04]'>
          {size}
        </p>
      </h2>
      <div className='flex justify-between items-center gap-x-6'>
        {
          SIZES.map((currsize, index) => (
            <div key={index}
              className={`${size == currsize ? "bg-neonViolet" : "bg-gray-300 text-gray-400"} 
              cursor-pointer p-[1px] rounded-md sm:rounded-lg w-full text-center`
              }
              onClick={() => dispatch(setSize(currsize))}
            >
              <div className='w-full h-full px-1 py-1 sm:px-2 sm:py-3 rounded-md sm:rounded-lg bg-black text-sm sm:text-base'>
                {currsize}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default SelectSize;
