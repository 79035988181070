import {apiConnector} from "../apiConnector"
import {endpoints} from "../apis";
import { toast } from "react-hot-toast"
import { setLoading, setToken } from "../../slices/authSlice"
import { setUser } from "../../slices/profileSlice";
import { ACCOUNT_TYPE } from "../../utils/constants";


const {
  LOGIN_API,
  SENDOTP_API,
  SIGNUP_API,
  RESETPASSTOKEN_API,
  RESETPASSWORD_API
} = endpoints;


export function sendOtp(email, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...")
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", SENDOTP_API, {
        email,
        checkUserPresent: true,
      })

      if (!response.data.success) {
        throw new Error(response.data.message)
      }

      toast.success("OTP Sent Successfully")
      navigate("/verify-email")
    } catch (error) {
      toast.error("Could Not Send OTP")
    }
    dispatch(setLoading(false))
    toast.dismiss(toastId)
  }
}

export function signUp(
  firstName,
  lastName,
  email, 
  password, 
  confirmPassword,
  image,
  otp,
  navigate
) {
  return async(dispatch) => {
      const toastId = toast.loading("Loading...");
      dispatch(setLoading(true));
      try{
          const response = await apiConnector("POST", SIGNUP_API, {
              firstName, 
              lastName,
              email,
              password,
              confirmPassword,
              image,
              otp
          });

          if(!response.data.success) {
              throw new Error(response.data.message);
          }

          toast.success("Signup Successful");
          navigate("/login");
      } catch(error) {
          toast.error(error?.response?.data?.message);
          navigate("/verify-email");
      }
      dispatch(setLoading(false));
      toast.dismiss(toastId);
  }
}

export function login(
    email, 
    password,
    isVerified, 
    navigate
) {
    return async(dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try{
            const response = await apiConnector("POST", LOGIN_API, {
                email,
                password,
                isVerified,
            });

            if(!response.data.success) {
                throw new Error(response.data.message);
            }
            toast.success("Login Successful");

            dispatch(setToken(response?.data?.token));
            const userImage = response?.data?.user?.image 
            ? response?.data?.user?.image 
            : `https://api.dicebear.com/7.x/adventurer/svg?seed=${response.data.user.firstName} ${response.data.user.lastName}`

            dispatch(setUser({...response.data.user, image: userImage}));
            sessionStorage.setItem("token", JSON.stringify(response?.data?.token));
            sessionStorage.setItem("user", JSON.stringify(response.data.user));
            if(response?.data?.accountType == ACCOUNT_TYPE.ADMIN) {
              navigate('/dashboard/admin');
            }
            else{
              navigate("/dashboard/profile");
            }
        } catch(error) {
            toast.error(error?.response?.data?.message);
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    }
}

export function logout(navigate){
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    // dispatch(resetCart());
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    toast.success("Logged Out");
    navigate("/");
  }
}

export function getPasswordResetToken(email, setEmailSent) {
  return async (dispatch) => {
      const toastId = toast.loading("Loading...");
      dispatch(setLoading(true));
      try{
          const response = await apiConnector("POST", RESETPASSTOKEN_API, {email});
          
          console.log("RESETPASSTOKEN RESPONSE............", response)
          
          if(!response.data.success){
              throw new Error(response.data.message);
          }

          toast.success("Reset Email Sent");
          setEmailSent(true);
      }catch(error){
          console.log("RESETPASSTOKEN ERROR............", error)
          toast.error("Failed to send reset email");
      }
      toast.dismiss(toastId);
      dispatch(setLoading(false));
  }
};

export function resetPassword(password, confirmPassword, token, navigate) {
  return async(dispatch) => {
      const toastId = toast.loading("Loading...");
      dispatch(setLoading(true));
      try{
          const response = await apiConnector("POST", RESETPASSWORD_API, {password, confirmPassword, token});

          console.log("RESETPASSWORD RESPONSE............", response)

          if(!response.data.success){
              throw new Error(response.data.error);
          }

          toast.success("Password reset successful");
          navigate("/login");
      }catch(error){
          console.log("RESETPASSWORD ERROR............", error)
          toast.error("Failed to reset password");
      }
      toast.dismiss(toastId);
      dispatch(setLoading(false));
  }
}
