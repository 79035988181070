import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiEyeSlash, HiMiniEye } from 'react-icons/hi2';
import NeonButton from '../../NeonButton';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { setGoogleAuth, setSignupData } from '../../../slices/authSlice';
import { sendOtp, signUp } from '../../../services/operations/authAPI';
import { decodeJwt } from 'jose';
const SignupForm = ({Heading}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onSubmitHandle = (data) => {
        console.log("SIGNUP FORM DATA BEFORE...", data);
        if(data?.password !== data.confirmPassword) {
            toast.error("Passsword Do Not Match");
            return;
        }
        data.image = null;
        dispatch(setSignupData(data));
        dispatch(sendOtp(data?.email, navigate));
    }

    const handleGoogleSignup = (credentialResponse) => {
        const decode = decodeJwt(credentialResponse.credential);
        const data = {};
        data.firstName = decode?.given_name;
        data.lastName = decode?.family_name;
        data.email = decode?.email;
        data.image = null;
        dispatch(setSignupData(data));
        navigate('/password');
    }

  return (
    <div className='flex flex-col gap-y-6'>
        <h2 className='text-3xl lg:text-4xl font-semibold text-wrap w-4/5'>
            {Heading}
        </h2>

        <form
        className="flex flex-col gap-7"
        onSubmit={handleSubmit(onSubmitHandle)}
        >
            <div className='flex justify-between gap-x-5'>
                <div className='relative w-full'>
                    <input 
                        type="text" 
                        id='firstName'
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg 
                        border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                        dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                        placeholder=" " 
                        {...register("firstName", {required: true})}
                    />
                    <label htmlFor="firstName"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                        -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
                        dark:bg-neonBlack px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                        peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                        rtl:peer-focus:left-auto start-1"
                    >
                        First Name <sup>*</sup>
                    </label>
                    {errors.firstName && (
                        <span className='text-red-500 text-xs absolute'>
                            First Name is required
                        </span>
                    )}
                </div>

                <div className='relative w-full'>
                    <input 
                        type="text" 
                        id='lastName'
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg 
                        border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                        dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                        placeholder=" " 
                        {...register("lastName")}
                    />
                    <label htmlFor="lastName"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                        -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
                        dark:bg-neonBlack px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                        peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                        peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                        rtl:peer-focus:left-auto start-1"
                    >
                        Last Name <sup>*</sup>
                    </label>
                    {errors.lastName && (
                        <span className='text-red-500 text-xs absolute'>
                            Last Name is required
                        </span>
                    )}
                </div>
            </div>

            <div className='relative'>
                <input 
                    type="email" 
                    id='email'
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg 
                    border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" " 
                    {...register("email", {required: true})}
                />
                <label htmlFor="email"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
                    dark:bg-neonBlack px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                    rtl:peer-focus:left-auto start-1"
                >
                    Email <sup>*</sup>
                </label>
                
                {errors.email && (
                    <span className='text-red-500 text-xs absolute'>
                        Email is required
                    </span>
                )}
            </div>

            <div className='relative'>
                <input 
                    type={showPassword ? "text" : "password"}
                    id='password' 
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg 
                    border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" " 
                    {...register("password", {required: true})}
                />
                <label htmlFor="password"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
                    dark:bg-neonBlack px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                    rtl:peer-focus:left-auto start-1"
                >
                    Password <sup>*</sup>
                </label>
                <div 
                    className='text-gray-300 absolute cursor-pointer right-5 text-2xl bottom-2'
                    onClick={() => setShowPassword((prev) => !prev)}
                >
                    {showPassword ? (<HiMiniEye />) : (<HiEyeSlash />) }
                </div>
                {errors.password && (
                    <span className='text-red-500 text-xs absolute'>
                        Password is required
                    </span>
                )}
            </div>

            <div className='relative'>
                <input 
                    type={showConfirmPassword ? "text" : "password"} 
                    id='confirmPassword'
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg 
                    border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" " 
                    {...register("confirmPassword", {required: true})}
                />
                <label htmlFor="confirmPassword"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
                    dark:bg-neonBlack px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                    rtl:peer-focus:left-auto start-1"
                >
                    Confirm Password <sup>*</sup>
                </label>
                <div 
                    className='text-gray-300 absolute cursor-pointer right-5 text-2xl bottom-2'
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                >
                    {showConfirmPassword ? (<HiMiniEye />) : (<HiEyeSlash />) }
                </div>
                {errors.confirmPassword && (
                    <span className='text-red-500 text-xs absolute'>
                        Confirm Password is required
                    </span>
                )}
            </div>

            <button type="submit" 
                className="bg-[#CFFF04] text-black py-2 px-4 rounded-lg font-extrabold font-Lato mt-3 w-full hover:bg-opacity-95 hover:scale-95 transition-all duration-300"
            >
                Sign Up
            </button>
        </form>

    </div>
  );
}

export default SignupForm;
