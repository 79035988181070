import React from 'react'
import CartAndBuy from '../Custome/CartAndBuy'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addCart, createProduct } from '../../../services/operations/productAPI';
import { setCurrentOrder } from '../../../slices/paymentSlice';

const ProductCard = ({product, index, isSwiper, filter, type}) => {
    const navigate = useNavigate();
    const handleOnClick = () => {
        if(isSwiper) {
            navigate(`/products?filter=${filter}`);
        }
    }

    const dispatch = useDispatch();
    const {user} = useSelector((state) => state.profile);
    const {token} = useSelector((state) => state.auth);

    const handleAddToCart = async(text, price, discount, image) => {
        if(!token) {
            alert("Please Login to Add to Cart");
            return;
        }
        const data = {
            text: text,
            type: type,
            buyerId: user?._id,
            price: price,
            discount: discount,
            image: image,
        }
        const result = await addCart(data, user?.token, dispatch, user);
    }

    const handleBuyNow = async(text, price, discount, image) => {
        if(!token) {
            alert("Please Login to Buy Now");
            return;
        }

        const data = {
            text: text,
            type: type,
            buyerId: user?._id,
            totalAmount: price,
            discount: discount,
            image: image,
            quantity: 1,
        }

        dispatch(setCurrentOrder(data))
        navigate('/order');
    }
  return (
    <div onClick={handleOnClick} key={index} className='w-[60%] lg:w-[80%] mx-auto xl:w-full flex items-start cursor-pointer group'>
        <div className='w-full relative flex flex-col px-2 py-2 gap-y-3'>
            <img src={product.image} alt="" className=' object-fill h-[280px] xl:h-[300px] rounded-xl'/>
            <div
                style={{fontFamily: "Montserrat"}}
                className='w-full flex gap-x-10 flex-col'
            > 
                <div className='flex flex-col items-start'>
                    <p className='text-lg text-start'>{product.name}</p>
                    <div className='text-start flex gap-x-2 items-center mt-2'>
                        <p className=''>Price</p> 
                        <s className='text-gray-500'>
                        ₹ {Number(product.price) + Number(((product.discount/100) * product.price))}
                        </s>
                    </div>
                    <div className='flex items-center gap-x-2'>
                        <p className='text-[#CFFF04] font-bold text-lg'>
                        ₹ {product.price}
                        </p>
                        <p className='text-sm text-green-300'>
                        ({product.discount}% off)
                        </p>
                    </div>
                </div>

                {
                    !isSwiper && (
                        <div className='scale-y-0 group-hover:scale-100 h-0 block group-hover-h-full transition-all duration-300 w-full mt-2'>
                            <CartAndBuy
                                customeStyle="xl:text-base lg:text-sm"
                                cartStyle="text-white bg-[#FF3131] xl:px-2 xl:py-3 px-1 py-2"
                                buyStyle="text-black bg-white xl:px-2 xl:py-3 px-1 py-2"
                                handleAddToCart={() => handleAddToCart(product.name, product.price, product.discount, product.image)}
                                handleBuyNow={() => handleBuyNow(product.name, product.price, product.discount, product.image)}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default ProductCard