import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion"
import ProductBg1 from "../../../assests/Product-bg1.jpg" 
import CustomeBG from "../../../assests/Customebg.jpg" 

import SelectFont from './SelectFont';
import allFonts from "../../../data/google-fonts.json"
import { setDesc, setPrice, setText } from '../../../slices/productSlice';
import SelectColor from './SelectColor';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { allColors } from '../../../data/gradient-colors';
import SelectSize from "./SelectSize"
import { GetPrice } from '../../../utils/priceCalculator';
import CartAndBuy from './CartAndBuy';
import { addCart } from '../../../services/operations/productAPI';
import { setCurrentOrder } from '../../../slices/paymentSlice';
import { useNavigate } from 'react-router-dom';
import AddOn from './AddOn';


const CustomeNeon = () => {
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isSubmitSuccessful },
    } = useForm()

    const {fontStyle, text, color, price, size, description, addOn} = useSelector((state) => state.product)
    const neonTextColor = allColors[color]['text-shadow']
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user} = useSelector((state) => state.profile);
    const {token} = useSelector((state) => state.auth);
    const handleAddToCart = async() => {
        if(!token) {
            alert("Please Login to Add to Cart");
            return;
        }

        const data = {
            text: text,
            type: 'Text',
            buyerId: user?._id,
            price: price,
            color: color,
            size: size,
            font: fontStyle,
            buyerId: user?._id,
            description: description,
            addon: addOn
        }
        const result = await addCart(data, user?.token, dispatch, user);
    }
    
    const handleTextChange = (e) => {
        dispatch(setText(e.target.value));
        const newPrice = GetPrice({size: size, text: text, font: fontStyle, color: color});
        dispatch(setPrice(newPrice));

    }

    const handleDescChange = (e) => {
        dispatch(setDesc(e.target.value));
    }


    function splitString(string, length) {
        // Check if the string is empty.
        if (string.length === 0) {
            return [];
        }
        
        // Create an empty array to store the substrings.
        const substrings = [];
        
        // Iterate over the string, starting at the beginning.
        for (let i = 0; i < string.length; i += length) {
            // Get the substring from the current position to the length.
            const substring = string.substring(i, i + length);
        
            // Add the substring to the array.
            substrings.push(substring);
        }
        
        // Return the array of substrings.
        return substrings;
    }
    let substrings = [text];
    useEffect(() => {
        const newPrice = GetPrice({size: size, text: text, font: fontStyle, color: color, addon: addOn});
        console.log("Text ", text)
        substrings = splitString(text, 22);
        console.log("Substrings ", substrings)
        dispatch(setPrice(newPrice));
    }, [size, fontStyle, text, color, addOn])

    const handleBuyNow = async() => {
        if(!token) {
            alert("Please Login to Buy Now");
            return;
        }

        const data = {
            text: text,
            type: 'Text',
            buyerId: user?._id,
            totalAmount: price,
            color: color,
            size: size,
            font: fontStyle,
            buyerId: user?._id,
            description: description,
            addon: addOn
        }

        await dispatch(setCurrentOrder(data))
        navigate('/order');
    }

  return (
    <div className='bg-black text-white py-12 h-full pt-20' id='Custome'>
        <h2 className='text-center mt-7 text-4xl md:text-5xl lg:text-6xl font-bold font-serif text-[#CFFF04]'>
            Custome Neon
        </h2>
        <div className='w-full max-w-[1360px]  flex lg:flex-row flex-col gap-y-10 lg:gap-y-0 items-center 
            justify-between lg:items-center lg:justify-between  h-full mx-auto lg:py-10 xl:px-0 px-5'
        >
            {/* Left */}
            <div className='relative h-[450px] sm:h-[650px] lg:w-[100%] w-[90%] flex items-center justify-center'>
                <img src={CustomeBG} alt="" className='w-full lg:h-auto h-full object-fill absolute top-5 z-0' />
                <motion.div
                    className='w-[79%] z-[2] h-[50%] mx-auto'
                    drag
                    whileHover={{ scale: 1.05 }}
                    dragConstraints={{
                    top: -100,
                    left: -180,
                    right: 180,
                    bottom: 100,
                    }}
                    
                >
                    <div
                    style={
                    {fontFamily: allFonts[fontStyle], textShadow: `${neonTextColor}`}
                    } 
                    className={`${neonTextColor === 'homeText' && "homeText"} text-5xl text-white text-wrap break-words text-center`}>
                        {
                            substrings.map((sub, index) => (
                                <>
                                    <p>
                                        {
                                            sub.toString().split("").map((letter, index) =>(
                                                <>
                                                    {letter === "\n" ? <br /> : letter} 
                                                </>
                                            ))
                                        }
                                    </p>
                                </>
                            ))
                        }
                    </div>
                    {/* {
                        text.length > 22 && 
                        <p
                            style={
                                {fontFamily: allFonts[fontStyle], textShadow: `${neonTextColor}`}
                            } 
                            className={`${neonTextColor === 'homeText' && "homeText"} text-5xl text-white text-wrap break-words text-center`}
                        >
                            {text.toString().split("").slice(22).join("")}
                        </p>
                    } */}
                </motion.div>
            </div>

            {/* Right */}
            <div className='w-full lg:w-[68%] h-full relative flex flex-col items-end gap-y-4'>
                <div
                    className="text-white w-full sm:pl-12 lg:overflow-y-scroll h-3/6 lg:max-h-[30rem] px-5 "
                    onSubmit={handleSubmit()}
                >
                    <div className='bg-black font-bold font-Poppins sticky top-0 left-0 right-0 py-2 px-2 z-[2] text-2xl flex items-center gap-x-2'>
                        <p className='text-white'>
                            Price: 
                        </p>
                        <p className='text-[#CFFF04]'>
                            ₹ {price}
                        </p>
                    </div>
                    <div className='w-full flex flex-col gap-y-10 max-h-[40%] py-4'>
                        <div className='flex justify-between gap-x-5'>
                            <div className='relative w-full'>
                                <textarea 
                                    type="text" 
                                    id='text'
                                    onChange={handleTextChange}
                                    value={text}
                                    className="block px-2.5 pb-2.5 pt-4 w-full text-lg text-gray-900 bg-transparent rounded-lg 
                                    border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "
                                    required 
                                />
                                <label htmlFor="text"
                                    className="text-lg absolute  text-gray-500 dark:text-gray-400 duration-300 transform 
                                    -translate-y-4 scale-75 top-2 z-[1] origin-[0] bg-black bg-transparent 
                                    dark:bg-black px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                                    rtl:peer-focus:left-auto start-1"
                                >
                                    Text <sup>*</sup>
                                </label>
                            </div>
                        </div>
                        <SelectFont />    
                        <SelectColor />
                        <SelectSize />
                        <AddOn />
                        <div className=''>
                            <h2 className='sticky top-0 left-0 right-0 bg-black text-white font-Lato font-semibold text-sm sm:text-base pb-3'>
                                Description
                            </h2>
                            <div className='relative w-full'>
                                <textarea 
                                    type="text" 
                                    id='text'
                                    onChange={handleDescChange}
                                    value={description}
                                    className="block px-2.5 pb-2.5 pt-4 w-full text-lg text-white bg-transparent rounded-lg 
                                    border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "
                                    required 
                                />
                                <label htmlFor="text"
                                    className="text-lg absolute  text-gray-500 dark:text-gray-400 duration-300 transform 
                                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-black bg-transparent 
                                    dark:bg-black px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                                    rtl:peer-focus:left-auto start-1"
                                >
                                    Description
                                </label>
                            </div>
                        </div>
                    </div>
                
                </div>
                <div className='mt-6 px-6'>
                    <CartAndBuy 
                        customeStyle="text-base gap-x-8"
                        cartStyle="text-white bg-[#FF3131] xl:px-2 xl:py-3 px-1 py-2"
                        buyStyle="text-black bg-white xl:px-2 xl:py-3 px-1 py-2"
                        handleAddToCart={handleAddToCart}
                        handleBuyNow={handleBuyNow}
                    />
                </div>
            </div>
        </div>

    </div>
  );
}

export default CustomeNeon;
