import React from 'react'

const HEADER = [
  {
    title: "2 Year Warranty",
    icon: (
      <svg
        fill="#ffffff"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 362.666 362.666"
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M339.106,205.998c-1.586-39.295-18.655-75.978-48.109-103.354c-29.585-27.498-68.534-42.642-109.674-42.642
                        s-80.09,15.144-109.675,42.642c-29.619,27.531-46.715,64.473-48.135,104.02c-0.192,5.339,3.847,9.886,9.171,10.325
                        c5.32,0.436,10.054-3.384,10.739-8.682c2.2-16.992,19.023-29.805,39.132-29.805c21.715,0,39.383,15.028,39.383,33.5
                        c0,5.523,4.478,10,10,10c5.523,0,10-4.477,10-10c0-15.534,12.496-28.629,29.385-32.403v140.268
                        c0,12.571-10.229,22.799-22.801,22.799c-12.57,0-22.797-10.228-22.797-22.799c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10
                        c0,23.599,19.198,42.799,42.797,42.799c23.601,0,42.801-19.2,42.801-42.799V179.6c16.889,3.774,29.384,16.869,29.384,32.402
                        c0,5.523,4.477,10,10,10s10-4.477,10-10c0-18.472,17.668-33.5,39.384-33.5c20.109,0,36.932,12.813,39.131,29.805
                        c0.65,5.021,4.932,8.716,9.908,8.716c0.058,0,0.115,0,0.173-0.001c5.456-0.077,9.856-4.524,9.856-9.999
                        C339.159,206.677,339.142,206.335,339.106,205.998z"
          />
          <path
            d="M44.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.562-14.056c-4.312-3.45-10.605-2.75-14.056,1.561l-12,15C39.064,23.065,39.764,29.358,44.075,32.809z"
          />
          <path
            d="M94.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.562-14.056c-4.313-3.45-10.606-2.75-14.056,1.561l-12,15C89.064,23.065,89.764,29.358,94.075,32.809z"
          />
          <path
            d="M144.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.562-14.056c-4.313-3.45-10.606-2.75-14.056,1.561l-12,15C139.064,23.065,139.764,29.358,144.075,32.809
                        z"
          />
          <path
            d="M194.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.562-14.056c-4.313-3.45-10.606-2.75-14.056,1.561l-12,15C189.064,23.065,189.764,29.358,194.075,32.809
                        z"
          />
          <path
            d="M244.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.561-14.056c-4.314-3.45-10.605-2.75-14.056,1.561l-12,15C239.064,23.065,239.764,29.358,244.075,32.809
                        z"
          />
          <path
            d="M294.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.561-14.056c-4.312-3.45-10.605-2.75-14.056,1.561l-12,15C289.064,23.065,289.764,29.358,294.075,32.809
                        z"
          />
        </g>
      </svg>
    ),
    color: "#ff10f0",
  },
  {
    title: "5000+ Happy Customers",
    icon: (
      <svg
        viewBox="0 0 24 24"
        id="meteor-icon-kit__regular-smile-beam"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM6.05132 15.3162C5.87667 14.7923 6.15983 14.226 6.68377 14.0513C7.20772 13.8767 7.77404 14.1598 7.94868 14.6838C8.02839 14.9229 8.26749 15.3533 8.70711 15.7929C9.46008 16.5459 10.5197 17 12 17C13.4803 17 14.5399 16.5459 15.2929 15.7929C15.7325 15.3533 15.9716 14.9229 16.0513 14.6838C16.226 14.1598 16.7923 13.8767 17.3162 14.0513C17.8402 14.226 18.1233 14.7923 17.9487 15.3162C17.7784 15.8271 17.3925 16.5217 16.7071 17.2071C15.5851 18.3291 14.0197 19 12 19C9.98027 19 8.41492 18.3291 7.29289 17.2071C6.60751 16.5217 6.22161 15.8271 6.05132 15.3162ZM7.5547 9.83205C7.09517 10.1384 6.4743 10.0142 6.16795 9.5547C5.8616 9.09517 5.98577 8.4743 6.4453 8.16795L6.8359 7.90755C7.8436 7.23575 9.1564 7.23575 10.1641 7.90755L10.5547 8.16795C11.0142 8.4743 11.1384 9.09517 10.8321 9.5547C10.5257 10.0142 9.90483 10.1384 9.4453 9.83205L9.0547 9.57165C8.7188 9.34772 8.2812 9.34772 7.9453 9.57165L7.5547 9.83205ZM14.5547 9.83205C14.0952 10.1384 13.4743 10.0142 13.1679 9.5547C12.8616 9.09517 12.9858 8.4743 13.4453 8.16795L13.8359 7.90755C14.8436 7.23575 16.1564 7.23575 17.1641 7.90755L17.5547 8.16795C18.0142 8.4743 18.1384 9.09517 17.8321 9.5547C17.5257 10.0142 16.9048 10.1384 16.4453 9.83205L16.0547 9.57165C15.7188 9.34772 15.2812 9.34772 14.9453 9.57165L14.5547 9.83205Z"
          fill="#ffffff"
        />
      </svg>
    ),
    color: "#FF3131",
  },
  {
    title: "Free Pan India Delivery",
    icon: (
      <svg
        viewBox="0 0 32 32"
        id="svg5"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsSvg="http://www.w3.org/2000/svg"
      >
        <defs id="defs2" />

        <g id="layer1" transform="translate(-60,-436)">
          <path
            d="m 68,456.01367 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 8 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z"
            id="path453673"
            color="#ffffff"
            fill="#ffffff"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="4.1"
            inkscape-stroke="none"
          />

          <path
            d="m 70,453.01367 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 6 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z"
            id="path453657"
            color="#ffffff"
            fill="#ffffff"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="4.1"
            inkscape-stroke="none"
          />

          <path
            d="m 73,450.01367 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z"
            id="path453639"
            color="#ffffff"
            fill="#ffffff"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="4.1"
            inkscape-stroke="none"
          />

          <path
            d="m 68.888672,444.01953 a 1,1 0 0 0 -0.595703,0.28711 L 66.5,446.09961 65.707031,445.30664 a 1,1 0 0 0 -1.414062,0 1,1 0 0 0 0,1.41406 l 1.5,1.5 a 1.0001,1.0001 0 0 0 1.414062,0 l 2.5,-2.5 a 1,1 0 0 0 0,-1.41406 1,1 0 0 0 -0.818359,-0.28711 z"
            id="path453633"
            color="#ffffff"
            fill="#ffffff"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="4.1"
            inkscape-stroke="none"
          />

          <path
            d="m 67,440.01367 c -3.301857,0 -6,2.69815 -6,6 0,1.76801 0.774349,3.36262 2,4.46289 v 11.53711 a 1.0001,1.0001 0 0 0 1,1 h 2.173828 c 0.415693,1.16039 1.530816,2 2.826172,2 1.295356,0 2.410479,-0.83961 2.826172,-2 H 79 81.173828 c 0.415693,1.16039 1.530816,2 2.826172,2 1.295356,0 2.410479,-0.83961 2.826172,-2 H 90 a 1.0001,1.0001 0 0 0 1,-1 v -7 a 1.0001,1.0001 0 0 0 -0.21875,-0.625 l -4,-5 A 1.0001,1.0001 0 0 0 86,449.01367 h -6 v -2 a 1.0001,1.0001 0 0 0 -1,-1 h -6 c 0,-3.30185 -2.698143,-6 -6,-6 z m 0,2 c 2.220979,0 4,1.77902 4,4 0,2.22098 -1.779021,4 -4,4 -0.84757,0 -1.630859,-0.25926 -2.275391,-0.70312 a 1,1 0 0 0 -0.267578,-0.20117 C 63.565547,448.37778 63,447.26595 63,446.01367 c 0,-2.22098 1.779021,-4 4,-4 z m 5.654297,6 H 78 v 13 h -6.173828 c -0.415693,-1.16039 -1.530816,-2 -2.826172,-2 -1.295356,0 -2.410479,0.83961 -2.826172,2 H 65 v -9.3457 c 0.626269,0.22297 1.29935,0.3457 2,0.3457 2.601207,0 4.826496,-1.67494 5.654297,-4 z m 7.345703,3 h 2 v 5 a 1.0001,1.0001 0 0 0 1,1 h 6 v 4 h -2.173828 c -0.415693,-1.16039 -1.530816,-2 -2.826172,-2 -1.295356,0 -2.410479,0.83961 -2.826172,2 H 80 Z m 4,0 h 1.519531 l 3.199219,4 H 84 Z m -15,10 c 0.564128,0 1,0.43587 1,1 0,0.56413 -0.435872,1 -1,1 -0.564128,0 -1,-0.43587 -1,-1 0,-0.56413 0.435872,-1 1,-1 z m 15,0 c 0.564128,0 1,0.43587 1,1 0,0.56413 -0.435872,1 -1,1 -0.564128,0 -1,-0.43587 -1,-1 0,-0.56413 0.435872,-1 1,-1 z"
            id="path453615"
            color="#ffffff"
            fill="#ffffff"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="4.1"
            inkscape-stroke="none"
          />
        </g>
      </svg>
    ),
    color: "#CFFF04",
  },
];

const Header = () => {
  return (
    <div className='flex text-white w-full justify-center gap-x-14 md:py-2 px-4'>
      {
        HEADER.map((item, index) => (
          <div
            key={index}
            className='flex items-center gap-x-2'
          >
            <div
              className='flex items-center justify-center w-5 h-5 :w-8 md:h-8 rounded-full relative'
            >
              <div 
                style={{background: item.color}}
                className={`w-full h-full absolute blur-lg md:blur-xl`}
              >
              </div>
              {item.icon}
            </div>
            <p className='md:text-base sm:text-sm text-[0.55rem]'>
              {item.title}
            </p>
          </div>
        ))
      }

    </div>
  )
}

export default Header