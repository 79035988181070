import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    paymentStatus: "Pending",
    step: sessionStorage.getItem('step') ? JSON.parse(sessionStorage.getItem('step')) : 1,
    editAddress: false,
    orders: [],
    currentOrder: sessionStorage.getItem('currentOrder') ? JSON.parse(sessionStorage.getItem('currentOrder')) : null
};

const paymentSlice = createSlice({
    initialState: initialState,
    name: "payment",
    reducers: {
        setPaymentStatus(state, value) {
            state.paymentStatus = value.payload;
        },
        setStep(state, value) {
            state.step = value.payload;
        },
        setEditAddress(state, value) {
            state.editAddress = value.payload;
        },
        setOrder(state, value) {
            state.orders = value.payload;
        },
        setCurrentOrder(state, value) {
            state.currentOrder = value.payload;
        }
    }
})

export const {setPaymentStatus, setStep, setEditAddress, setOrder, setCurrentOrder} = paymentSlice.actions;
export default paymentSlice.reducer;
