import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FaChevronDown } from "react-icons/fa";
import { motion } from 'framer-motion';
import useOnClickOutside from '../hooks/useOnClickOutside';

const DropDown = ({settings, display, width}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpen(false));
  const handleOnClick = (url) => {
    navigate(url);
    setOpen(false);
  }

  return (
    <button className='relative group' onClick={() => setOpen(true)}>
      <div 
        className={`relative flex items-center justify-center gap-x-2
        text-white text-center py-2 px-4 rounded-md cursor-pointer w-full`}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <div className='text-lg font-semibold'>
          {display}
          
        </div>
        <FaChevronDown />
        <motion.div 
          className='absolute h-[2px] group-hover:scale-100 scale-0 w-[75%] bottom-0 bg-gradient-to-r from-red-500 via-blue-500 to-purple-500 transition-all duration-300'></motion.div>
      </div>
        
      {
        open && (
          <div
          onClick={(e) => e.stopPropagation()}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          className={`${width ? width : "xl:w-[800px] lg:w-[700px] md:w-[600px]"} absolute top-[100%] -right-56 z-[1000] divide-y-[1px] divide-gray-900 overflow-hidden rounded-md border-[1px] border-gray-700 text-white
            flex gap-x-4 h-[250px] backdrop-blur-md px-6 py-6 shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg isolate`}
          ref={ref}
          >
            {settings.map((setting, index) => ( 
              <div key={index} onClick={ () => handleOnClick(setting.link)} className='w-full'>
                <div className={`flex w-full h-full justify-center items-center gap-x-1 py-[10px] px-[12px] text-sm text-richblack-100 hover:bg-richblack-700 hover:text-richblack-25 relative`}>
                    <img 
                      src={setting?.image} 
                      alt={setting?.name} 
                      className={`absolute h-[200px] w-[200px] top-0 bottom-0 left-0 right-0 object-cover -z-10`}
                    />
                    <div className={`absolute h-[200px] w-full top-0 bottom-0 left-0 right-0 bg-black opacity-40 -z-10`}></div>
                    <p className='z-10 font-semibold text-lg font-Poppins text-center'>
                        {setting.name}
                    </p>
                </div>
              </div>
            ))}
          </div>
        )
      }
    </button>
  )
}

export default DropDown