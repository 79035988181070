export const MDF_ACRYLIC_LOGO = [
    {
        name: "Itachi Uchiha",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087250/HomePageMedia/gfdvdgje9s87gm4cuvrf.jpg",
        price: "1199",
        discount: "75"
    },
    {
        name: "Dragon Ball Z",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087249/HomePageMedia/padia1lhxrhq739mtccq.jpg",
        price: "1199",
        discount: "50"
    },
    {
        name: "Groot",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087249/HomePageMedia/a6xduvoif6iku7razsy1.jpg",
        price: "999",
        discount: "75"
    },
    {
        name: "Lady",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087249/HomePageMedia/xhkovbozpt5szpjif8kz.jpg",
        price: "999",
        discount: "75"
    },
    {
        name: "W",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087250/HomePageMedia/vc9rjnkkvmrpoy7v55is.jpg",
        price: "1199",
        discount: "50"
    },
    {
        name: "DC Logo",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087249/HomePageMedia/jubgxjuhuqojbjiryggr.jpg",
        price: "1199",
        note: "Any One",
        discount: "50"
    },
    {
        name: "Tree",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1716651048/HomePageMedia/kf5j3fur0oepruqkmvan.jpg",
        price: "1999",
        discount: "50"
    },
    {
        name: "Elite Barber",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087249/HomePageMedia/hytasxenoj8ijza3mcct.jpg",
        price: "2300",
        discount: "75"
    },
    {
        name: "JS Beauty Bar",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087249/HomePageMedia/lydoye0w7sgnd85is7u7.jpg",
        price: "2500",
        discount: "50"
    },
    {
        name: "Samer Dally",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087249/HomePageMedia/cvtxnvevbwc4nizqvdy6.jpg",
        price: "2500",
        discount: "50"
    },
]

export const EVENT = [
    {
        name: "Crazy in Love",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087238/HomePageMedia/mievyhqsf8n7o4tannnf.jpg",
        price: "1999",
        discount: "25"
    },
    {
        name: "It was Always You",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1716655111/HomePageMedia/ijmj4jsaryfqhppwngx3.jpg",
        price: "2199",
        discount: "75"
    },
    {
        name: "Ash Loves to Rev",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087238/HomePageMedia/a3g4c2eylzgrvmorpucz.jpg",
        price: "2999",
        discount: "50"
    },
    {
        name: "Well you Marry Me",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087238/HomePageMedia/jumn3xoq0bb22c6mwhxx.jpg",
        price: "2999",
        discount: "50"
    },
    {
        name: "Let's Party",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087238/HomePageMedia/kx72px4aldicvso2ekpa.jpg",
        price: "1399",
        discount: "50"
    },
    {
        name: "Always & Forever",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087238/HomePageMedia/lz52ojfqyx6mjchorsfl.jpg",
        price: "1999",
        discount: "75"
    },
    {
        name: "Havana Nights",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087238/HomePageMedia/u6ybzhqz8tp4k0svqbfg.jpg",
        price: "4500",
        discount: "75"
    },
    {
        name: "Will You Marry Me Red",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1716655506/HomePageMedia/qo3arlsl1gnhmeahkvwo.jpg",
        price: "4700",
        discount: "75"
    },
    {
        name: "Better Together",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087238/HomePageMedia/hyzdkgbnw2nummrywi6m.jpg",
        price: "6999",
        discount: "50"
    },
    {
        name: "Wings 1",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087237/HomePageMedia/dxw4luv0lw9ysukeahau.jpg",
        price: "7800",
        discount: "50"
    },
    {
        name: "Wings 2",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087238/HomePageMedia/xlxiyum3wctvy12y6jxy.jpg",
        price: "7900",
        discount: "50"
    },
]

export const CAFE_RESTAURANT = [
    {
        name: "Feed Me Tacos",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087225/HomePageMedia/gs8el0ibrzphbjkkl3r5.jpg",
        price: "7499",
        discount: "75"
    },
    {
        name: "Coffee Point",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087225/HomePageMedia/fyapujkkvcdwduntyb4e.jpg",
        price: "2299",
        discount: "50"
    },
    {
        name: "You Are My Cup of Tea",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087227/HomePageMedia/fn1itwnti4kz0wbuurnv.jpg",
        price: "3500",
        discount: "75"
    },
    {
        name: "Estele Let your jwellery talk",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087226/HomePageMedia/te4z3qbhthltaoalgxoa.jpg",
        price: "3500",
        discount: "50"
    },
    {
        name: "Juice Glass",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087225/HomePageMedia/crgrnzgp8onhalxlqezt.jpg",
        price: "5200",
        discount: "50"
    },
    {
        name: "From the Street of Budapest",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1716658838/HomePageMedia/zzjntqr2my1slzcpo6b4.jpg",
        price: "5700",
        discount: "75"
    },
    {
        name: "Boba Tree",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087227/HomePageMedia/lmierridmcqfbbao6u70.jpg",
        price: "3999",
        note: 'Large Size',
        discount: "75"
    },
    {
        name: "Smile Love Dream Talk Drink",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087225/HomePageMedia/zm8bi5kuheezmjiqbnvi.jpg",
        price: "4499",
        discount: "75"
    },
    {
        name: "Angle Wings",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087226/HomePageMedia/kig3xvyaw8bst9vdi2j5.jpg",
        price: "7600",
        discount: "50"
    },
    {
        name: "Wings",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087225/HomePageMedia/tw56e9zlnoijtiwrkq3y.jpg",
        price: "8999",
        discount: "50"
    },
    {
        name: "Cup Noodles",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087225/HomePageMedia/kx4ubz0rppgn8c4iimkb.jpg",
        price: "7999",
        note: "Large Size",
        discount: "75"
    },
    {
        name: "Steak BBQ",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087227/HomePageMedia/qaxx8iy5qayryxcyk8zz.jpg",
        price: "8999",
        discount: "75"
    },
]

export const OTHERS = [
    {
        name: "Work Hard Play Hard",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087200/HomePageMedia/grcgbqano3hxvmczyykf.jpg",
        price: "2499",
        discount: "75"
    },
    {
        name: "Pizza Slice",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087199/HomePageMedia/cld2td4uuew7gskojijn.jpg",
        price: "2399",
        discount: "5"
    },
    {
        name: "Magic Begin Here",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087199/HomePageMedia/fbjm0f3hvsh9fyguej3f.jpg",
        price: "2999",
        discount: "75"
    },
    {
        name: "Albert Einstein Headphone",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087199/HomePageMedia/trrjlezylmhds3aqxhbn.jpg",
        price: "3999",
        discount: "50"
    },
    {
        name: "King's Crown and Wings",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087200/HomePageMedia/s5goxwvnn6vptmuawqxs.jpg",
        price: "7800",
        discount: "75"
    }    
] 