import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import productReducer from "../slices/productSlice";
import profileReducer from "../slices/profileSlice";
import cartReducer from "../slices/cartSlice";
import paymentReducer from "../slices/paymentSlice";
const rootReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    product: productReducer,
    cart: cartReducer,
    payment: paymentReducer
})

export default rootReducer