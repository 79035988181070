import React from 'react';
import NeonButton from '../components/NeonButton';
import Slider from '../components/core/Home/Slider';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';
import WhyUs from '../components/core/Home/WhyUs';
import HowToInstall from "../components/core/Home/HowToInstall";
import Footer from '../components/Footer';
import { allColors } from '../data/gradient-colors';
import { CAFE_RESTAURANT, EVENT, MDF_ACRYLIC_LOGO, OTHERS } from '../data/productList';
import { SwiperSlide } from 'swiper/react';
import Header from '../components/Header';

const carouselImages = [];
MDF_ACRYLIC_LOGO.forEach(element => carouselImages.push(element.image));
EVENT.forEach(element => carouselImages.push(element.image));
CAFE_RESTAURANT.forEach(element => carouselImages.push(element.image));
OTHERS.forEach(element => carouselImages.push(element.image));

const Home = () => {
  const navigate = useNavigate();
  
  return (
    <div className="w-full bg-black">
      <div className='fixed w-full bg-black z-10'>
        <Navbar />
      </div>
      <div className='pt-24 pb-3 md:pt-16 md:pb-2 bg-gray-900/40'>
        <Header />
      </div>
      {/* Hero Section */}
      <div className="w-full bg-black">
        <div  className="w-full max-w-[1380px] mx-auto h-full flex ">
          <div className="w-full flex items-center  h-full relative mb-8">
            
            {/* Line and Icon */}
            <div className='flex flex-col items-center gap-y-3 mt-36 place-self-start'>
              <motion.div className='relative'
                initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.3}} viewport={{once: false}}
              >
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ffffff" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 4"/>
                </svg>
                <span className='absolute left-0 top-0 h-full w-full bg-[#7ee787] blur-lg'></span>
              </motion.div>
              <motion.div style={{background: 'linear-gradient(#d2a8ff, #a371f7 10%, #196c2e 70%, #2ea043 80%, #56d364)'}}
                className='max-md:w-[2px] w-[3px] h-[260px] max-md:h-[450px] max-sm:h-[750px] max-ssm:h-[900px] max-sssm:h-[1160px] rounded-md line'
                animate={{height: [0, 380]}} transition={{duration: 0.3, delay: 0.6}}              
              ></motion.div>
            </div>

            {/* Character */}
            <motion.div 
              animate={{translateY: [0, -100, 0], }}
              transition={{repeat: Infinity, duration: 3.5, ease: 'easeInOut'}}
              className='absolute left-16 w-[200px] h-[200px]  justify-center items-center lg:flex hidden'
            >
              <motion.svg 
                initial={{opacity: 0, y:50}}
                animate={{opacity: 1, y:0}}
                transition={{duration: 1, delay: 0.5, ease: 'easeInOut'}}
                version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="150.000000pt" height="150.000000pt" viewBox="0 0 2000.000000 2000.000000"
                preserveAspectRatio="xMidYMid meet" id='svgAnime' className='' 
              >
                <g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)"
                fill="#FFFFFF" stroke="white" strokeWidth="50" id="svgAnime">
                  <path d="M7895 18851 c-106 -66 -198 -253 -280 -573 -70 -274 -124 -577 -156
                  -877 -33 -312 -28 -826 11 -1118 8 -60 7 -64 -49 -200 -323 -775 -547 -1851
                  -611 -2938 -8 -141 -5 -879 4 -945 3 -19 7 -69 11 -110 37 -500 155 -1013 310
                  -1347 l43 -91 -35 -49 c-75 -105 -123 -209 -137 -297 -4 -22 -8 -40 -9 -41 -1
                  -1 -69 -20 -152 -44 -500 -142 -947 -400 -1147 -662 -290 -380 -633 -1321
                  -732 -2004 -37 -256 -41 -603 -10 -945 43 -494 55 -848 33 -980 -16 -89 -15
                  -272 0 -345 7 -33 30 -115 52 -182 21 -67 39 -135 39 -150 0 -16 -16 -53 -35
                  -83 -32 -49 -35 -61 -35 -127 0 -90 32 -196 107 -353 30 -63 73 -167 95 -230
                  81 -229 194 -462 280 -578 87 -116 272 -193 503 -208 104 -7 109 -9 95 -33
                  -20 -39 -132 -338 -196 -526 -112 -329 -188 -604 -244 -883 -38 -195 -45 -269
                  -45 -497 0 -148 4 -204 18 -253 28 -103 66 -169 130 -227 33 -30 57 -58 53
                  -63 -21 -34 -37 -367 -21 -463 18 -115 56 -200 109 -247 43 -38 155 -104 252
                  -148 61 -28 84 -34 141 -33 l68 0 -81 38 -80 38 -22 74 c-33 110 -52 258 -53
                  401 0 116 8 218 17 218 2 0 71 -20 152 -43 81 -24 194 -56 252 -71 l105 -28
                  -3 -111 c-6 -190 28 -504 55 -514 8 -3 16 -4 18 -1 2 2 -4 55 -15 118 -18 114
                  -26 199 -35 399 l-5 103 27 -4 c16 -3 128 -27 251 -54 l222 -48 1 -106 c0 -58
                  2 -112 3 -120 2 -8 6 -55 10 -105 12 -166 16 -185 38 -185 17 0 19 5 14 38 -5
                  34 -8 60 -20 175 -3 23 -8 99 -11 170 -8 141 -12 134 65 116 25 -6 148 -28
                  274 -49 126 -22 230 -41 231 -42 1 -2 5 -61 9 -133 3 -72 11 -159 16 -195 5
                  -36 10 -68 10 -72 0 -5 7 -8 16 -8 14 0 16 8 11 48 -16 130 -39 354 -35 357 1
                  1 59 -6 128 -17 69 -10 206 -29 305 -41 l180 -22 2 -115 c1 -63 2 -136 2 -162
                  1 -37 5 -48 17 -48 14 0 15 18 9 161 l-6 161 63 -7 c35 -4 70 -9 79 -10 9 -2
                  99 -11 200 -20 101 -9 193 -18 204 -20 11 -2 32 -4 47 -4 l27 -1 -6 -130 c-5
                  -117 -4 -130 10 -130 15 0 17 14 18 98 2 120 4 150 9 155 3 2 100 -2 215 -9
                  116 -7 248 -14 295 -16 67 -2 85 -6 86 -18 1 -8 2 -59 3 -112 1 -92 2 -98 22
                  -98 20 0 20 3 14 113 l-6 112 350 2 351 2 1 -30 c1 -16 3 -33 4 -39 1 -5 3
                  -44 4 -85 1 -62 4 -75 17 -75 15 0 16 13 9 119 l-7 118 109 7 c107 7 521 42
                  598 51 309 35 417 45 423 41 5 -3 14 -65 21 -138 17 -187 19 -198 38 -198 14
                  0 15 12 9 93 -6 82 -22 173 -41 232 -6 19 0 21 107 34 62 7 201 26 308 41 107
                  16 195 28 196 27 4 -3 33 -257 40 -345 6 -70 9 -82 25 -82 15 0 17 7 13 58
                  -11 131 -26 251 -38 310 -9 46 -10 65 -2 67 6 2 114 22 240 45 126 23 242 46
                  257 50 31 9 28 16 58 -105 23 -95 39 -234 40 -347 1 -73 2 -78 23 -78 21 0 21
                  2 15 127 -6 133 -36 303 -63 368 -8 21 -12 41 -7 45 4 4 53 17 108 30 55 12
                  162 40 239 62 76 22 142 36 146 31 4 -4 18 -55 31 -113 19 -86 23 -134 24
                  -265 0 -88 -4 -189 -8 -223 -7 -58 -7 -63 10 -60 13 2 20 17 26 53 9 62 11
                  303 2 370 -8 61 -41 224 -49 245 -5 11 13 22 76 44 46 16 134 53 197 82 63 30
                  117 54 121 54 9 0 47 -174 59 -270 24 -183 18 -303 -18 -401 -24 -64 -65 -110
                  -136 -150 l-50 -29 66 0 c66 0 66 0 111 48 118 126 136 382 52 741 l-24 104
                  22 15 c60 40 146 140 169 197 22 56 24 72 23 255 0 167 -4 216 -27 345 -78
                  426 -236 977 -436 1513 -40 107 -71 195 -69 196 2 1 66 12 143 23 181 28 296
                  61 421 123 106 52 185 114 234 184 60 88 183 412 269 711 132 459 255 1172
                  305 1770 4 39 9 97 11 130 12 141 26 383 31 550 4 159 12 266 30 425 3 25 5
                  135 5 245 0 645 -183 1244 -513 1677 -77 102 -213 244 -293 307 -157 124 -401
                  272 -701 428 l-181 93 -12 53 c-16 72 -44 114 -88 134 -21 10 -40 18 -42 20
                  -2 1 17 46 42 100 123 264 192 504 262 903 27 156 80 565 90 695 3 44 8 96 10
                  115 8 59 24 284 30 413 4 67 8 131 10 142 24 118 27 1284 4 1630 -4 61 -10
                  164 -13 230 -4 66 -9 140 -11 165 -3 25 -12 124 -20 220 -18 195 -49 466 -70
                  608 l-14 92 30 88 c88 258 162 668 188 1027 9 120 6 505 -3 580 -3 22 -11 85
                  -17 140 -25 236 -131 674 -187 780 -48 89 -142 117 -288 85 -67 -15 -278 -84
                  -399 -132 -206 -81 -662 -325 -986 -527 l-112 -70 -31 18 c-63 39 -234 120
                  -330 155 -133 50 -334 101 -502 126 -180 28 -575 35 -769 14 -141 -15 -394
                  -59 -463 -79 l-43 -13 -147 112 c-563 431 -1205 897 -1309 950 -43 22 -111 20
                  -148 -3z m172 -86 c39 -24 127 -83 195 -131 114 -81 241 -174 983 -714 276
                  -202 473 -336 480 -328 3 3 -33 37 -80 78 -47 40 -84 74 -83 75 5 5 260 53
                  333 64 462 66 980 19 1360 -124 119 -45 318 -142 313 -154 -2 -5 -40 -36 -85
                  -71 -46 -34 -83 -63 -83 -65 0 -11 150 67 328 172 599 352 856 481 1177 589
                  177 61 273 84 340 84 53 0 60 -3 90 -34 27 -29 39 -60 75 -188 72 -257 121
                  -528 138 -752 12 -172 6 -598 -12 -756 -33 -290 -64 -445 -183 -903 -41 -159
                  -73 -291 -70 -293 2 -2 21 32 42 76 20 45 39 79 41 78 16 -17 85 -727 99
                  -1028 4 -74 9 -146 11 -160 6 -43 19 -583 19 -825 0 -235 -12 -752 -20 -815
                  -2 -19 -6 -96 -10 -170 -4 -74 -8 -148 -10 -165 -2 -16 -6 -68 -10 -115 -5
                  -82 -21 -251 -30 -335 -3 -22 -9 -85 -15 -140 -59 -533 -164 -988 -297 -1275
                  -180 -387 -195 -411 -317 -491 -211 -140 -457 -232 -931 -349 -159 -40 -350
                  -92 -423 -116 -122 -40 -302 -120 -327 -146 -5 -6 5 -3 22 6 109 55 317 116
                  688 202 523 122 764 210 1003 368 100 65 142 115 211 254 17 34 34 62 37 62 3
                  0 19 -7 35 -16 67 -34 80 -161 34 -319 -32 -109 -48 -135 -122 -206 -183 -174
                  -506 -274 -978 -304 -49 -3 -162 -10 -250 -15 -259 -16 -388 -36 -563 -86
                  -118 -34 -312 -130 -409 -203 -200 -151 -375 -390 -493 -673 -18 -45 -38 -78
                  -46 -78 -19 0 -19 -2 20 98 116 301 338 638 598 907 168 174 266 258 618 532
                  620 481 852 701 1022 963 103 158 159 300 177 450 10 80 15 787 6 895 -2 28
                  -6 102 -10 165 -7 135 -10 160 -36 425 -79 781 -223 1343 -469 1829 -179 353
                  -346 595 -556 808 -174 177 -319 281 -539 388 -213 104 -407 165 -660 207
                  -154 25 -538 25 -675 -1 -570 -106 -1090 -440 -1425 -916 -140 -199 -341 -580
                  -453 -861 -214 -531 -357 -1133 -406 -1716 -14 -163 -22 -655 -12 -738 2 -16
                  7 -61 11 -100 74 -794 464 -1372 1339 -1988 361 -255 417 -295 556 -406 313
                  -250 646 -612 810 -879 27 -44 30 -56 19 -63 -23 -14 -26 -12 -68 65 -116 211
                  -323 419 -556 555 -170 100 -340 162 -575 211 -516 109 -943 255 -1339 461
                  -387 200 -557 357 -597 549 -17 85 -10 152 28 240 22 52 97 170 108 170 4 0
                  25 -28 48 -63 95 -148 391 -457 602 -629 252 -205 514 -370 759 -478 98 -42
                  322 -123 327 -117 2 2 -33 18 -79 36 -138 56 -425 203 -563 289 -142 88 -298
                  202 -434 316 -185 156 -463 450 -559 593 -188 281 -345 800 -406 1348 -8 77
                  -18 157 -20 179 -23 189 -27 962 -6 1216 12 156 38 435 46 500 2 19 7 60 10
                  90 4 30 8 66 10 80 2 14 13 93 25 175 88 630 252 1299 448 1828 36 97 69 177
                  73 177 4 0 10 -19 13 -42 4 -24 18 -74 31 -113 l25 -70 -6 75 c-6 98 -13 169
                  -24 255 -28 224 -36 887 -12 1110 58 543 170 1056 283 1293 50 103 121 182
                  165 182 18 0 58 -18 101 -45z m2217 -2791 c12 -5 5 -18 -33 -62 -160 -186
                  -319 -518 -372 -775 -33 -163 -31 -467 3 -502 6 -7 7 0 2 20 -17 63 -7 315 15
                  425 64 306 229 640 422 852 l34 37 95 -15 c52 -9 100 -19 107 -22 14 -9 -40
                  -178 -96 -298 -74 -157 -194 -338 -328 -491 -89 -102 -43 -72 67 45 166 174
                  299 396 374 622 19 58 39 106 44 108 16 5 246 -66 346 -107 131 -54 142 -62
                  150 -110 11 -73 57 -204 110 -316 68 -144 150 -278 306 -505 193 -280 240
                  -371 276 -531 23 -106 35 -127 28 -49 -17 182 -69 296 -267 591 -203 303 -287
                  447 -348 601 -26 66 -71 220 -65 225 7 7 153 -83 238 -148 118 -89 329 -305
                  431 -440 154 -206 309 -474 427 -739 112 -249 232 -663 291 -1003 22 -126 22
                  -130 4 -180 -53 -156 -154 -309 -289 -441 -105 -102 -48 -73 67 34 96 90 144
                  153 208 274 27 50 49 87 49 81 0 -5 5 -40 10 -78 l10 -67 -44 -88 c-107 -213
                  -267 -384 -536 -575 -14 -9 13 1 60 24 195 95 370 271 488 487 l39 74 6 -34
                  c4 -18 13 -107 22 -198 8 -91 17 -182 19 -202 4 -41 -1 -48 -54 -68 -64 -24
                  -171 -102 -212 -153 -50 -64 -85 -132 -114 -222 l-22 -70 -7 49 c-18 115 55
                  246 227 411 89 86 97 96 58 74 -25 -13 -86 -64 -136 -114 -143 -143 -257 -343
                  -300 -530 l-7 -30 -7 25 c-12 38 -11 166 1 237 11 63 13 65 83 125 94 80 91
                  77 83 84 -12 12 -118 -66 -189 -138 -190 -195 -243 -361 -192 -603 6 -29 5
                  -29 -45 20 -54 52 -94 123 -106 187 -6 30 -1 50 29 110 19 40 58 104 87 141
                  28 37 49 70 47 72 -2 3 -20 -9 -40 -25 -81 -67 -179 -259 -208 -407 -8 -38
                  -11 -109 -8 -180 l4 -117 -37 43 c-50 56 -74 127 -73 219 l0 72 54 55 c59 60
                  56 72 -6 26 -77 -56 -216 -213 -333 -375 -65 -90 -71 -96 -71 -67 0 36 48 419
                  56 443 2 9 37 42 76 74 198 160 332 298 428 439 53 78 162 287 230 440 31 68
                  62 118 99 160 109 123 204 281 231 385 6 25 13 54 16 65 3 11 -17 -21 -44 -71
                  -27 -50 -68 -117 -92 -150 -52 -70 -181 -206 -263 -277 l-59 -50 15 34 c109
                  250 178 447 242 691 41 156 103 455 112 541 5 52 4 57 -13 57 -17 0 -22 -14
                  -38 -107 -33 -199 -130 -450 -262 -682 -74 -130 -89 -150 -73 -100 63 197 97
                  357 120 559 15 132 6 448 -16 590 -51 322 -172 641 -339 888 -91 134 -122 170
                  -136 156 -9 -9 0 -29 38 -80 281 -390 424 -830 425 -1314 0 -181 -5 -251 -30
                  -415 l-18 -110 -6 130 c-8 146 -22 250 -50 375 -64 276 -187 564 -342 799
                  -148 223 -289 465 -362 618 -43 91 -55 85 -99 -49 -68 -203 -175 -407 -319
                  -608 -117 -162 -122 -164 -52 -25 124 248 213 507 260 764 28 151 29 161 15
                  161 -5 0 -10 -8 -10 -18 0 -37 -71 -307 -116 -440 -53 -157 -120 -325 -171
                  -427 -38 -75 -123 -225 -128 -225 -1 0 17 60 40 133 76 236 189 782 152 736
                  -2 -2 -25 -94 -52 -204 -26 -110 -63 -238 -81 -285 -83 -207 -205 -390 -374
                  -561 -101 -102 -267 -239 -290 -239 -20 0 -105 275 -144 470 -58 283 -60 403
                  -11 575 26 91 89 244 151 367 36 72 44 103 20 78 -28 -29 -136 -229 -170 -314
                  -19 -47 -44 -125 -55 -174 -11 -48 -29 -131 -41 -184 -26 -116 -35 -260 -22
                  -348 5 -36 8 -67 6 -69 -5 -6 -34 71 -60 159 -44 151 -58 253 -58 435 l0 170
                  36 82 c38 84 78 207 99 304 21 99 4 86 -30 -22 -52 -165 -79 -227 -145 -333
                  -75 -121 -104 -145 -326 -262 -218 -114 -349 -202 -438 -292 -59 -59 -70 -68
                  -57 -41 56 110 193 256 385 409 231 185 311 269 376 394 42 81 37 78 -28 -14
                  -56 -80 -207 -227 -353 -345 -224 -181 -316 -275 -389 -395 -41 -67 -22 -12
                  39 115 46 97 66 124 167 235 63 69 168 176 234 239 130 124 131 130 9 35 -187
                  -145 -391 -359 -500 -524 -211 -317 -308 -598 -308 -885 0 -151 13 -237 53
                  -355 15 -47 26 -87 24 -89 -2 -2 -35 45 -74 105 -103 158 -150 320 -168 574
                  -6 96 -7 100 -13 50 -13 -133 2 -273 46 -430 13 -47 40 -152 60 -233 36 -150
                  81 -280 130 -378 l27 -54 -57 55 c-31 30 -76 82 -100 115 -47 65 -122 218
                  -148 300 -24 77 -47 210 -47 279 0 34 -4 55 -8 48 -25 -39 -4 -251 39 -390 16
                  -52 29 -106 29 -121 0 -46 57 -253 94 -341 20 -46 36 -87 36 -89 0 -3 -21 15
                  -46 39 -99 97 -184 229 -230 358 -27 76 -32 64 -8 -19 22 -75 96 -216 154
                  -292 83 -110 203 -207 227 -183 4 3 -19 55 -50 115 -51 99 -126 306 -114 317
                  2 2 17 -15 32 -38 16 -24 50 -68 75 -98 47 -54 48 -57 70 -184 64 -372 161
                  -638 303 -829 99 -134 328 -350 528 -501 75 -55 77 -58 93 -120 13 -52 14 -75
                  5 -122 -14 -77 -67 -188 -112 -239 -36 -39 -37 -39 -43 -17 -25 87 -143 283
                  -279 462 -39 52 -82 112 -94 132 -22 38 -116 128 -132 128 -17 0 -8 -14 46
                  -71 30 -32 55 -62 55 -67 0 -23 -137 -122 -167 -122 -4 0 -8 55 -8 122 0 119
                  -1 125 -33 188 -35 70 -96 149 -107 138 -4 -3 12 -37 35 -75 57 -94 59 -110
                  24 -139 -44 -37 -119 -74 -169 -83 -25 -5 -45 -7 -45 -5 0 2 14 25 30 51 47
                  73 65 144 64 253 -2 180 -67 340 -260 640 -129 200 -151 238 -191 335 -39 96
                  -43 84 -7 -23 31 -95 94 -214 209 -396 157 -249 215 -401 215 -563 0 -85 -21
                  -167 -54 -215 -15 -21 -16 -16 -15 78 1 183 -51 289 -246 504 -150 166 -284
                  360 -328 475 l-18 49 26 131 c132 649 383 1300 687 1780 144 227 322 421 530
                  578 341 257 671 391 1083 440 53 7 343 7 359 1z m964 -889 c36 -60 108 -174
                  159 -252 52 -79 120 -193 151 -255 264 -518 332 -1076 177 -1462 -14 -34 -25
                  -70 -25 -79 0 -76 128 103 272 378 37 72 68 125 68 119 0 -6 -18 -72 -40 -146
                  -51 -176 -137 -403 -207 -551 -68 -141 -64 -156 24 -107 32 18 66 39 76 48 9
                  8 17 12 17 7 0 -20 -184 -378 -227 -442 -224 -333 -718 -700 -1166 -867 -184
                  -69 -388 -111 -486 -100 -20 2 -61 6 -91 10 -134 15 -377 118 -577 245 -283
                  179 -587 446 -739 648 -98 132 -166 283 -228 513 -26 94 -76 329 -76 355 0 8
                  21 -2 55 -27 89 -66 116 -51 54 31 -54 73 -113 197 -160 341 -42 127 -47 155
                  -23 123 125 -167 206 -244 224 -215 6 10 2 25 -11 45 -43 63 -108 213 -129
                  294 -20 78 -43 270 -35 295 2 6 6 48 9 92 12 163 81 375 188 572 58 107 81
                  138 57 76 -20 -54 -39 -175 -39 -255 0 -70 2 -79 19 -79 14 0 21 12 29 54 14
                  69 52 145 109 218 83 106 197 187 466 331 178 96 266 152 291 188 11 17 14 -2
                  19 -133 10 -249 51 -426 147 -623 58 -118 102 -176 121 -158 9 9 7 22 -10 62
                  -49 114 -83 344 -63 426 10 40 10 40 11 8 2 -54 59 -326 96 -451 32 -109 86
                  -256 100 -270 15 -16 185 110 313 232 104 100 193 206 260 311 25 39 49 75 54
                  80 18 20 -53 -162 -102 -260 -27 -56 -50 -106 -50 -113 0 -28 33 -7 108 71
                  278 286 508 650 607 956 l17 53 60 -113 c33 -63 90 -163 126 -224z m-3547
                  -2327 c56 -83 92 -128 223 -278 132 -151 168 -206 201 -303 28 -84 33 -190 14
                  -292 -12 -67 -7 -75 57 -75 59 0 135 25 194 65 29 19 56 35 61 35 15 0 10
                  -133 -7 -190 -13 -43 -13 -54 -2 -61 28 -18 148 33 209 88 l37 33 95 -127
                  c157 -213 243 -365 256 -451 3 -25 11 -47 17 -49 20 -7 121 107 159 180 l37
                  72 -6 -145 c-8 -183 -21 -299 -39 -342 -31 -73 -174 -162 -345 -214 -77 -24
                  -85 -24 -101 -10 -37 33 -171 2 -186 -43 -4 -13 -33 -26 -97 -44 l-91 -27 -56
                  62 c-145 159 -241 346 -326 639 -65 225 -140 590 -160 782 -4 37 -12 70 -17
                  73 -12 7 -6 -49 29 -276 94 -624 239 -1026 447 -1240 l47 -48 -68 -15 c-217
                  -49 -292 -60 -309 -46 -9 8 -47 59 -84 114 -189 283 -291 557 -352 946 -20
                  130 -22 179 -22 509 0 337 10 526 38 675 2 11 9 60 16 109 l12 88 38 -65 c21
                  -36 58 -94 81 -129z m4965 -445 c5 -49 9 -299 9 -558 0 -460 0 -472 -23 -560
                  -31 -117 -113 -287 -193 -400 -35 -49 -120 -147 -189 -217 l-125 -128 -147 30
                  c-214 44 -205 36 -115 104 238 179 414 471 473 784 16 88 22 218 10 230 -11
                  10 -26 -20 -26 -52 -1 -126 -65 -377 -130 -507 -81 -162 -215 -327 -358 -441
                  -87 -69 -111 -73 -141 -22 -28 47 -96 77 -177 78 -47 1 -67 -3 -82 -17 -17
                  -16 -24 -17 -44 -6 -72 37 -136 90 -174 142 -61 82 -84 164 -91 312 l-5 120
                  83 121 c46 67 120 166 164 219 l80 97 12 -71 c15 -91 53 -159 126 -227 88 -82
                  104 -74 74 40 -14 55 -17 94 -14 176 3 58 9 116 15 130 9 24 10 23 35 -25 43
                  -86 179 -198 221 -182 13 5 12 14 -11 64 -36 76 -44 220 -18 309 18 63 63 152
                  101 197 l23 28 0 -93 c0 -133 44 -270 83 -256 11 4 17 28 21 81 4 41 19 112
                  33 158 l27 84 12 -53 c14 -58 44 -107 66 -107 9 0 16 17 20 51 22 178 136 351
                  279 422 35 18 69 32 76 32 8 0 15 -29 20 -87z m-1511 -643 c-54 -356 -67 -662
                  -32 -771 41 -127 123 -228 230 -284 31 -17 57 -35 57 -40 0 -16 -82 -145 -133
                  -213 -58 -75 -188 -198 -257 -243 -211 -139 -407 -181 -820 -174 -218 3 -289
                  11 -457 54 -267 68 -517 207 -728 404 -111 103 -222 235 -203 241 7 3 45 14
                  83 26 172 53 316 148 349 231 37 93 59 487 35 654 -6 42 -8 79 -6 82 3 2 36
                  -16 74 -41 38 -25 117 -70 174 -100 346 -183 592 -204 954 -85 151 50 403 174
                  565 277 69 44 126 79 127 78 1 -1 -4 -44 -12 -96z m-2380 -1068 c71 -94 215
                  -243 310 -319 222 -179 485 -298 782 -355 105 -21 147 -23 388 -22 370 0 490
                  21 679 115 199 99 400 306 510 526 34 68 51 77 124 63 60 -11 105 -36 110 -63
                  7 -36 -200 -311 -337 -447 -154 -153 -289 -237 -477 -299 -304 -99 -822 -113
                  -1173 -30 -398 93 -759 329 -970 634 -55 80 -111 185 -111 209 0 21 57 56 90
                  56 19 -1 37 -17 75 -68z m-185 -59 c63 -143 218 -334 375 -463 248 -203 592
                  -349 910 -385 33 -4 71 -9 85 -11 38 -6 438 -6 500 1 141 15 276 38 360 61
                  314 83 534 256 806 632 53 72 99 132 103 132 3 0 47 -13 96 -29 50 -15 132
                  -36 183 -47 50 -10 92 -21 92 -24 0 -17 -356 -309 -675 -555 -367 -282 -533
                  -434 -704 -643 -46 -56 -84 -102 -85 -102 -1 0 -24 9 -52 19 -80 31 -226 52
                  -358 53 -178 0 -291 -25 -508 -115 -26 -11 -31 -8 -80 39 -151 145 -333 288
                  -638 499 -357 247 -575 421 -765 610 -124 123 -230 243 -223 251 3 2 50 11
                  104 19 55 9 176 35 269 59 94 23 173 44 177 44 4 1 17 -19 28 -45z m-1574
                  -399 c6 -27 16 -62 23 -77 6 -16 11 -30 11 -32 0 -2 -30 -13 -67 -26 -96 -31
                  -323 -129 -462 -198 -221 -111 -450 -258 -645 -414 -64 -51 -142 -103 -184
                  -122 -40 -19 -86 -43 -102 -55 -17 -12 -30 -17 -30 -13 0 20 133 240 187 311
                  145 189 425 379 770 521 147 60 456 162 477 157 6 -1 15 -24 22 -52z m93 -194
                  c212 -285 953 -636 1720 -814 l135 -31 12 -55 c7 -30 20 -113 30 -185 18 -139
                  20 -293 16 -1010 -1 -220 0 -420 3 -445 2 -25 10 -103 16 -175 26 -305 102
                  -639 199 -876 33 -79 63 -119 91 -119 10 0 37 -27 63 -64 53 -74 114 -126 147
                  -126 44 1 89 53 89 103 0 20 -10 30 -44 47 -62 30 -130 105 -121 133 17 53 16
                  95 -5 152 -61 164 -91 261 -114 372 -55 260 -87 645 -75 903 3 74 6 284 6 465
                  1 354 -9 484 -52 700 -13 65 -23 120 -22 121 6 6 155 -53 246 -96 124 -60 245
                  -139 324 -212 53 -48 61 -62 87 -140 48 -143 72 -281 105 -613 11 -108 34
                  -427 40 -545 4 -69 8 -143 10 -165 8 -72 20 -536 20 -730 0 -201 -11 -618 -21
                  -821 -5 -91 -4 -123 5 -129 7 -4 16 -3 21 1 15 17 35 560 35 969 0 255 -12
                  755 -19 777 -3 7 -7 72 -11 143 -7 166 -23 354 -50 620 -21 203 -47 340 -86
                  454 -11 30 -19 56 -19 59 0 27 141 -156 196 -254 29 -52 34 -70 28 -96 -15
                  -68 -5 -278 17 -379 9 -41 15 -48 51 -62 30 -12 38 -20 35 -34 -3 -10 -8 -90
                  -12 -178 -3 -88 -8 -175 -12 -192 -9 -57 3 -81 48 -94 34 -11 39 -16 35 -33
                  -25 -92 -29 -363 -11 -806 2 -58 7 -193 10 -300 3 -107 8 -233 10 -280 17
                  -336 17 -350 3 -350 -12 0 -294 26 -493 46 -61 6 -365 39 -465 50 -102 12
                  -378 167 -503 283 -59 55 -133 152 -155 204 -11 26 -22 33 -35 21 -17 -17 75
                  -158 154 -235 69 -68 160 -134 277 -201 l87 -50 -50 6 c-27 3 -97 8 -155 11
                  -103 6 -107 8 -215 62 -470 240 -853 667 -1055 1176 -58 145 -113 351 -131
                  483 -14 109 -15 114 -30 114 -29 0 28 -321 92 -513 47 -143 174 -407 253 -525
                  193 -290 462 -551 730 -708 46 -27 99 -58 117 -69 l34 -19 -60 -6 c-57 -6 -62
                  -5 -100 27 -94 79 -231 119 -380 109 -49 -3 -105 -10 -122 -15 -24 -7 -54 -4
                  -114 10 -174 41 -324 106 -452 193 -75 51 -77 52 -77 31 0 -44 285 -199 438
                  -238 l93 -24 -78 -28 c-90 -32 -200 -86 -258 -125 -22 -14 -60 -34 -84 -44
                  -55 -21 -160 -83 -223 -132 l-48 -36 17 44 c14 35 17 76 17 223 -1 178 -6 227
                  -63 625 -35 241 -52 431 -56 635 -5 290 12 453 77 720 27 110 32 149 32 255 1
                  136 -2 146 -141 635 -48 170 -59 199 -72 190 -12 -7 36 -228 105 -480 3 -11
                  -25 41 -63 115 -159 311 -372 597 -555 745 -97 78 -249 156 -363 186 -95 25
                  -247 35 -330 21 -33 -6 -61 -9 -62 -8 -6 5 222 170 342 248 148 97 366 218
                  505 279 83 37 392 158 397 155 0 -1 19 -26 42 -56z m6212 14 c151 -76 366
                  -196 505 -282 162 -101 285 -196 387 -299 l78 -79 -84 4 c-76 4 -176 -12 -257
                  -42 -10 -3 -28 13 -55 51 -87 122 -225 252 -370 350 -65 44 -236 132 -298 154
                  -34 12 -38 17 -33 39 3 14 11 53 18 88 7 34 14 62 15 62 2 0 44 -21 94 -46z
                  m14 -224 c192 -95 354 -223 513 -408 62 -73 73 -92 50 -92 -22 0 -155 -84
                  -240 -151 -264 -208 -495 -610 -564 -982 -15 -80 -20 -94 -26 -71 -21 80 -28
                  496 -10 644 9 74 5 103 -11 97 -26 -8 -37 -118 -37 -378 -1 -209 6 -296 45
                  -544 45 -284 54 -375 50 -520 -5 -209 -47 -356 -140 -490 -59 -85 -60 -86
                  -110 -260 -25 -86 -54 -173 -65 -194 -10 -20 -55 -76 -100 -126 -99 -110 -116
                  -141 -123 -229 -4 -49 -12 -75 -26 -90 -11 -11 -25 -43 -32 -71 -6 -27 -27
                  -76 -46 -107 -32 -52 -35 -62 -34 -136 l1 -79 -63 14 c-111 24 -257 23 -442
                  -1 -93 -13 -171 -23 -172 -22 -1 1 53 42 120 91 352 262 661 666 821 1077 47
                  119 96 277 96 309 0 41 -19 15 -40 -53 -35 -117 -125 -336 -185 -454 -66 -127
                  -194 -323 -292 -444 -134 -167 -367 -382 -541 -500 -57 -38 -73 -44 -198 -66
                  -75 -13 -168 -25 -207 -26 -76 -3 -300 9 -330 18 -13 4 11 26 95 86 197 140
                  303 236 283 256 -9 9 -41 -13 -151 -106 -55 -46 -146 -115 -203 -154 -117 -80
                  -100 -77 -306 -57 l-120 11 -2 57 c-24 824 -23 1632 2 2131 2 47 7 162 10 255
                  4 94 8 184 10 200 2 17 6 59 10 95 16 180 62 361 152 595 l58 154 75 50 c69
                  47 216 121 238 121 6 0 4 -19 -4 -45 -7 -25 -18 -94 -25 -153 -17 -162 0 -361
                  55 -647 44 -227 49 -294 46 -590 -8 -669 -5 -905 14 -1055 33 -273 123 -654
                  184 -780 21 -42 34 -57 58 -64 23 -7 38 -23 58 -62 33 -62 116 -144 147 -144
                  11 0 36 15 55 34 45 45 47 89 4 111 -43 22 -99 73 -115 106 -13 24 -13 32 0
                  63 20 47 12 98 -31 203 -41 99 -78 248 -101 403 -18 128 -25 690 -10 874 12
                  140 10 469 -3 611 -13 145 -42 337 -75 505 -40 204 -47 292 -35 394 12 103 49
                  222 88 283 22 34 33 42 67 47 71 11 237 25 355 31 413 20 603 49 814 122 158
                  54 277 124 372 218 49 49 87 96 95 118 16 45 13 46 174 -33z m-7074 -473 c151
                  -52 245 -113 380 -247 217 -215 409 -514 564 -880 55 -128 57 -138 57 -220 0
                  -61 -9 -124 -31 -220 -65 -273 -82 -416 -82 -683 0 -283 9 -382 80 -877 37
                  -257 55 -465 48 -550 -11 -117 -41 -183 -178 -387 -217 -321 -285 -493 -296
                  -743 -7 -164 17 -344 43 -318 3 4 1 57 -5 117 -24 225 16 435 121 639 27 53
                  58 108 69 124 l21 29 504 -256 c630 -319 637 -322 1425 -618 509 -192 642
                  -230 1074 -307 293 -53 275 -48 250 -67 -42 -31 -137 -74 -207 -92 -132 -35
                  -257 -48 -573 -56 -347 -9 -485 -19 -710 -50 -108 -15 -462 -41 -815 -60 -209
                  -11 -479 -32 -530 -40 -14 -2 -52 -7 -85 -10 -33 -4 -132 -20 -220 -36 -88
                  -16 -221 -36 -295 -45 -154 -17 -475 -20 -595 -4 -44 6 -138 14 -210 20 -255
                  18 -430 77 -510 172 -75 89 -189 319 -269 543 -26 72 -71 183 -101 247 -74
                  158 -115 283 -115 349 0 44 7 64 35 109 48 74 46 113 -10 291 -61 194 -71 313
                  -41 503 9 56 10 319 1 483 l-8 142 29 6 c424 96 809 378 1067 782 265 415 358
                  970 227 1347 -86 250 -291 428 -567 492 -71 16 -287 20 -345 5 -26 -6 -38 -6
                  -38 2 0 6 23 61 51 124 45 98 57 117 98 147 196 143 447 177 692 93z m8076
                  -23 c94 -114 281 -446 322 -570 l13 -42 -81 -93 c-244 -280 -370 -576 -388
                  -913 -17 -325 95 -678 303 -955 61 -82 198 -226 249 -263 36 -27 39 -32 37
                  -71 -4 -59 -42 -437 -47 -467 -2 -14 -7 -50 -10 -80 -37 -331 -130 -837 -211
                  -1150 -80 -309 -194 -639 -275 -801 -82 -165 -262 -268 -572 -329 -145 -28
                  -253 -37 -482 -39 -366 -3 -720 52 -945 145 -141 59 -153 62 -271 78 -138 18
                  -201 23 -519 41 -245 13 -426 27 -545 41 -167 19 -178 21 -315 55 -63 15 -185
                  40 -270 54 -401 67 -665 122 -858 181 -77 24 -723 263 -1050 390 l-122 47 -26
                  81 c-58 180 -42 319 59 516 35 69 81 177 102 240 61 185 98 241 188 287 61 30
                  162 37 357 23 114 -9 158 -13 260 -25 115 -14 485 -55 540 -60 36 -3 106 -10
                  155 -15 50 -5 117 -12 150 -15 33 -3 209 -18 390 -35 663 -60 811 -71 865 -65
                  30 4 163 24 295 45 476 77 544 79 785 30 465 -95 1111 -302 1544 -494 67 -30
                  96 -33 96 -11 0 36 -543 246 -977 378 -111 34 -205 67 -207 73 -2 5 -2 23 1
                  38 l6 28 126 -7 c106 -5 138 -11 202 -35 101 -39 160 -46 299 -39 87 5 133 13
                  189 33 76 26 155 68 166 87 16 25 -11 20 -87 -19 -75 -37 -174 -72 -202 -72
                  -6 0 4 12 22 26 66 52 42 82 -138 178 -161 85 -154 83 -414 102 l-89 6 35 35
                  34 34 100 -9 c54 -5 143 -8 197 -7 88 3 105 0 165 -24 94 -39 217 -69 283 -70
                  114 -2 196 -46 331 -179 108 -105 143 -126 96 -55 -63 95 -185 194 -293 238
                  l-58 23 41 21 c45 23 84 67 80 90 -5 24 -95 80 -263 166 -141 71 -155 76 -240
                  85 -109 11 -251 19 -325 20 l-54 0 39 40 c38 39 42 40 105 40 87 1 203 22 276
                  51 46 19 69 23 104 17 87 -13 264 -9 320 8 151 45 363 -6 502 -119 61 -51 68
                  -54 68 -33 0 24 -90 97 -163 131 -93 44 -175 65 -255 65 l-64 0 35 39 c62 69
                  84 134 54 159 -24 20 -403 72 -526 72 -27 0 -112 -16 -187 -35 -147 -38 -158
                  -37 -150 15 3 14 5 35 5 47 1 14 28 45 79 88 42 36 101 87 130 112 132 113
                  213 275 313 627 21 76 39 149 39 162 0 52 -21 19 -50 -78 -79 -265 -125 -399
                  -157 -466 -20 -40 -37 -71 -39 -70 -1 2 1 28 5 58 13 106 -14 193 -64 206 -56
                  14 -81 -4 -326 -236 -84 -80 -126 -114 -117 -95 25 48 57 154 69 225 21 130 7
                  435 -31 638 -25 137 60 485 175 715 112 223 279 433 444 557 165 125 349 192
                  510 186 79 -2 86 -4 108 -32z m-2947 -48 c-49 -107 -63 -184 -63 -336 0 -112
                  5 -160 31 -285 17 -82 38 -199 47 -260 9 -60 18 -119 20 -131 19 -122 27 -321
                  22 -544 -3 -140 -9 -266 -12 -280 -10 -41 -6 -550 5 -675 12 -136 15 -160 41
                  -287 17 -87 32 -135 99 -314 24 -64 15 -104 -35 -157 -58 -62 -77 -44 -129
                  121 -40 127 -82 303 -110 452 -46 252 -51 338 -48 740 2 206 5 456 5 555 3
                  261 -4 329 -66 665 -58 313 -56 574 7 724 13 30 20 35 92 54 84 22 117 29 122
                  27 1 -1 -11 -32 -28 -69z m-1055 -57 c97 -10 253 -43 267 -57 4 -4 -26 -55
                  -65 -113 -97 -142 -189 -307 -247 -440 -26 -61 -50 -113 -53 -115 -3 -3 -26
                  28 -52 68 -71 109 -218 288 -350 426 -65 67 -116 124 -114 126 8 7 147 56 204
                  72 60 17 208 40 275 42 19 1 80 -3 135 -9z m-1198 -69 c20 0 60 -182 85 -380
                  13 -105 17 -512 10 -1030 -6 -428 50 -820 157 -1105 22 -57 42 -117 46 -134 7
                  -34 -17 -91 -40 -91 -7 0 -23 -12 -34 -26 -36 -46 -50 -24 -126 198 -88 256
                  -135 498 -165 848 -5 58 -7 375 -6 705 2 330 0 650 -5 710 -9 99 -31 255 -45
                  312 -6 22 -6 22 53 8 32 -8 63 -14 70 -15z m1614 -188 c-101 -232 -144 -395
                  -175 -672 -3 -25 -8 -119 -11 -210 -3 -91 -8 -196 -10 -235 -26 -445 -25
                  -1832 1 -2175 l4 -56 -102 9 c-57 5 -106 12 -110 16 -3 3 -13 191 -22 416 -9
                  226 -18 451 -21 500 -2 50 -7 259 -11 466 l-7 376 52 5 c29 3 57 10 64 17 15
                  15 12 147 -8 333 -8 79 -12 144 -7 146 49 17 57 28 65 92 10 82 -6 374 -22
                  393 -6 8 -25 17 -42 21 -26 5 -29 9 -23 28 18 62 120 256 182 349 62 93 229
                  286 238 276 2 -2 -14 -45 -35 -95z m-4928 -33 c269 -67 462 -262 539 -544 19
                  -70 22 -106 22 -290 0 -239 -13 -323 -84 -538 -164 -491 -558 -928 -995 -1100
                  -92 -37 -252 -84 -259 -76 -2 2 -5 32 -7 67 l-4 64 105 32 c663 202 1128 841
                  1130 1555 1 210 -17 304 -87 450 -82 172 -234 293 -420 335 -83 18 -248 21
                  -323 5 -28 -6 -54 -9 -57 -6 -10 11 9 46 28 52 90 29 287 26 412 -6z m-58 -85
                  c168 -43 308 -158 383 -314 22 -47 48 -112 56 -145 97 -379 -27 -900 -300
                  -1265 -114 -152 -283 -305 -447 -404 -101 -61 -167 -90 -307 -137 l-115 -38
                  -6 31 c-4 16 -10 84 -14 149 l-7 119 35 0 c87 0 290 61 386 116 145 83 259
                  198 325 330 48 94 67 170 73 290 8 159 -28 290 -113 405 -45 62 -75 70 -245
                  71 -89 0 -129 4 -129 12 0 20 26 96 35 105 9 8 81 19 333 49 l63 7 30 90 c30
                  89 78 178 126 236 17 20 22 34 16 40 -15 15 -249 11 -339 -6 -107 -20 -207
                  -55 -301 -104 -42 -22 -78 -39 -80 -37 -4 3 96 298 121 358 15 35 18 37 83 47
                  135 21 241 20 338 -5z m8949 -238 c9 -27 16 -55 16 -63 0 -7 -25 -45 -56 -85
                  -97 -126 -191 -299 -239 -443 -62 -186 -81 -411 -50 -596 44 -266 172 -523
                  353 -709 l73 -75 -8 -65 -8 -65 -105 105 c-225 226 -374 502 -427 794 -23 122
                  -22 351 1 471 34 179 91 332 181 485 79 135 230 320 246 302 3 -4 14 -29 23
                  -56z m-8824 -41 c0 -4 -13 -25 -29 -48 -39 -57 -75 -131 -96 -197 -10 -30 -20
                  -58 -24 -61 -3 -4 -35 -9 -71 -12 -80 -7 -324 -38 -328 -42 -9 -8 -51 -179
                  -46 -187 3 -4 45 -5 94 -2 133 10 234 -2 269 -32 75 -63 139 -271 127 -412
                  -28 -344 -275 -597 -659 -676 -134 -28 -124 -36 -127 107 -3 146 -4 210 -2
                  258 2 36 2 36 54 42 123 15 310 142 363 248 93 183 -18 292 -265 262 -47 -5
                  -86 -9 -88 -8 -8 7 77 364 127 531 l21 71 107 50 c168 79 291 109 466 112 59
                  1 107 -1 107 -4z m8869 -112 c38 -140 77 -361 87 -486 l7 -86 -32 -36 c-54
                  -62 -74 -112 -79 -201 -5 -101 8 -163 59 -280 25 -57 38 -100 35 -115 -3 -13
                  -8 -128 -11 -254 -4 -127 -10 -246 -13 -264 l-7 -33 -68 78 c-125 145 -205
                  284 -262 458 -105 322 -82 621 76 952 47 99 176 304 191 304 4 0 12 -17 17
                  -37z m-4382 -89 c17 -5 24 -16 28 -48 10 -64 11 -354 1 -363 -12 -12 -213 -10
                  -226 3 -15 15 -29 143 -30 271 l0 112 28 15 c27 15 155 22 199 10z m-32 -532
                  c4 -37 9 -78 11 -92 2 -14 7 -83 11 -153 7 -143 6 -147 -57 -147 -66 0 -81 9
                  -76 44 2 17 8 99 12 181 4 83 8 159 9 170 1 11 3 30 4 41 1 18 7 22 40 22 l40
                  1 6 -67z m4514 -189 c-3 -302 -7 -316 -54 -170 -41 127 -31 248 25 328 12 16
                  23 29 26 29 3 0 4 -84 3 -187z m-9535 129 c60 -21 89 -56 89 -110 0 -122 -173
                  -279 -346 -313 -31 -7 -60 -9 -64 -5 -13 13 35 407 51 423 8 8 107 20 166 21
                  30 1 77 -7 104 -16z m8180 -458 c9 -8 19 -27 22 -41 12 -45 -3 -149 -27 -199
                  -24 -49 -163 -197 -249 -266 l-44 -35 -1 -81 0 -81 -80 -22 c-114 -30 -199
                  -72 -240 -116 -49 -53 -44 -61 14 -19 27 20 78 47 114 61 106 39 497 137 562
                  140 96 4 423 -33 481 -55 l25 -10 -20 -37 c-23 -45 -73 -94 -121 -119 -41 -21
                  -201 -28 -318 -13 -59 8 -78 7 -103 -6 -54 -29 -182 -56 -279 -61 l-94 -4 -46
                  -43 c-68 -65 -71 -67 -151 -73 -41 -4 -85 -12 -99 -19 -14 -7 -66 -52 -115
                  -100 -96 -93 -95 -92 80 31 80 56 64 55 365 41 239 -11 309 -17 350 -29 75
                  -23 390 -191 390 -209 0 -5 -17 -19 -37 -32 -106 -66 -179 -63 -388 18 -57 21
                  -81 26 -130 22 -33 -3 -127 0 -208 5 l-149 10 -56 -55 c-57 -55 -57 -55 -145
                  -66 -153 -20 -144 -30 28 -30 88 -1 174 -3 190 -5 17 -3 75 -8 129 -11 133 -9
                  218 -41 390 -148 l48 -30 -23 -18 c-77 -60 -211 -63 -345 -9 -73 29 -91 32
                  -230 37 l-151 5 -7 -52 c-6 -48 -9 -52 -29 -46 -12 3 -90 23 -174 43 -171 41
                  -163 35 -163 138 0 52 5 69 35 115 19 30 37 71 41 90 3 19 17 51 30 71 18 25
                  27 58 33 113 10 86 14 93 106 195 121 134 131 153 200 400 14 52 33 110 42
                  128 16 35 436 443 488 475 37 22 40 22 59 2z m-4042 -990 c28 -30 68 -64 90
                  -75 21 -11 38 -23 38 -27 0 -5 -11 -19 -25 -32 l-25 -23 -38 30 c-54 44 -125
                  152 -111 170 6 7 13 13 16 13 2 0 27 -25 55 -56z m2131 -373 c17 -29 74 -84
                  117 -113 l25 -16 -26 -27 -26 -26 -32 26 c-62 53 -113 148 -95 177 9 14 19 8
                  37 -21z m-3256 -91 c80 -13 174 -55 222 -100 l34 -31 -29 -18 c-16 -10 -35
                  -25 -43 -34 -11 -14 -17 -15 -40 -3 -60 30 -137 41 -296 41 -224 -1 -397 -27
                  -543 -80 l-67 -25 35 31 c46 39 254 142 355 174 144 46 268 61 372 45z m73
                  -195 c30 -9 58 -20 63 -24 4 -4 -4 -27 -17 -52 -14 -24 -39 -89 -57 -144 -17
                  -55 -38 -112 -45 -126 l-13 -26 -58 28 c-139 65 -376 99 -570 80 -102 -10
                  -244 -35 -321 -57 l-23 -7 23 39 c64 111 109 159 190 200 87 44 183 70 327 88
                  64 9 130 18 146 20 68 10 301 -3 355 -19z m-928 -87 c-68 -86 -122 -167 -153
                  -233 -38 -80 -69 -179 -69 -222 0 -13 -2 -23 -5 -23 -10 0 -165 80 -165 85 0
                  3 26 45 59 93 66 99 175 207 271 270 69 45 78 49 62 30z m534 -213 c108 -19
                  180 -40 257 -73 l57 -25 -34 -61 c-40 -72 -82 -192 -91 -258 l-6 -46 -57 28
                  c-176 87 -456 141 -696 135 -152 -3 -150 -4 -142 62 3 29 16 82 28 118 23 73
                  16 68 171 101 175 36 373 43 513 19z m-355 -339 c188 -24 322 -59 462 -124
                  l77 -35 0 -51 c0 -47 19 -145 35 -188 7 -17 5 -17 -31 -3 -72 29 -804 400
                  -804 407 0 13 136 9 261 -6z m2924 -1177 c131 -31 285 -55 416 -64 46 -4 93
                  -8 104 -10 11 -2 88 -7 170 -11 113 -4 153 -10 161 -21 7 -8 24 -53 39 -101
                  55 -176 279 -593 500 -928 213 -322 424 -583 704 -866 107 -109 193 -200 190
                  -202 -12 -11 -586 -105 -919 -150 -432 -59 -995 -116 -1305 -131 -66 -3 -129
                  -8 -140 -10 -93 -18 -1105 -27 -1440 -12 -158 7 -407 25 -590 43 -249 24 -629
                  85 -874 139 -151 34 -452 112 -460 121 -2 2 65 75 150 162 476 487 859 1029
                  1151 1627 40 83 77 153 82 156 4 4 49 10 100 13 50 4 127 11 171 17 44 5 97
                  11 118 14 213 26 271 29 667 40 213 6 253 9 382 26 170 22 319 78 403 152 46
                  40 37 40 220 -4z m1185 -115 c58 -4 111 -10 119 -14 8 -4 -11 -17 -55 -35
                  -112 -45 -293 -104 -298 -97 -5 9 -46 147 -46 157 0 4 39 5 88 1 48 -3 134 -8
                  192 -12z m216 -28 c21 -15 66 -107 78 -157 5 -24 0 -29 -56 -60 -93 -51 -152
                  -76 -240 -104 -119 -38 -117 -39 -166 60 -23 47 -42 89 -42 94 0 5 49 26 109
                  46 59 20 148 59 197 85 49 27 91 49 94 49 3 1 15 -5 26 -13z m199 -52 c240
                  -109 590 -166 1038 -168 l180 -1 54 -140 c114 -295 354 -1047 340 -1062 -3 -2
                  -11 13 -18 34 -25 76 -152 371 -215 497 -62 127 -129 231 -264 416 -39 52 -82
                  125 -97 162 -28 67 -43 85 -43 50 0 -37 75 -172 174 -311 166 -234 239 -375
                  371 -711 169 -429 199 -531 230 -783 26 -208 17 -373 -22 -451 -25 -49 -112
                  -133 -182 -178 -397 -250 -1376 -459 -2761 -588 -113 -10 -252 -22 -310 -25
                  -58 -4 -116 -8 -130 -11 -151 -24 -936 -24 -1255 1 -33 3 -123 9 -200 15 -77
                  6 -158 12 -180 15 -22 2 -71 7 -110 10 -189 17 -539 59 -795 96 -764 109
                  -1427 252 -1855 399 -183 64 -168 49 -203 197 -62 260 -72 350 -67 633 6 398
                  72 726 259 1285 130 387 134 397 155 390 33 -10 385 -13 495 -4 111 9 233 27
                  496 72 80 14 179 28 220 32 41 3 86 8 100 10 14 2 68 7 120 10 52 3 151 10
                  220 14 69 5 126 8 128 6 5 -5 -56 -152 -97 -233 -70 -138 -204 -331 -426 -612
                  -247 -312 -358 -472 -417 -597 -42 -90 -78 -234 -78 -317 0 -79 20 -175 39
                  -185 28 -15 341 -100 481 -130 351 -78 772 -139 1140 -166 47 -4 99 -8 115
                  -10 233 -26 1557 -26 1728 0 12 2 76 6 142 10 66 3 138 8 160 10 22 2 74 7
                  115 10 78 6 356 32 425 40 22 3 121 14 220 25 99 11 191 22 205 25 14 2 48 7
                  75 10 330 39 1032 158 1052 178 22 22 36 127 30 232 -12 198 -53 291 -240 543
                  -74 98 -169 235 -212 303 -146 230 -306 563 -404 842 -22 65 -46 127 -52 139
                  -10 19 -9 20 21 13 18 -4 65 -22 105 -41z m-3718 -71 c-3 -5 -25 -52 -50 -105
                  -26 -53 -50 -98 -54 -101 -21 -11 -157 24 -221 58 l-71 37 16 41 c9 23 19 45
                  22 49 4 4 69 11 146 16 195 13 217 13 212 5z m3650 -179 c39 -101 48 -85 -92
                  -163 -81 -45 -219 -101 -250 -101 -8 0 -72 106 -103 170 -1 3 32 16 75 29 87
                  25 216 85 283 130 24 16 47 28 49 25 3 -2 20 -43 38 -90z m-3992 5 c44 -30
                  108 -53 173 -64 23 -4 42 -9 42 -12 0 -5 -98 -192 -105 -201 -13 -16 -255 104
                  -255 127 0 22 86 189 95 183 5 -3 28 -18 50 -33z m-1907 23 c9 -6 -8 -63 -68
                  -232 -257 -716 -354 -1259 -309 -1735 13 -134 38 -280 65 -373 13 -45 24 -85
                  24 -87 0 -3 -23 6 -51 20 -165 84 -222 225 -215 535 10 427 170 1039 476 1823
                  23 58 43 70 78 49z m5978 -219 c61 -132 61 -118 -4 -160 -63 -40 -246 -130
                  -276 -135 -15 -2 -31 18 -72 87 -30 50 -54 93 -54 96 0 3 26 13 58 23 72 23
                  192 84 257 130 27 20 51 36 53 36 1 0 19 -35 38 -77z m-4124 -56 c24 -14 61
                  -30 83 -37 22 -7 44 -16 49 -20 7 -7 -88 -180 -100 -180 -32 0 -254 120 -254
                  138 0 2 25 45 56 94 l56 88 34 -29 c18 -16 53 -41 76 -54z m4233 -177 c25 -49
                  45 -93 45 -97 -1 -16 -271 -203 -292 -203 -7 0 -58 72 -112 158 l-27 43 33 11
                  c61 21 203 98 253 138 28 21 51 39 52 39 2 1 23 -39 48 -89z m-4304 -54 l86
                  -42 -58 -89 c-32 -49 -62 -91 -67 -93 -11 -3 -171 102 -219 145 l-32 28 60 82
                  61 83 42 -36 c22 -20 80 -55 127 -78z m4415 -148 l48 -81 -55 -50 c-92 -85
                  -210 -169 -223 -158 -6 6 -34 42 -61 82 -45 66 -48 73 -31 83 85 51 174 115
                  218 156 29 27 54 49 55 50 0 0 23 -37 49 -82z m-4543 -70 c37 -22 66 -44 65
                  -48 -9 -25 -125 -179 -134 -180 -13 0 -118 80 -193 147 l-54 49 68 86 68 87
                  56 -50 c31 -28 87 -69 124 -91z m4657 -111 l51 -78 -30 -37 c-41 -51 -117
                  -123 -170 -161 l-43 -31 -59 74 c-33 41 -59 78 -59 84 0 5 30 32 68 60 37 27
                  94 77 127 110 33 33 61 60 62 59 1 -1 25 -37 53 -80z m-4787 -117 c20 -14 37
                  -29 37 -33 0 -5 -21 -36 -47 -70 -27 -33 -53 -68 -59 -76 -10 -13 -15 -13 -40
                  4 -38 25 -138 120 -176 167 l-29 38 57 74 58 75 80 -77 c45 -42 98 -88 119
                  -102z m4912 -57 c30 -40 45 -68 41 -78 -18 -48 -181 -249 -196 -243 -4 2 -38
                  39 -75 83 -60 71 -66 80 -50 90 71 41 215 188 215 218 0 13 2 11 65 -70z
                  m-5219 30 c28 -44 104 -121 156 -159 l48 -36 -72 -85 -72 -86 -29 34 c-54 63
                  -146 189 -152 209 -5 15 7 38 41 85 26 36 51 65 55 65 4 0 15 -12 25 -27z
                  m5328 -177 c26 -35 46 -72 46 -82 0 -30 -63 -176 -100 -233 l-33 -52 -44 43
                  c-25 24 -58 60 -74 80 l-30 37 65 83 c36 45 78 106 93 135 16 29 29 53 30 53
                  1 0 22 -29 47 -64z m-5441 -7 c16 -27 56 -86 89 -130 l60 -80 -68 -73 c-38
                  -41 -74 -74 -79 -75 -14 -1 -77 116 -110 202 l-24 66 46 70 c26 39 50 71 53
                  71 3 0 18 -23 33 -51z m5583 -270 c18 -66 26 -300 11 -316 -4 -4 -55 40 -112
                  97 l-104 104 20 31 c31 45 86 164 99 215 l12 45 28 -56 c16 -30 37 -84 46
                  -120z m-5649 -51 l42 -77 -95 -90 c-52 -50 -99 -91 -103 -91 -13 0 -22 89 -15
                  155 7 74 36 184 65 249 l22 50 21 -60 c12 -33 41 -94 63 -136z m-1269 -1038
                  l114 -45 -9 -55 c-4 -30 -7 -127 -7 -215 1 -148 17 -271 50 -392 6 -21 0 -19
                  -70 19 -181 99 -225 184 -226 436 0 115 18 305 29 300 3 -2 57 -23 119 -48z"
                  />
                  <path d="M7955 18536 c-92 -41 -168 -198 -229 -471 -29 -126 -62 -348 -72
                  -480 -3 -49 -8 -101 -9 -115 -12 -99 -15 -638 -6 -872 10 -231 9 -283 -2 -310
                  -19 -46 -30 -108 -19 -108 6 0 29 44 52 98 67 152 99 212 150 282 71 96 86
                  107 152 112 54 4 77 12 67 22 -2 3 -31 8 -64 11 -69 8 -107 25 -130 57 -15 23
                  -15 23 26 30 22 4 72 21 109 39 61 28 73 30 101 20 18 -6 48 -11 68 -10 l36 1
                  -47 22 c-113 54 -163 114 -194 230 -21 80 -20 86 24 86 57 0 372 -57 415 -75
                  23 -9 58 -15 85 -13 l47 3 -68 23 c-83 29 -141 72 -184 135 -33 49 -66 128
                  -76 185 l-6 32 42 0 c52 0 202 -31 296 -60 56 -18 76 -30 101 -62 34 -41 125
                  -118 142 -118 5 0 -19 31 -54 69 -75 83 -122 158 -154 247 -25 67 -31 124 -14
                  124 15 0 168 -70 222 -101 123 -72 208 -136 345 -257 46 -41 78 -62 88 -58 8
                  3 15 9 15 13 0 8 -602 713 -810 949 -278 316 -346 364 -445 320z m111 -46 c48
                  -24 125 -102 329 -335 208 -237 565 -653 565 -660 0 -2 -33 17 -72 44 -98 65
                  -189 114 -277 151 -90 37 -121 39 -122 8 -3 -63 8 -132 29 -186 10 -28 -111
                  83 -184 169 -140 164 -242 333 -315 524 -42 111 -54 134 -65 123 -14 -15 42
                  -174 106 -303 91 -182 178 -303 321 -445 l115 -116 -85 19 c-69 16 -102 30
                  -167 73 -133 87 -267 244 -326 380 -13 30 -29 54 -36 54 -18 0 -9 -27 44 -130
                  66 -128 170 -246 294 -333 l25 -17 -40 1 c-71 2 -68 4 -62 -53 7 -72 40 -162
                  82 -225 19 -29 31 -53 27 -53 -5 0 -58 9 -118 19 -172 30 -232 34 -242 15 -21
                  -38 22 -198 74 -275 19 -28 34 -52 34 -54 0 -1 -24 -13 -52 -26 -34 -14 -74
                  -23 -110 -23 -31 -1 -59 -4 -63 -8 -11 -11 34 -95 65 -122 l29 -24 -26 -23
                  c-30 -29 -143 -195 -143 -212 0 -8 -3 -8 -11 0 -13 13 -18 784 -6 973 31 490
                  127 908 237 1034 53 60 81 67 146 36z"/>
                  <path d="M13042 18039 c-155 -28 -444 -325 -734 -754 -210 -310 -275 -425
                  -239 -425 5 0 52 27 104 60 140 88 265 140 432 180 99 25 95 25 95 -3 0 -74
                  -65 -238 -119 -299 -28 -32 -143 -98 -170 -98 -6 0 -11 -4 -11 -10 0 -30 116
                  6 197 62 68 47 168 80 241 80 61 0 137 -17 147 -32 9 -14 -52 -159 -88 -211
                  -65 -94 -90 -115 -163 -137 -63 -19 -124 -51 -99 -52 6 0 62 11 125 24 111 23
                  173 25 245 7 44 -11 57 -64 59 -241 0 -80 -1 -170 -4 -200 -4 -45 -2 -55 12
                  -58 22 -4 46 61 101 284 183 734 205 1418 55 1713 -48 94 -101 125 -186 110z
                  m101 -63 c121 -112 175 -556 122 -1007 -29 -250 -69 -486 -118 -684 l-31 -130
                  -7 100 c-4 55 -16 125 -26 155 l-18 55 -60 8 c-33 5 -74 10 -92 13 l-32 5 34
                  48 c47 65 104 190 111 244 6 42 5 44 -30 61 -78 37 -213 40 -302 6 -23 -9 -25
                  -8 -19 7 46 108 77 238 67 285 -2 10 -15 18 -33 20 -26 3 -20 8 64 55 103 57
                  217 138 217 153 0 20 -26 8 -132 -61 -91 -59 -322 -179 -393 -203 -25 -9 -24
                  -8 99 95 115 95 270 249 367 364 97 115 115 143 102 156 -7 7 -34 -17 -84 -78
                  -95 -115 -301 -325 -414 -422 -133 -114 -200 -162 -298 -214 -104 -55 -108
                  -68 55 176 245 369 411 568 608 729 111 90 192 111 243 64z"/>
                  <path d="M8680 16472 c-308 -231 -517 -451 -683 -719 -263 -426 -491 -1054
                  -556 -1533 -22 -162 -24 -200 -8 -200 11 0 18 31 31 128 57 426 216 923 431
                  1346 214 423 447 692 918 1064 145 114 64 62 -133 -86z"/>
                  <path d="M8955 16202 c-301 -197 -555 -470 -739 -797 -31 -55 -56 -106 -56
                  -112 0 -26 16 -12 40 34 37 71 183 286 248 367 129 162 342 363 522 494 90 66
                  97 72 84 72 -5 0 -50 -26 -99 -58z"/>
                  <path d="M11772 16012 c324 -338 528 -660 683 -1077 31 -83 91 -305 131 -480
                  5 -22 14 -38 19 -35 14 7 8 67 -21 200 -107 486 -381 987 -729 1335 -166 165
                  -227 207 -83 57z"/>
                  <path d="M12456 15833 c97 -208 171 -427 208 -618 20 -103 46 -316 46 -377 0
                  -62 20 -63 21 0 5 297 -84 664 -236 967 -72 144 -103 167 -39 28z"/>
                  <path d="M7882 13550 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                  <path d="M7801 13234 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                  <path d="M8995 14779 c-27 -6 -86 -27 -130 -49 -140 -67 -235 -171 -235 -256
                  0 -24 4 -45 9 -48 5 -3 51 16 102 43 197 104 368 151 578 160 100 4 146 10
                  148 18 6 18 -100 83 -182 111 -80 28 -209 37 -290 21z m270 -51 c33 -11 73
                  -28 89 -38 l28 -17 -88 -7 c-186 -14 -362 -63 -529 -145 -55 -27 -102 -47
                  -104 -45 -9 9 27 73 64 113 69 76 191 140 305 161 62 12 163 2 235 -22z"/>
                  <path d="M11022 14778 c-77 -8 -152 -32 -209 -65 -64 -36 -56 -49 35 -57 211
                  -19 419 -96 618 -232 57 -38 101 -61 106 -56 18 18 11 97 -15 147 -28 56 -110
                  138 -183 185 -101 64 -229 92 -352 78z m159 -38 c86 -16 188 -71 260 -138 64
                  -59 109 -130 109 -169 0 -18 -11 -14 -82 33 -159 105 -365 187 -536 215 -78
                  12 -82 14 -58 26 71 37 207 52 307 33z"/>
                  <path d="M8960 14295 c-156 -38 -313 -146 -399 -276 -34 -50 -39 -65 -34 -97
                  6 -44 42 -128 69 -160 l19 -23 -3 53 c-3 37 3 71 19 113 30 82 145 198 240
                  243 37 18 70 32 75 32 4 0 -11 -16 -34 -35 -60 -52 -102 -143 -103 -225 -2
                  -82 14 -133 57 -193 61 -83 160 -139 275 -152 69 -8 184 15 243 50 84 49 165
                  159 166 225 0 14 4 31 9 38 10 17 21 -56 21 -142 0 -57 -3 -65 -32 -93 -153
                  -147 -554 -185 -780 -73 -33 17 -55 24 -49 16 19 -23 152 -83 211 -95 78 -17
                  262 -14 347 3 159 34 273 91 317 158 22 34 23 146 2 248 -54 257 -240 402
                  -510 399 -39 0 -96 -7 -126 -14z m292 -103 c10 -4 -16 -7 -56 -7 -64 0 -78 -4
                  -117 -30 l-44 -29 54 -7 c56 -7 104 -35 109 -62 9 -43 -111 -72 -187 -46 -18
                  6 -34 9 -37 6 -3 -3 -5 -30 -5 -60 0 -71 26 -132 78 -178 51 -46 100 -63 169
                  -57 164 14 260 198 179 347 -16 29 -45 64 -65 77 -50 34 -26 30 29 -5 140 -90
                  186 -211 130 -340 -98 -228 -468 -246 -603 -29 -34 55 -47 161 -27 221 39 118
                  175 207 316 206 33 0 68 -3 77 -7z"/>
                  <path d="M11040 14299 c-143 -9 -242 -55 -330 -152 -72 -79 -129 -246 -131
                  -384 -1 -79 12 -100 99 -159 106 -73 241 -112 422 -121 129 -6 202 6 298 52
                  80 38 99 56 25 24 -122 -52 -315 -67 -460 -35 -171 37 -333 125 -351 189 -10
                  36 2 158 17 185 9 14 10 12 11 -14 1 -101 100 -229 215 -275 50 -20 77 -24
                  165 -24 92 0 112 4 162 27 211 98 273 333 132 504 l-37 46 54 -28 c31 -15 87
                  -60 130 -103 93 -93 123 -159 117 -258 -4 -61 -3 -62 15 -45 30 27 65 93 76
                  142 9 38 7 51 -11 87 -32 64 -114 158 -182 209 -136 104 -269 144 -436 133z
                  m91 -115 c140 -44 229 -169 216 -300 -7 -70 -36 -122 -98 -178 -163 -146 -448
                  -96 -543 97 -30 58 -35 146 -12 200 15 36 94 112 147 140 24 13 28 14 15 3
                  -19 -15 -17 -16 30 -16 59 0 118 -27 122 -56 7 -52 -104 -83 -182 -50 -22 9
                  -41 14 -44 11 -12 -11 -13 -104 -3 -143 7 -24 28 -61 48 -83 52 -58 99 -79
                  176 -79 172 0 278 175 203 337 -29 60 -90 110 -148 119 -20 4 -35 10 -32 15 6
                  10 34 5 105 -17z"/>
                  <path d="M9988 13720 c-4 -14 -17 -70 -29 -125 -43 -202 -103 -356 -203 -520
                  -20 -33 -36 -69 -36 -81 0 -40 71 -93 187 -138 110 -43 120 -38 29 16 -49 29
                  -106 69 -128 88 l-39 35 36 50 c55 75 122 214 149 307 26 91 50 277 44 348 -3
                  37 -5 40 -10 20z"/>
                  <path d="M10030 12943 c0 -5 20 -16 44 -26 24 -10 62 -33 84 -52 43 -37 62
                  -44 62 -21 0 19 -55 65 -105 87 -41 19 -85 25 -85 12z"/>
                  <path d="M10984 12874 c26 -20 44 -40 39 -44 -19 -17 -150 -82 -233 -115 -225
                  -90 -404 -126 -650 -130 -194 -4 -280 4 -448 41 -110 25 -289 82 -382 122
                  l-45 20 28 12 c37 18 34 30 -6 21 -69 -16 -134 -64 -167 -123 l-24 -43 44 38
                  44 37 17 -83 c28 -140 87 -233 229 -362 47 -42 114 -94 150 -115 320 -187 858
                  -153 1155 73 67 50 186 178 236 252 46 68 78 164 86 253 l6 76 45 -50 c35 -38
                  43 -44 37 -24 -19 65 -88 140 -151 166 -56 23 -59 16 -10 -22z m-5 -165 c-31
                  -80 -68 -137 -132 -206 -66 -70 -63 -81 7 -28 26 19 49 35 52 35 3 0 -10 -28
                  -28 -62 -39 -75 -150 -189 -231 -237 -75 -44 -159 -77 -276 -108 -87 -24 -115
                  -26 -291 -27 -172 -1 -204 2 -276 21 -159 44 -268 107 -369 215 -91 96 -174
                  268 -92 191 31 -30 107 -83 117 -83 3 0 -26 33 -63 72 -37 40 -84 96 -103 124
                  -37 54 -68 127 -55 132 4 1 46 -15 92 -36 155 -71 279 -109 464 -144 153 -28
                  455 -31 605 -5 189 32 400 100 539 172 25 13 49 24 53 25 4 0 -1 -23 -13 -51z"/>
                  <path d="M8275 11508 c15 -70 76 -224 116 -294 80 -140 196 -245 331 -298 66
                  -26 128 -34 128 -17 0 5 -24 15 -52 21 -222 52 -405 255 -489 543 -22 75 -47
                  109 -34 45z"/>
                  <path d="M12000 11283 c-95 -148 -263 -293 -434 -374 -45 -22 -64 -35 -57 -42
                  13 -13 152 58 241 123 74 55 189 172 241 245 55 77 80 123 72 128 -4 3 -33
                  -33 -63 -80z"/>
                  <path d="M10754 11423 c-56 -250 -192 -748 -288 -1057 -43 -136 -50 -175 -27
                  -150 34 38 210 645 300 1035 23 97 41 181 41 187 0 23 -20 11 -26 -15z"/>
                  <path d="M9524 11143 c6 -21 38 -205 57 -338 9 -62 15 -80 17 -56 6 58 -55
                  411 -70 411 -5 0 -7 -8 -4 -17z"/>
                  <path d="M12328 8864 c-3 -3 29 -60 72 -127 81 -128 184 -318 241 -449 19 -43
                  40 -78 47 -78 16 0 16 -2 -19 87 -54 137 -180 363 -285 511 -47 65 -47 65 -56
                  56z"/>
                  <path d="M6880 8426 c0 -5 13 -30 29 -55 49 -76 118 -230 146 -326 27 -94 55
                  -253 55 -317 0 -21 5 -38 10 -38 29 0 -4 255 -50 393 -35 104 -119 270 -161
                  319 -16 18 -28 29 -29 24z"/>
                  <path d="M6750 8027 c0 -1 28 -32 63 -67 73 -75 113 -141 177 -294 71 -168
                  101 -154 34 16 -61 153 -138 269 -213 319 -39 26 -61 35 -61 26z"/>
                  <path d="M6761 6867 c-13 -85 -48 -225 -72 -294 -57 -161 -120 -271 -238 -418
                  -132 -163 -147 -209 -82 -252 49 -33 139 -32 209 3 48 24 162 133 162 155 0 5
                  -37 -22 -81 -61 -61 -52 -95 -74 -135 -86 -64 -18 -118 -11 -151 22 -23 23
                  -23 25 -7 56 9 18 47 71 84 118 132 168 203 292 258 455 34 100 66 254 59 288
                  -2 12 -5 18 -6 14z"/>
                  <path d="M7089 6428 c0 -2 -2 -16 -4 -33 -17 -135 -49 -243 -105 -355 -94
                  -186 -293 -378 -469 -451 -28 -12 -51 -28 -51 -35 0 -22 24 -16 106 26 262
                  135 454 382 520 668 22 93 29 182 15 182 -6 0 -11 -1 -12 -2z"/>
                  <path d="M6870 5373 c-112 -89 -333 -305 -429 -420 -188 -224 -369 -513 -322
                  -513 5 0 61 78 124 173 63 94 162 228 220 297 113 136 329 351 445 444 67 54
                  89 86 59 86 -7 0 -51 -30 -97 -67z"/>
                  <path d="M5840 5423 c-232 -22 -393 -77 -560 -189 -92 -62 -103 -71 -96 -83 4
                  -6 34 8 73 35 169 116 359 183 562 200 57 5 81 4 81 -4 0 -6 -72 -66 -159
                  -132 -213 -159 -322 -252 -416 -352 -73 -78 -175 -211 -175 -230 0 -25 32 0
                  75 60 110 150 275 301 558 512 76 56 144 114 150 127 10 22 8 28 -12 44 -13
                  11 -28 18 -35 17 -6 0 -27 -3 -46 -5z"/>
                  <path d="M6509 5330 c-247 -66 -516 -200 -714 -355 -112 -88 -211 -195 -180
                  -195 3 0 52 41 108 91 230 205 512 355 816 433 69 18 127 38 129 44 6 19 -43
                  13 -159 -18z"/>
                  <path d="M7066 4953 c-3 -15 -9 -82 -12 -148 -12 -240 57 -487 194 -694 65
                  -98 192 -221 192 -186 0 2 -34 46 -75 97 -86 108 -150 212 -193 318 -66 161
                  -98 357 -87 522 9 118 9 112 -3 116 -5 1 -12 -10 -16 -25z"/>
                  <path d="M7636 4051 c-13 -20 190 -114 367 -170 110 -35 243 -66 332 -76 32
                  -4 68 -9 79 -11 93 -18 516 4 516 26 0 5 -98 6 -217 3 -242 -6 -361 3 -541 43
                  -169 38 -383 116 -524 192 -3 1 -9 -1 -12 -7z"/>
                  <path d="M11990 5446 c0 -7 33 -20 78 -30 131 -31 428 -133 539 -185 176 -85
                  294 -168 425 -302 67 -68 120 -115 123 -109 20 32 -162 214 -313 314 -211 141
                  -458 235 -834 320 -10 2 -18 -1 -18 -8z"/>
                  <path d="M10700 5172 c0 -18 105 -180 169 -262 349 -448 941 -754 1666 -861
                  133 -20 403 -18 490 4 75 19 205 75 205 88 0 19 -21 16 -91 -11 -124 -48 -212
                  -60 -404 -56 -446 10 -1028 195 -1400 446 -236 159 -467 399 -577 600 -18 33
                  -39 60 -45 60 -7 0 -13 -4 -13 -8z"/>
                  <path d="M14200 5054 c0 -3 11 -15 25 -26 77 -60 70 -169 -14 -216 -21 -12
                  -84 -30 -139 -41 -111 -22 -160 -40 -107 -41 60 0 207 29 250 50 47 23 81 67
                  91 119 9 43 -20 115 -56 141 -28 20 -50 26 -50 14z"/>
                  <path d="M10406 4952 c-14 -23 61 -265 116 -377 82 -165 238 -351 389 -465 85
                  -64 225 -138 237 -125 4 4 -2 12 -12 19 -78 46 -233 154 -289 203 -185 159
                  -334 404 -395 648 -24 98 -33 117 -46 97z"/>
                  <path d="M10226 8249 c-21 -3 -42 -12 -47 -20 -14 -22 -10 -73 7 -98 15 -21
                  22 -23 94 -19 43 1 81 6 85 10 12 12 12 41 0 82 -11 39 -15 41 -56 46 -24 3
                  -61 3 -83 -1z m98 -51 c12 -45 8 -48 -49 -48 -49 0 -57 3 -64 23 -13 33 5 47
                  59 47 41 0 49 -3 54 -22z"/>
                  <path d="M10271 8090 c-29 -5 -57 -12 -62 -17 -4 -4 -9 -44 -11 -88 l-3 -79
                  83 -1 c78 0 83 1 89 23 7 29 -19 165 -32 167 -5 2 -34 -1 -64 -5z m42 -47 c3
                  -5 8 -29 11 -55 l7 -48 -56 0 -57 0 6 38 c3 20 5 42 6 49 0 16 75 30 83 16z"/>
                  <path d="M8500 2832 c0 -22 53 -159 84 -219 167 -316 504 -539 1001 -662 286
                  -71 656 -125 1040 -151 243 -16 820 -2 1010 24 17 3 68 10 115 16 47 6 89 16
                  95 21 12 12 -8 12 -205 -6 -91 -7 -226 -16 -300 -20 -74 -3 -182 -8 -240 -11
                  -130 -6 -530 11 -733 32 -445 45 -854 132 -1112 235 -351 140 -604 378 -707
                  665 -17 46 -35 84 -39 84 -5 0 -9 -4 -9 -8z"/>
                  <path d="M8130 1684 c0 -9 498 -75 550 -74 20 0 22 2 9 10 -8 5 -118 20 -245
                  35 -126 14 -248 28 -271 31 -24 4 -43 2 -43 -2z"/>
                  <path d="M6630 2248 c-70 -11 -262 -76 -394 -134 -113 -49 -133 -60 -125 -68
                  3 -3 52 15 110 40 174 76 362 135 432 137 l62 2 -1 -37 c-2 -58 -57 -122 -288
                  -330 -205 -184 -254 -234 -243 -244 7 -7 44 25 278 238 205 186 263 253 279
                  321 15 70 -12 89 -110 75z"/>
                  <path d="M13446 1954 c-218 -409 -508 -724 -888 -962 -85 -53 -280 -150 -377
                  -188 -66 -25 -93 -46 -78 -60 8 -8 119 32 230 84 394 181 731 467 977 827 73
                  108 200 341 200 368 0 36 -20 14 -64 -69z"/>
                  <path d="M6488 1313 c-23 -26 -172 -253 -191 -290 -10 -18 -13 -34 -8 -38 9
                  -5 213 292 228 331 7 20 -11 18 -29 -3z"/>
                  <path d="M12170 1200 c-15 -28 -171 -162 -249 -214 -95 -64 -215 -122 -327
                  -160 -94 -31 -131 -56 -84 -56 34 0 191 55 268 94 114 58 252 156 345 246 48
                  47 87 91 87 98 0 19 -28 14 -40 -8z"/>
                  <path d="M10993 219 c4 -30 10 -91 13 -136 6 -71 10 -83 25 -83 10 0 17 6 14
                12 -2 7 -6 48 -9 91 -6 88 -21 153 -38 163 -8 5 -9 -8 -5 -47z"/>
                </g>
              </motion.svg>
              <motion.div 
                initial={{opacity: 0, y:50}}
                animate={{opacity: 1, y:0}}
                transition={{duration: 1, delay: 0.5, ease: 'easeInOut'}}
                className='absolute -z-10 w-[75%] h-[75%] bg-[#4D4DFF] blur-3xl rounded-full'
              ></motion.div>
            </motion.div>

            {/* Neongasmic */}
            <motion.div 
              initial={{opacity: 0, y:50}}
              animate={{opacity: 1, y:0,}}
              transition={{duration: 1, delay: 0.5, ease: 'easeInOut'}}
              className="text-white w-full mx-auto flex flex-col gap-y-10 items-center py-10 mb-7 h-[440px] mt-20"
            >
              <div className="flex flex-col gap-y-5 w-full items-center">
                <h2 className="text-7xl sm:text-8xl md:text-9xl font-bold w-full font-Poppins text-center tracking-wide leading-tight uppercase">
                  <span style={{fontFamily: "Londrina Outline"}} className=''>
                    Neongasmic 
                  </span> 
                </h2>
        
                <h3 className="text-base sm:text-xl md:text-2xl text-[#CFFF04] font-semibold uppercase tracking-widest  mx-auto text-center">
                  The World's Most Affordable Signs.
                </h3>
              </div>

              <div className="flex gap-x-8">
                <NeonButton
                  Text={"Products"}
                  handleClick={() => navigate("/products")}
                />
                <NeonButton 
                  Text={"Custome"} 
                  handleClick={() => navigate("/custome")}
                />
              </div>
            </motion.div>

            {/* Hello Neon */}
            <motion.div
              initial={{opacity: 0, y:50}}
              animate={{opacity: 1, y:0}}
              transition={{duration: 2.5, ease: 'easeInOut'}}
              style={{fontFamily: "Great Vibes"}}
              className='text-white text-5xl font-bold tracking-wide leading-tight absolute top-20 right-0 mr-10 mt-10 lg:flex hidden'
            >
              <motion.p
                animate={{translateY: [150, 50, 150], }}
                transition={{repeat: Infinity, duration: 3.5, ease: 'easeInOut'}}
                style={{fontFamily: "Great Vibes", textShadow: allColors['lemon yellow']['text-shadow'], rotateZ: '-20deg'}}
              >
                Hello
              </motion.p>
            </motion.div>

          </div>
        </div>
      </div>
      <WhyUs />
      <motion.div className='bg-black flex justify-between'
        initial={{opacity: 0, y: 38}} 
        whileInView={{opacity: 1, y:0}} 
        transition={{duration: 0.3, delay: 0.3}} 
        viewport={{once: false}} 
      >
        <div className='w-full max-w-[1380px] mx-auto h-full'>
          <div className="w-full flex items-center justify-between h-full relative ">
            <div className='flex flex-col justify-start items-center gap-y-6 place-self-start'>
              <motion.div className='relative '
                initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.3}} viewport={{once: false}}
              >
                <svg width="20px" height="20px" viewBox="0 -0.5 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#ffffff" fill-rule="evenodd" d="M474.188327,259.775909 L480.842912,259.775909 L477.549999,256.482996 L474.375904,259.65709 C474.321124,259.71187 474.256777,259.751373 474.188327,259.775909 Z M474,258.618781 L474,247.775909 L486,247.775909 L486,254.968826 L483.657827,252.626653 C483.470927,252.439753 483.148791,252.4342 482.953529,252.629462 C482.940375,252.642616 482.928101,252.656403 482.916711,252.670736 C482.913161,252.674075 482.909651,252.677479 482.906183,252.680947 L479.034173,256.552957 L477.918719,255.437503 C477.808988,255.327771 477.655516,255.279359 477.507786,255.29536 C477.387162,255.302309 477.267535,255.351246 477.17513,255.44365 L474,258.618781 Z M482.257125,259.775909 L486,259.775909 L486,256.377007 L485.996984,256.380023 L483.309152,253.692192 L479.74128,257.260064 L482.257125,259.775909 Z M487,259.406871 L487.960593,259.541874 C488.51207,259.619379 489.020377,259.235606 489.097766,258.684953 L490.765938,246.815293 C490.843443,246.263816 490.459671,245.75551 489.909017,245.678121 L478.039358,244.009949 C477.487881,243.932444 476.979574,244.316216 476.902185,244.86687 L476.633887,246.775909 L474.006845,246.775909 C473.449949,246.775909 473,247.226689 473,247.782754 L473,259.769063 C473,260.32596 473.45078,260.775909 474.006845,260.775909 L485.993155,260.775909 C486.550051,260.775909 487,260.325128 487,259.769063 L487,259.406871 Z M487,258.397037 L488.10657,258.552556 L489.776647,246.669339 L477.89343,244.999262 L477.643739,246.775909 L485.993155,246.775909 C486.54922,246.775909 487,247.225857 487,247.782754 L487,258.397037 Z" transform="translate(-473 -244)"/>
                </svg>
                <span className='absolute left-0 top-0 h-full w-full bg-[#7ee787] blur-lg'></span>
              </motion.div>
              <motion.div style={{background: 'linear-gradient(#d2a8ff, #a371f7 10%, #196c2e 70%, #2ea043 80%, #56d364)'}}
                className='max-md:w-[2px] w-[3px] h-[520px] max-md:h-[650px] max-sm:h-[750px] max-ssm:h-[900px] max-sssm:h-[1160px] rounded-md line'
                initial={{height: 0}} whileInView={{height: 430}} transition={{duration: 0.7, delay: 0.6}}              
              ></motion.div>
            </div>

            <Slider>
            {
              carouselImages.map((image) => (
                <SwiperSlide className='w-[50px] h-60 '>
                  <img src={image} className='w-full lg:h-[25rem] md:h-[18rem] h-[8rem]  object-cover rounded-lg'/>
                </SwiperSlide>
              ))
            }
            </Slider>
          </div>
        </div>
      </motion.div>
      <HowToInstall />
      <Footer />
    </div>
  );
}

export default Home;
