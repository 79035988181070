import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CAFE_RESTAURANT, EVENT, MDF_ACRYLIC_LOGO, OTHERS } from '../../../data/productList';
import Slider from '../Home/Slider';
import { SwiperSlide } from 'swiper/react';
import ProductCard from './ProductCard';
import { motion } from "framer-motion"
import { PRODUCT_DROPDOWN } from '../../../data/dropDownData';
import { useDispatch } from 'react-redux';
import { setEditAddress, setStep } from '../../../slices/paymentSlice';

const FilterMapping = {
  'mdf-and-acrylic-logos': MDF_ACRYLIC_LOGO,
  'events': EVENT,
  'cafe-restaurants': CAFE_RESTAURANT,
  'others': OTHERS
}

const ProductsList = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentData, setCurrentData] = useState({
    filter: "",
    data: [],
    type: "",
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const filter = query.get('filter');
    const link = location.pathname+location.search
    let type;
    for (let entry of PRODUCT_DROPDOWN) {
      if (entry.link === link) {
        type = entry.name;
        break;
      }
    }

    if (filter) {
      setCurrentData({
        filter: filter,
        data: FilterMapping[filter],
        type: type,
      });
    }

  }, [location.search]);

  useEffect(() => { 
    dispatch(setStep(1));
    dispatch(setEditAddress(false))
    sessionStorage.removeItem('step');
    sessionStorage.removeItem('currentOrder');
  }, []) 
  return (
    <div className='text-white bg-black h-auto'>
      <div className='w-full max-w-[1280px] mx-auto flex flex-col items-center gap-y-6 h-auto py-20'>
        {
          !currentData 
          ? (
            <div className='w-full flex items-center justify-center h-[calc(100vh-7rem)] overflow-hidden'>
              <div className='loader'></div>
            </div>
          )
          : (
            <div>
              <motion.div className='xl:w-full md:w-[70%] sm:w-[50%] w-[40%]  max-w-[1280px] flex flex-col gap-y-4 mx-auto px-20 xl:px-0 mt-10'
                initial={{opacity: 0, y:50}}
                animate={{opacity: 1, y:0,}}
                transition={{duration: 0.5, delay: 0.5, ease: 'easeInOut'}}
              >
                <h2 className='text-2xl md:text-4xl font-Lato capitalize xl:w-full md:w-[40%]  mx-auto'>
                  {currentData.type}
                </h2>
                <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 md:gap-x-20 gap-y-20 place-items-start text-center py-2 w-full'>
                  {currentData.data.map((product, index) => (
                    <ProductCard product={product} index={index} isSwiper={false} type={currentData.type}/>
                  ))}
                </div>
              </motion.div>
              
              {/* Other Product */}
              <div className='mt-20 flex flex-col'>
                <motion.h2 
                  initial={{opacity: 0, y: 38}} whileInView={{opacity: 1, y:0}} transition={{duration: 0.3, delay: 0.3}} viewport={{once: false}} 
                  className='text-center text-5xl'
                  >
                  Other Products
                </motion.h2>
                {Object.keys(FilterMapping).filter((key) => key !== currentData.filter).map((filter, index) => {
                  let type;
                  const link = `/products?filter=${filter}`;
                  for (let entry of PRODUCT_DROPDOWN) {
                    if (entry.link === link) {
                      type = entry.name;
                      break;
                    }
                  }

                  return (
                  <motion.div
                    initial={{opacity: 0, y: 38}} whileInView={{opacity: 1, y:0}} transition={{duration: 0.3, delay: 0.3}} viewport={{once: false}} 
                    className='flex flex-col w-full mx-auto px-20 mt-10'
                  >
                    <h2 className='text-2xl xl:w-full lg:w-[10%] mx-auto capitalize'>{type}</h2>
                    <Slider>
                      {
                        FilterMapping[filter].map((product, index) => (
                          <SwiperSlide>
                            <ProductCard product={product} index={index} isSwiper={true} filter={filter} type={type}/>
                          </SwiperSlide>
                        ))
                      }
                    </Slider>
                  </motion.div>)
                })}
              </div>
            </div>
          )
        } 
      </div>
    </div>
  );
}

export default ProductsList;
