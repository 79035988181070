import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allFonts from "../../../data/google-fonts.json"
import { setFontStyle } from '../../../slices/productSlice';

const SelectFont = () => {
    const {fontStyle, text} = useSelector((state) => state.product)
    const dispatch = useDispatch();
    let fonts = []
  return (
    <div className='h-80 w-full overflow-y-scroll '>
        <div className='w-full relative'>
            <div className='sticky -top-1 lg:top-0 left-0 right-0 bg-black  font-Lato font-semibold text-lg py-3 z-[1]'>
                <div className='flex items-center text-sm sm:text-base gap-x-1'>
                    <p className='text-white'>
                        Choose Font : 
                    </p>
                    <p className='text-[#CFFF04]'>
                        {fontStyle}
                    </p>
                </div>
            </div>
            <div className='grid sm:grid-cols-3 grid-cols-2 w-full gap-5 place-items-center pr-7 py-4'>
                {
                    Object.entries(allFonts).map((font, index) => {
                        fonts.push(font[0]);
                        return (
                        <div 
                            key={index}
                            onClick={() => dispatch(setFontStyle(font[0]))}
                            style={
                                {fontFamily: font[0]}
                            }
                            className={`${fontStyle == font[0] ? "bg-neonViolet" : "bg-white text-white/50"} cursor-pointer p-[1px] rounded-lg w-full text-center text-lg font-Lato font-semibold 
                                transition duration-300 hover:bg-neonViolet hover:text-white relative`
                            }
                        >
                            <div className={`${font[0] === 'Amsterdam' ? "text-[0.4rem] sm:text-xs" : "text-xs sm:text-base"} w-full h-full bg-black px-1 py-2 sm:px-2 sm:py-3 rounded-lg`}>
                                {font[0].toString().split("", 11).join('')?.length == font[0].toString()?.length 
                                    ? font[0].toString().split("", 11).join('') 
                                    : font[0].toString().split("", 11).join('') + '...'
                                }
                            </div>
                        </div>
                    )})
                }
            </div>
        </div>
    </div>
  );
}

export default SelectFont;
