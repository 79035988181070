import React from 'react'

const HeadingAndPara = ({heading, paragraph}) => {
  return (
    <div
        style={{fontFamily: "Inter"}}
        className='text-base'
    >
        <h3 className='font-bold text-lg'>
            {heading}
        </h3>
        <p>
            {paragraph}
        </p>
    </div>
  )
}

export default HeadingAndPara