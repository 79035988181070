import React, { useState } from 'react'
import SidebarLink from './SidebarLink'
import { sidebarLinks } from '../../../data/dashboard-links'
// import{logout} from "../../../services/operations/authAPI"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {VscSignOut} from "react-icons/vsc"
import { logout } from '../../../services/operations/authAPI'
// import ConfirmationModal from '../../common/ConfirmationModal'

const Sidebar = () => {
    const {user, loading: profileLoading } = useSelector( (state) => state.profile);
    const {loading: authLoading} = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [confirmationModal, setConfirmationModal] = useState(null)
    if(profileLoading || authLoading) {
        return (
            <div className='grid h-[calc(100vh-3.5rem)] min-w-[220px] items-center border-r-[1px]'>
                <div className='spinner'></div>
            </div>
        )
    };

  return (
    <div className='bg-neonBlack md:block hidden'>
        <div className='flex min-w-[222px] flex-col border-r-[1px] 
            h-[calc(100vh-3.5rem)]  py-10  '>
            <div className='flex flex-col '>
                {
                    sidebarLinks.map( (link,index) => {
                        if(link.type && user?.accountType !== link.type) return null;
                        return (
                            <SidebarLink link={link} iconName={link.icon} key={index}/>
                        )
                    })
                }

            </div> 

            <div className='mx-auto mt-6 mb-6 h-[1px] w-10/12'></div>

            <div className='flex flex-col '>

                <button 
                    onClick={() => dispatch(logout(navigate))}
                    className='relative px-6 py-2 font-medium text-sm text-white'
                >
                    <div className='flex items-center gap-x-2'>
                        <VscSignOut  className="text=lg" />
                        <span>Logout</span>
                    </div>
                </button>
            </div>
        </div>
        {
            // confirmationModal && <ConfirmationModal modalData={confirmationModal}/>
        }
    </div>
  )
}

export default Sidebar