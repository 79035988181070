import {pricesList} from "../data/prices"
import {SPECIAL_FONTS, LOVE_NEON, SIZE, GRUPPO, POIRET_ONE} from '../utils/constants'

export function GetPrice(productDetails) {
    const {size, text, font, color, addon} = productDetails;
    const characters = text.toString().split("").filter((letter) => letter !== " ");
    
    let charLength = characters.length;
    let price = 0;
    if(charLength < 6) {
        price = pricesList[charLength]?.price;
    }
    else {
        price = 175*charLength;
    }

    if(size === SIZE.MEDIUM) {
        price += 0.5*price;
    }
    else if(size === SIZE.LARGE) {
        price = 2*price;
    }
    
    if(font === LOVE_NEON){
        price += 0.2*price; 
    }

    else if(font === GRUPPO){
        price += 0.2*price; 
    }
    else if(font === POIRET_ONE){
        price += 0.2*price; 
    }
    else if(SPECIAL_FONTS.includes(font)) {
        price += 0.2*price; 
    }

    if(color === 'RGB') {
        price = 2*price;
    }

    if(addon?.includes('Waterproof')) {
        price += characters.length < 11 ? 500 : 1000;
    }
    if(addon?.includes('Dimmer')) {
        price += 400;
    }
    if(addon?.includes('Mobile Controller')) {
        price += 800;
    }

    return price
}