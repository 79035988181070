import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import { HowToInstall } from '../../../data/howToInstallData';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function VerticalSwiper() {

  return (
    <div className='w-full h-full pb-10'>
      <div className='w-[300px] sm:w-[700px] md:w-[800px] lg:w-[1200px] mx-auto'>
        <div className='mt-8 w-full '>
          <Swiper
            direction={'vertical'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={2}
            spaceBetween={0}
            animating={true}
            loop={true}
            autoplay={{
              delay: 1000,
              disableOnInteraction: true,
          
            }}
            modules={[Autoplay]}
            style={{
              width:'50%',
              // height: "400px",
              marginLeft: '0px',
              transition: "all",
              transitionTimingFunction: 'ease-in-out',
              transitionDuration: "500ms"
            }}
          
            className="mySwiper2 h-96 transition-all ease-in-out duration-500"
          >
            {HowToInstall.map((element, index) => (
                <SwiperSlide key={index}
                  style={{height: '100%', width : '100%'}}
                  className='w-full h-10 border-b border-gray-700 py-4'>
                  <div className='w-full flex flex-col items-start px-3 gap-y-3 h-96'>
                    <p
                      style={{color: element.color}} 
                      className='p- rounded-full bg-gray-800 w-5 h-5 sm:w-8 sm:h-8 md:w-12 md:h-12 text-center flex justify-center items-center font-bold text-xs sm:text-lg lg:text-xl'
                    >
                      {index+1}
                    </p>
                    <h2 className='text-sm sm:text-lg md:text-xl font-semibold'>{element.heading}</h2>
                    <p className='text-wrap md:w-[450px] lg:w-[550px] md:text-base sm:text-sm text-[0.55rem] text-gray-400'>{element.description}</p>
                  </div>
                </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

