import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../assests/Logo.png"
import { AiOutlineShoppingCart } from "react-icons/ai";
import ProfileDropDown from './ProfileDropDown';
import { useEffect } from 'react';
import { getCart } from '../services/operations/productAPI';
import { BsList } from "react-icons/bs";
import { CUSTOME, PRODUCT_DROPDOWN } from '../data/dropDownData';
import DropDown from './DropDown';
import useOnClickOutside from '../hooks/useOnClickOutside';
import { FaChevronDown } from "react-icons/fa";
import { motion } from 'framer-motion';

const LIST =[
  {
    name: "Product",
    links: PRODUCT_DROPDOWN,
  },
  {
    name: "Custome",  
    links: CUSTOME
  },
]

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.profile);
  const {totalItems} = useSelector( (state) => state.cart);

  const [open, setOpen] = React.useState(false);
  const ref = React.useRef(null);
  useOnClickOutside(ref, () => setOpen(false));
  const handleOnClick = (url) => {
    navigate(url);
    setOpen(false);
  }


  useEffect(() => { 
    (async () => {
      if(!user?.token) return;
      const response = await getCart(user?.token, dispatch);
      if(response === null) {
        console.log("Error in getting cart");
      }
    })();
  }, []);

  return (
    <AppBar position="static" style={{backgroundColor:"#000000"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{display: "flex", justifyContent: "space-between"}}>
          <div className="flex items-center gap-x-1 cursor-pointer"
            onClick={() => navigate("/")}  
          >
            <img 
              src={Logo} 
              alt="" 
              className="w-8 sm:w-12 rounded-full" 
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              NEONGASMIC
            </Typography>
          </div>

          <div 
            className={`sm:flex md:hidden flex-grow flex`}
          >
            <button className='relative group' onClick={() => setOpen(true)}>
              <div 
                className={`relative flex items-center justify-center gap-x-2
                text-white text-center py-2 px-4 rounded-md cursor-pointer w-full`}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
              >
                <div className='text-lg font-semibold'>
                  <BsList />
                </div>
                <FaChevronDown />
                <motion.div 
                  className='absolute h-[2px] group-hover:scale-100 scale-0 w-[75%] bottom-0 bg-gradient-to-r from-red-500 via-blue-500 to-purple-500 transition-all duration-300'></motion.div>
              </div>
                
              {
                open && (
                  <div
                  onClick={(e) => e.stopPropagation()}
                  onMouseEnter={() => setOpen(true)}
                  onMouseLeave={() => setOpen(false)}
                  className={`xl:w-[800px] lg:w-[700px] w-[200px] absolute top-[100%] left-0 z-[1000] divide-y-[1px] divide-gray-900 overflow-hidden rounded-md border-[1px] border-gray-700 text-white
                    flex flex-col items-start gap-x-4 h-[250px] backdrop-blur-md px-2 py-4 shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg isolate`}
                  ref={ref}
                  >
                    {LIST.map((setting, index) => (
                      <div key={index} className='w-full'>
                        <div className={`flex flex-col items-start w-full h-full gap-x-1  text-sm `}>
                          <p className='z-10 font-semibold text-lg font-Poppins'>
                            {setting.name}
                          </p>
                          <div className='ml-12 flex flex-col gap-y-2 items-start'>
                            {setting.links.map((link, index) => (
                              <Link to={link?.link}
                                className='font-semibold text-white/80 hover:bg-black/20 hover:text-white '
                              >
                                {link.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              }
            </button>

          </div>
          <div
            style={{
              fontFamily: "monospace",
            }}
            className='font-bold text-center md:hidden flex flex-grow text-sm sm:text-lg tracking-widest'
          >
            NEONGASMIC
          </div>
          <div className={`md:flex hidden  flex-grow gap-x-4 justify-center items-center`}>
            <DropDown settings={PRODUCT_DROPDOWN} display={"Products"}/>
            <DropDown width="w-[400px]" settings={CUSTOME} display={"Custome"}/>
            <Link to="/contact-us"
              className='text-[#CFFF04] p-[1px] rounded-md bg-gradient-to-r from-red-500 via-blue-500 to-purple-500 hover:scale-95 transition-all duration-200'
            >
              <div className='flex items-center gap-x-3 justify-center bg-black h-full w-full text-xs sm:text-base px-2 py-1 sm:px-3 sm:py-2 rounded-md '>
                Contact Us
              </div>
            </Link>
          </div>
          
          {/* Sign-Up/Login/Profile Drop Down */}
          <div>
            {!user?.token ? (
              <div className='flex gap-x-3'>
                <button className='text-[#CFFF04] p-[1px] rounded-md bg-gradient-to-r from-red-500 via-blue-500 to-purple-500 hover:scale-95 transition-all duration-200' 
                  onClick={() => navigate("/signup")}
                >
                  <div className='flex items-center gap-x-3 justify-center bg-black h-full w-full text-xs sm:text-base px-2 py-1 sm:px-3 sm:py-2 rounded-md '>
                    Sign Up
                  </div>
                </button>

                <button className='text-[#CFFF04] p-[1px] rounded-md bg-gradient-to-r from-red-500 via-blue-500 to-purple-500 hover:scale-95 transition-all duration-200' 
                  onClick={() => navigate("/login")}>

                  <div className='flex items-center gap-x-3 justify-center bg-black h-full w-full text-xs sm:text-base px-2 py-1 sm:px-3 sm:py-2 rounded-md'>
                    Login
                  </div>
                </button>
              </div>
            ) : (
              <div className='flex items-center gap-x-6'>
                {/* Cart Icon */}
                {
                  user && (
                    <Link to={"/dashboard/cart"} className='relative'>
                      <AiOutlineShoppingCart className='text-2xl '/>
                      {
                        totalItems > 0 && (
                          <span className='absolute -top-2 -right-2 grid h-5 w-5 place-items-center -hidden rounded-full bg-neonBlue text-center text-xs font-bold text-yellow-100'>
                            {totalItems}
                          </span>
                        )
                      }
                    </Link>
                  )
                }
                <ProfileDropDown />
              </div>
            )}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
