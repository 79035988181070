import React from 'react';

const NeonButton = ({Text, handleClick}) => {
  return (
    <button
        onClick={handleClick}
        className='px-6 py-2 text-neonWhite uppercase tracking-[4px] sm:text-base text-sm
        border-2 border-neonViolet rounded hover:text-neonBlue 
        hover:font-semibold hover:border-neonWhite hover:bg-[#03e9f4] hover:shadow-[0_0_5px_#e3e9f4,0_0_25px_#03e9f4,0_0_50px_#e3e9f4,0_0_100px] 
        bg-neonBlue transition-all duration-300'
    >
        {Text}
    </button>
  );
}

export default NeonButton;
