import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCart, removeCart } from '../../../../services/operations/productAPI';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete } from "react-icons/md";
import {allColors} from '../../../../data/gradient-colors'
import CustomeBG from "../../../../assests/Customebg.jpg" 
import { setCurrentOrder } from '../../../../slices/paymentSlice';

const Cart = ({isSummary}) => {
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const {user} = useSelector((state) => state.profile);
  const {cart} = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCheckOut = () => {

    const orders = {totalAmount: 0, products: [], buyerId: user?._id};
    let totalAmount = 0;
    cart.forEach((item) => {
      orders.products.push(item);
      totalAmount = Number(item?.price) + Number(totalAmount);
    })
    orders.totalAmount = totalAmount;
    dispatch(setCurrentOrder(orders));
    navigate('/order');
  }

  useEffect(() => {
    let total = 0;
    cart.forEach((cart) => {
      total += cart.price;
    });
    setTotalPrice(total);
  }, [cart])
  return (
    <div className='relative flex w-full justify-between gap-x-6 text-neonWhite bg-black h-full min-h-[calc(100vh-8rem)]'>
      {
        loading 
        ? (
          <div className='w-full flex items-center justify-center h-[calc(100vh-7rem)] overflow-hidden'>
            <div className='loader'></div>
          </div>
        )
        : (
          <div className='flex lg:flex-row flex-col flex-grow'>
            <div className={`${isSummary === false ? "w-full" : "lg:w-[70%] w-full"} bg-black`}>
              <h2 className='text-3xl md:text-4xl font-semibold'>Shopping Cart</h2>

              <div className='w-full px-6 py-8'>
                <div className='w-full grid grid-cols-5 text-sm sm:text-lg font-semibold gap-x-5 text-center'>
                  <p>Product</p>
                  <p>Size</p>
                  <p>Quantity</p>
                  <p>Total Price</p>
                  <p>Delete</p>
                </div>
                {
                  cart?.length > 0
                  ? (
                    <>
                      {
                        cart.map((cart) => (
                          <div key={cart?.id} className='w-full grid grid-cols-5 gap-x-2  md:gap-x-5 place-items-center text-center py-2 h-[120px]'>
                            {cart.type === 'Text' 
                              ? (
                                <div className='relative w-full h-full flex items-center justify-center '>
                                  <img src={CustomeBG} alt="" className='ms:w-full object-fill absolute z-0 sm:h-full w-full h-[70%]' />
                                  <div
                                    className='w-[30%] z-10 absolute left-1 sm:top-2 sm:left-4'
                                  >
                                    <p 
                                      style={
                                      {fontFamily: cart?.font, textShadow: allColors[cart?.color]['text-shadow']}
                                      } 
                                      className={`${allColors[cart?.color]['text-shadow'] === 'homeText' && "homeText"} text-[0.55rem] sm:text-base md:text-xl text-white tracking-wide`}
                                    >
                                      {cart?.text}
                                    </p>
                                  </div>
                                </div>
                              ) 
                              : (
                                <div className='relative w-full h-full flex items-center justify-center '>
                                  <img src={cart.image} alt="" className='w-full object-fill absolute z-0 h-full' />
                                </div>
                              )
                            }

                            <p className='font-semibold text-xs sm:text-[1.18rem]'>
                              {cart?.type === 'Text' ? cart?.size: '-'}
                            </p>
                            <div className='font-semibold text-xs sm:text-[1.18rem] flex justify-between items-center gap-x-1 sm:gap-x-4 '>
                              <span className='p-2 cursor-pointer w-5 font-bold'>-</span>
                              <span>
                                {cart?.quantity}
                              </span>
                              <span className='p-2 cursor-pointer text-xl'>+</span>
                            </div>
                            <p className='font-semibold text-xs sm:text-[1.18rem]'>₹ {cart?.price}</p>
                            <div 
                              onClick={() => removeCart(cart?._id, user?.token, dispatch, user)}
                              className='font-semibold text-xs sm:text-[1.30rem] cursor-pointer p-2 rounded-full bg-neonBlue hover:scale-90 transition-all duration-300'>
                              <MdDelete />
                            </div>
                          </div>
                        ))
                      }
                    </>
                  )
                  : (
                    <div className='flex flex-col items-center justify-center gap-y-2 h-full'>
                      <p className='text-center text-lg py-3 '>
                        Empty Cart
                      </p>
                      <button 
                        onClick={() => navigate('/products?filter=events')}
                        className='border border-white hover:border-neonBlue px-3 py-2 rounded-lg transition duration-300'
                      >
                        Explore Products
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
            
            {
              isSummary === false ? (
                <div></div>
              ) 
              : (
                <div className='lg:fixed flex flex-col lg:w-[20%] w-full right-8 gap-y-3'>
                  <div className=' flex flex-col justify-between  overflow-hidden rounded-md border-[1px] border-gray-700 text-white
                    gap-x-4 backdrop-blur-md px-3 shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg isolate w-full lg:h-[40%]'
                  >
                    <h2 className='px-8 py-4 border-b border-b-gray-400'>
                      Order Summary
                    </h2>

                    <div className='py-4 px-8 flex flex-col gap-y-3'>
                      <div className='flex w-full justify-between'>
                        <p>Subtotal</p>
                        <p>₹ {totalPrice}</p>
                      </div>

                      <div className='flex w-full justify-between'>
                        <p>Shipping</p>
                        <p>Free</p>
                      </div>
                    </div>

                    <div className='px-8 py-4 border-t border-gray-400 flex justify-between'>
                      <p>Total</p>
                      <p>₹ {totalPrice}</p>
                    </div>
                  </div>

                  <button 
                    onClick={handleCheckOut}
                    className='bg-green-700 py-2'
                  >
                    Checkout
                  </button>
                </div>
              )
            }
          </div>
        )
      }
      
    </div>
  );
}

export default Cart;
