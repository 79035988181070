const BASE_URL = process.env.REACT_APP_BASE_URL

// AUTH ENDPOINTS
export const endpoints = {
  SENDOTP_API: BASE_URL + "/auth/otp",
  SIGNUP_API: BASE_URL + "/auth/signup",
  LOGIN_API: BASE_URL + "/auth/login",

  // **********************************************************
  // *********************REMAINING****************************
  // **********************************************************
  RESETPASSTOKEN_API: BASE_URL + "/auth/reset-password-token",
  RESETPASSWORD_API: BASE_URL + "/auth/reset-password",
}

export const productEndpoints = {
  ADD_CART: BASE_URL + '/product/addCart',
  REMOVE_CART: BASE_URL + '/product/removeCart',
  GET_CART: BASE_URL + '/product/getCart',
  CREATE_PRODUCT: BASE_URL + '/product/createProduct',
}

export const profileEndpoints = {
  GET_PROFILE: BASE_URL + '/profile/getProfile',
}

export const orderEndpoints = {
  CREATE_ORDER: BASE_URL + '/order/createOrder',
  UPDATE_ORDER: BASE_URL + '/order/updateOrder',
  GET_ORDERS: BASE_URL + '/order/getOrders',
  PLACE_ORDER: BASE_URL + '/order/placeOrder',
}