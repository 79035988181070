import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { MdNavigateNext } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import AnimatedInput from "../../AnimatedInput"
import { setCurrentOrder, setStep } from "../../../slices/paymentSlice"
import { createOrder, updateOrder } from "../../../services/operations/orderAPI"
import { createProduct } from "../../../services/operations/productAPI"

export default function AddressInfo() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm()

  const dispatch = useDispatch()
  const {user} = useSelector((state) => state.profile)
  const {editAddress, currentOrder} = useSelector((state) => state.payment)
  const [loading, setLoading] = useState(false)

  const isFormUpdated = () => {
    const currentValues = getValues()
    if (
      currentValues.fullName !== currentOrder.fullName ||
      currentValues.phoneNumber !== currentOrder.phoneNumber ||
      currentValues.address !== currentOrder.address ||
      currentValues.pincode !== currentOrder.pinCode ||
      currentValues.state !== currentOrder.state ||
      currentValues.city !== currentOrder.city
    ) {
      return true
    }
    return false
  }


  //   handle next button click
  const onSubmit = async (data) => {

    if (editAddress) {
      const currentValues = getValues()
      
      if (isFormUpdated()) {
        const currentValues = getValues()
        let orderData = {...currentOrder};

        if (orderData.fullName !== currentValues.fullName) {
          orderData.fullName = currentValues.fullName
        }
        if (orderData.phoneNumber !== currentValues.phoneNumber) {
          orderData.phoneNumber = currentValues.phoneNumber
        }
        if (orderData.address !== currentValues.address) {
          orderData.address = currentValues.address
        }
        if (orderData.pinCode !== currentValues.pincode) {
          orderData.pinCode = currentValues.pincode
        }
        if (orderData.state !== currentValues.state) {
          orderData.state = currentValues.state
        }
        if (orderData.city !== currentValues.city) {
          orderData.city = currentValues.city
        }
        setLoading(true)
        const result = await updateOrder(orderData, user?.token)
        setLoading(false)
        if (result) {
          dispatch(setStep(2));
          dispatch(setCurrentOrder(orderData));
          sessionStorage.setItem('currentOrder', JSON.stringify({...orderData}))
          sessionStorage.setItem('step', 2)
        }
      } else {
        toast.error("No changes made to the form")
      }
      return
    }

    let orderData = {}
    let formData = {}
    if(!currentOrder?.products) {
      let productId = null;
      let productDetails = {}
      if(currentOrder?.type === 'Text') {
        productDetails = {
          text: currentOrder?.text,
          type: currentOrder?.type,
          buyerId: user?._id,
          price: currentOrder?.totalAmount,
          font: currentOrder?.font,
          size: currentOrder?.size,
          color: currentOrder?.color,
          description: currentOrder?.description,
          addon: currentOrder?.addon,
        }
      }
      else {
        productDetails ={
          text: currentOrder?.text,
          type: currentOrder?.type,
          buyerId: user?._id,
          price: currentOrder?.totalAmount,
          discount: currentOrder?.discount,
          image: currentOrder?.image,
        }
      }

      try {
        const result = await createProduct(productDetails, user?.token, dispatch)
        if (result) {
          dispatch(setCurrentOrder({...currentOrder, productId:result._id}))
          productId = result._id
        }
      } catch (error) {
        toast.error("Server Issue Please try later");
        return;
      }
  
      formData = {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        address: `${data.address}`,
        pinCode: data.pincode,
        state: data.state,
        city: data.city,
        paymentStatus: "Pending",
        orderStatus: 'Incomplete',
        paymentMethod: 'Cash on Delivery',
      }
      orderData = {...currentOrder, ...formData, productId: [productId]}
    }
    else {
      
      let productsId = [];
      currentOrder.products.forEach((product) => {
        productsId.push(product._id)
      })

      formData = {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        address: `${data.address}`,
        pinCode: data.pincode,
        state: data.state,
        city: data.city,
        paymentStatus: "Pending",
        orderStatus: 'Incomplete',
        paymentMethod: 'Cash on Delivery',
        productId: productsId,
        totalAmount: currentOrder?.totalAmount,
        buyerId: currentOrder?.buyerId,
      }
      orderData = {...formData}
    }

    // return 
    setLoading(true)
    const result = await createOrder(orderData, user.token, dispatch, user)
    if (result) {
      dispatch(setStep(2))
      dispatch(setCurrentOrder({...currentOrder, ...orderData, orderId: result._id}))
      sessionStorage.setItem('currentOrder', JSON.stringify({...orderData, ...currentOrder, orderId: result._id}))
      sessionStorage.setItem('step', 2)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(editAddress) {
      setValue('fullName', currentOrder?.fullName)
      setValue('phoneNumber', currentOrder?.phoneNumber)
      setValue('address', currentOrder?.address)
      setValue('pincode', currentOrder?.pinCode)
      setValue('state', currentOrder?.state)
      setValue('city', currentOrder?.city)
    }
  }, [editAddress])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col space-y-8 rounded-md bg-black p-6"
    >
      {/* Full Name */}
      <AnimatedInput type='text' id='fullName' label='Full Name' register={register} errors={errors}/>
      {/* Phone number */}
      <AnimatedInput type='text' id='phoneNumber' label='Phone Number' register={register} errors={errors}/>
      {/* Address */}
      <div className="grid grid-cols-2 gap-x-4 gap-y-4">
        <AnimatedInput type="text" id='pincode' label="Pincode" register={register} errors={errors}/>
        <AnimatedInput type="text" id='state' label="State" register={register} errors={errors}/>
        <AnimatedInput type="text" id='city' label="City" register={register} errors={errors}/>        
      </div>
      
      <AnimatedInput type='text' id='address' label='Address' register={register} errors={errors}/>

      {/* Next Button */}
      <div className="flex gap-x-2 w-full">
        {editAddress && (
          <button
            onClick={() => dispatch(setStep(2))}
            disabled={loading}
            className={`flex cursor-pointer items-center gap-x-2 rounded-md border border-white hover:border-neonBlue bg-black py-[8px] px-[20px] font-semibold text-white`}
          >
            Continue Wihout Saving
          </button>
        )}
        <button
          disabled={loading}
          text={!editAddress ? "Next" : "Save Changes"}
          className="w-full justify-center text-white flex items-center gap-x-1  bg-[#e85931] py-[8px] px-[20px] font-semibold"
        >
          <span>
            {!editAddress ? "Save Adress" : "Save Changes"}
          </span> 
          <MdNavigateNext />
        </button>
      </div>
    </form>
  )
}