export const WhyNeongasmic = [
    {
        id: 1,
        title: "Quality & Durability",
        description: "2-year warranty.",
        icon: <svg width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4608 1.50732C11.2607 0.539132 12.7446 0.539132 13.5445 1.50732L14.7445 2.95979C15.1677 3.472 15.8176 3.74061 16.4789 3.67664L18.3571 3.49495C19.6102 3.37372 20.6624 4.42666 20.5402 5.67974L20.3597 7.53129C20.2949 8.1958 20.566 8.84883 21.0823 9.2721L22.5232 10.4533C23.4993 11.2534 23.4993 12.7466 22.5232 13.5467L21.0823 14.7279C20.566 15.1512 20.2949 15.8042 20.3597 16.4687L20.5402 18.3203C20.6624 19.5733 19.6102 20.6263 18.3571 20.505L16.4789 20.3234C15.8176 20.2594 15.1677 20.528 14.7445 21.0402L13.5445 22.4927C12.7446 23.4609 11.2607 23.4609 10.4608 22.4927L9.26079 21.0402C8.83761 20.528 8.18769 20.2594 7.52637 20.3234L5.64824 20.505C4.39507 20.6263 3.34293 19.5733 3.4651 18.3203L3.64562 16.4687C3.71041 15.8042 3.43933 15.1512 2.92298 14.7279L1.48208 13.5467C0.505968 12.7466 0.505968 11.2534 1.48208 10.4533L2.92298 9.2721C3.43933 8.84883 3.71041 8.1958 3.64562 7.53129L3.4651 5.67974C3.34293 4.42666 4.39507 3.37372 5.64824 3.49495L7.52637 3.67664C8.18769 3.74061 8.83761 3.472 9.26079 2.95979L10.4608 1.50732Z" stroke="#ffffff" strokeWidth="1.5"/>
                <path d="M8.12549 12.7725L10.4136 15.0516C10.8437 15.48 11.5531 15.4296 11.9182 14.9446L15.8801 9.68274" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>,
        color: '#FF3131'
    },
    {
        id: 2,
        title: "Free + Safe Shipping",
        description: "We offer free and secure shipping across India.",
        icon: <svg fill="#ffffff" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                viewBox="0 0 512 512" xmlSpace="preserve">
                <g>
                    <g>
                        <path d="M476.158,286.938l-13.259-53.035c3.625-0.77,6.345-3.986,6.345-7.839v-8.551c0-18.566-15.105-33.67-33.67-33.67h-60.392
                            v-17.637c0-9.136-7.432-16.568-16.568-16.568H246.32l68.24-27.296c4.111-1.644,6.11-6.31,4.466-10.421
                            c-1.644-4.11-6.307-6.111-10.421-4.466l-55.874,22.349c17.026-10.924,33.871-22.947,40.284-31.355
                            c12.485-16.369,9.323-39.843-7.046-52.328c-16.369-12.486-39.843-9.323-52.328,7.046c-9.122,11.962-21.158,45.573-28.948,69.258
                            c-7.79-23.683-19.826-57.296-28.948-69.258c-12.484-16.369-35.959-19.53-52.328-7.046c-16.369,12.484-19.53,35.958-7.046,52.328
                            c6.413,8.409,23.257,20.431,40.284,31.355l-55.874-22.349c-4.113-1.647-8.777,0.355-10.421,4.466
                            c-1.645,4.111,0.355,8.777,4.466,10.421l68.24,27.296H50.772c-9.136,0-16.568,7.432-16.568,16.568v145.37
                            c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-145.37c0-0.295,0.239-0.534,0.534-0.534h307.841
                            c0.295,0,0.534,0.239,0.534,0.534v145.372c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-9.088h94.566
                            c0.009,0,0.016,0.002,0.025,0.002s0.017-0.001,0.026-0.001c11.636,0.009,21.516,7.647,24.908,18.171h-24.928
                            c-4.427,0-8.017,3.589-8.017,8.017v17.102c0,13.851,11.268,25.119,25.119,25.119h9.086v35.273h-20.962
                            c-6.886-19.882-25.787-34.205-47.982-34.205s-41.097,14.322-47.982,34.205h-3.86V345.78c0-4.427-3.589-8.017-8.017-8.017
                            c-4.427,0-8.017,3.589-8.017,8.017v60.392H192.817c-6.886-19.882-25.787-34.205-47.982-34.205s-41.097,14.322-47.982,34.205
                            H50.772c-0.295,0-0.534-0.239-0.534-0.534v-17.637h34.739c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017H8.017
                            c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h26.188v17.637c0,9.136,7.432,16.568,16.568,16.568h43.304
                            c-0.002,0.178-0.014,0.355-0.014,0.534c0,27.995,22.777,50.772,50.772,50.772s50.772-22.777,50.772-50.772
                            c0-0.18-0.012-0.356-0.014-0.534h180.67c-0.002,0.178-0.014,0.355-0.014,0.534c0,27.995,22.777,50.772,50.772,50.772
                            c27.995,0,50.772-22.777,50.772-50.772c0-0.18-0.012-0.356-0.014-0.534h26.203c4.427,0,8.017-3.589,8.017-8.017v-85.511
                            C512,307.564,496.423,290.022,476.158,286.938z M172.9,121.059c-31.623-19.651-41.003-28.692-43.78-32.334
                            c-7.123-9.339-5.319-22.732,4.021-29.855c3.85-2.936,8.388-4.355,12.893-4.355c6.422,0,12.776,2.886,16.963,8.376
                            c7.755,10.168,19.9,44.391,27.918,69.052C185.504,128.757,179.284,125.028,172.9,121.059z M218.473,131.942
                            c8.018-24.66,20.163-58.882,27.917-69.052c7.123-9.339,20.516-11.142,29.855-4.02c9.34,7.123,11.143,20.516,4.021,29.855
                            c-2.777,3.641-12.157,12.683-43.778,32.333C230.105,125.026,223.885,128.756,218.473,131.942z M375.182,199.875h60.392
                            c9.725,0,17.637,7.912,17.637,17.637v0.534h-78.029V199.875z M375.182,286.456V234.08h71.235l13.094,52.376H375.182z
                                M144.835,457.479c-19.155,0-34.739-15.584-34.739-34.739s15.584-34.739,34.739-34.739c19.155,0,34.739,15.584,34.739,34.739
                            S163.99,457.479,144.835,457.479z M427.023,457.479c-19.155,0-34.739-15.584-34.739-34.739s15.584-34.739,34.739-34.739
                            c19.155,0,34.739,15.584,34.739,34.739S446.178,457.479,427.023,457.479z M495.967,354.865h-9.086
                            c-5.01,0-9.086-4.076-9.086-9.086v-9.086h18.171V354.865z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M144.835,406.172c-9.136,0-16.568,7.432-16.568,16.568s7.432,16.568,16.568,16.568c9.136,0,16.568-7.432,16.568-16.568
                            S153.971,406.172,144.835,406.172z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M427.023,406.172c-9.136,0-16.568,7.432-16.568,16.568s7.432,16.568,16.568,16.568c9.136,0,16.568-7.432,16.568-16.568
                            S436.159,406.172,427.023,406.172z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M332.96,371.967H213.244c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017H332.96
                            c4.427,0,8.017-3.589,8.017-8.017S337.388,371.967,332.96,371.967z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M127.733,337.763H25.119c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h102.614
                            c4.427,0,8.017-3.589,8.017-8.017S132.16,337.763,127.733,337.763z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M127.733,218.046H93.528c-4.427,0-8.017,3.589-8.017,8.017v68.409c0,4.427,3.589,8.017,8.017,8.017
                            s8.017-3.589,8.017-8.017v-26.188h17.637c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017h-17.637V234.08h26.188
                            c4.427,0,8.017-3.589,8.017-8.017C135.749,221.636,132.16,218.046,127.733,218.046z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M190.822,272.043c8.023-5.255,13.337-14.317,13.337-24.602c0-16.209-13.186-29.395-29.395-29.395h-21.378
                            c-4.427,0-8.017,3.589-8.017,8.017v68.409c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017v-17.637h13.346l14.722,22.083
                            c1.545,2.317,4.086,3.571,6.677,3.571c1.529,0,3.073-0.436,4.439-1.348c3.685-2.455,4.68-7.433,2.223-11.116L190.822,272.043z
                                M174.764,260.802h-13.361V234.08h13.361c7.368,0,13.361,5.993,13.361,13.361C188.125,254.809,182.132,260.802,174.764,260.802z"
                            />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M256,286.456h-26.188v-18.198c0.177,0.012,0.354,0.027,0.534,0.027h17.102c4.427,0,8.017-3.589,8.017-8.017
                            s-3.589-8.017-8.017-8.017h-17.102c-0.181,0-0.357,0.015-0.534,0.027V234.08H256c4.427,0,8.017-3.589,8.017-8.017
                            c0-4.427-3.589-8.017-8.017-8.017h-34.205c-4.427,0-8.017,3.589-8.017,8.017v68.409c0,4.427,3.589,8.017,8.017,8.017H256
                            c4.427,0,8.017-3.589,8.017-8.017S260.427,286.456,256,286.456z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M315.858,286.456H289.67v-18.171h9.086c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017h-9.086V234.08h26.188
                            c4.427,0,8.017-3.589,8.017-8.017c0-4.427-3.589-8.017-8.017-8.017h-34.205c-4.427,0-8.017,3.589-8.017,8.017v68.409
                            c0,4.427,3.589,8.017,8.017,8.017h34.205c4.427,0,8.017-3.589,8.017-8.017S320.285,286.456,315.858,286.456z"/>
                    </g>
                </g>
            </svg>,
        color: '#ff10f0',
    },
    {
        id: 3,
        title: "Water Resistance",
        description: "Suitable for both indoor and outdoor use.",
        icon: <svg fill="#ffffff" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                viewBox="0 0 362.666 362.666" xmlSpace="preserve">
                <g>
                    <path d="M339.106,205.998c-1.586-39.295-18.655-75.978-48.109-103.354c-29.585-27.498-68.534-42.642-109.674-42.642
                        s-80.09,15.144-109.675,42.642c-29.619,27.531-46.715,64.473-48.135,104.02c-0.192,5.339,3.847,9.886,9.171,10.325
                        c5.32,0.436,10.054-3.384,10.739-8.682c2.2-16.992,19.023-29.805,39.132-29.805c21.715,0,39.383,15.028,39.383,33.5
                        c0,5.523,4.478,10,10,10c5.523,0,10-4.477,10-10c0-15.534,12.496-28.629,29.385-32.403v140.268
                        c0,12.571-10.229,22.799-22.801,22.799c-12.57,0-22.797-10.228-22.797-22.799c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10
                        c0,23.599,19.198,42.799,42.797,42.799c23.601,0,42.801-19.2,42.801-42.799V179.6c16.889,3.774,29.384,16.869,29.384,32.402
                        c0,5.523,4.477,10,10,10s10-4.477,10-10c0-18.472,17.668-33.5,39.384-33.5c20.109,0,36.932,12.813,39.131,29.805
                        c0.65,5.021,4.932,8.716,9.908,8.716c0.058,0,0.115,0,0.173-0.001c5.456-0.077,9.856-4.524,9.856-9.999
                        C339.159,206.677,339.142,206.335,339.106,205.998z"/>
                    <path d="M44.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.562-14.056c-4.312-3.45-10.605-2.75-14.056,1.561l-12,15C39.064,23.065,39.764,29.358,44.075,32.809z"
                        />
                    <path d="M94.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.562-14.056c-4.313-3.45-10.606-2.75-14.056,1.561l-12,15C89.064,23.065,89.764,29.358,94.075,32.809z"
                        />
                    <path d="M144.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.562-14.056c-4.313-3.45-10.606-2.75-14.056,1.561l-12,15C139.064,23.065,139.764,29.358,144.075,32.809
                        z"/>
                    <path d="M194.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.562-14.056c-4.313-3.45-10.606-2.75-14.056,1.561l-12,15C189.064,23.065,189.764,29.358,194.075,32.809
                        z"/>
                    <path d="M244.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.561-14.056c-4.314-3.45-10.605-2.75-14.056,1.561l-12,15C239.064,23.065,239.764,29.358,244.075,32.809
                        z"/>
                    <path d="M294.075,32.809c1.845,1.475,4.051,2.192,6.241,2.192c2.934,0,5.841-1.285,7.815-3.753l12-15
                        c3.45-4.313,2.751-10.605-1.561-14.056c-4.312-3.45-10.605-2.75-14.056,1.561l-12,15C289.064,23.065,289.764,29.358,294.075,32.809
                        z"/>
                </g>
            </svg>,
        color: '#CFFF04',
    },
    {
        id: 4,
        title: "Lowest Prices Guaranteed",
        description: "Best value for your money without compromising on quality.",
        icon: <svg fill="#ffffff" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                viewBox="0 0 442.906 442.906" xmlSpace="preserve">
                <g>
                    <path d="M442.485,250.247c-1.053-3.01-3.892-5.025-7.08-5.025h-71.493V19.741c0-4.142-3.357-7.5-7.5-7.5H86.495
                        c-4.142,0-7.5,3.358-7.5,7.5v225.481H7.5c-3.188,0-6.027,2.015-7.08,5.025c-1.052,3.009-0.087,6.355,2.406,8.341L216.78,429.03
                        c1.367,1.089,3.02,1.634,4.673,1.634s3.306-0.544,4.673-1.634l213.952-170.442C442.572,256.602,443.537,253.257,442.485,250.247z
                        M221.453,413.575L28.951,260.222h57.544c4.142,0,7.5-3.358,7.5-7.5V27.241h254.917v225.481c0,4.142,3.357,7.5,7.5,7.5h57.542
                        L221.453,413.575z"/>
                    <path d="M155.968,171.393h27.329c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-19.829v-47.157c0-4.142-3.358-7.5-7.5-7.5
                        s-7.5,3.358-7.5,7.5v54.657C148.468,168.035,151.826,171.393,155.968,171.393z"/>
                    <path d="M289.104,171.073c3.167-0.956,5.334-3.873,5.334-7.181v-54.657c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v29.791
                        l-4.397-6.626c-1.39-2.094-3.735-3.353-6.249-3.353s-4.859,1.259-6.249,3.353l-4.397,6.626v-29.791c0-4.142-3.357-7.5-7.5-7.5
                        s-7.5,3.358-7.5,7.5v54.657c0,3.308,2.167,6.225,5.334,7.181c3.17,0.956,6.588-0.278,8.415-3.034l11.897-17.929l11.897,17.929
                        c1.415,2.133,3.783,3.354,6.25,3.354C287.659,171.393,288.388,171.289,289.104,171.073z"/>
                    <path d="M213.904,101.736c-11.67,0-21.165,9.494-21.165,21.164v27.329c0,11.67,9.494,21.165,21.165,21.165
                        s21.165-9.495,21.165-21.165V122.9C235.068,111.23,225.574,101.736,213.904,101.736z M220.068,150.228
                        c0,3.399-2.766,6.165-6.165,6.165s-6.165-2.766-6.165-6.165V122.9c0-3.399,2.766-6.164,6.165-6.164s6.165,2.765,6.165,6.164
                        V150.228z"/>
                    <path d="M321.41,219.883c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-27.329c-4.143,0-7.5,3.358-7.5,7.5v54.657
                        c0,4.142,3.357,7.5,7.5,7.5h27.329c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-19.829v-12.329h7.916c4.143,0,7.5-3.358,7.5-7.5
                        s-3.357-7.5-7.5-7.5h-7.916v-12.329H321.41z"/>
                    <path d="M223.491,204.883c-4.143,0-7.5,3.358-7.5,7.5v54.657c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-54.657
                        C230.991,208.241,227.634,204.883,223.491,204.883z"/>
                    <path d="M272.183,219.883c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5c-19.204,0-34.828,15.624-34.828,34.829
                        s15.624,34.829,34.828,34.829c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5c-10.934,0-19.828-8.895-19.828-19.829
                        S261.25,219.883,272.183,219.883z"/>
                    <path d="M135.16,204.883h-13.664c-4.142,0-7.5,3.358-7.5,7.5v54.657c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-19.829h6.164
                        c11.67,0,21.165-9.494,21.165-21.164S146.83,204.883,135.16,204.883z M135.16,232.212h-6.164v-12.329h6.164
                        c3.399,0,6.165,2.766,6.165,6.165S138.559,232.212,135.16,232.212z"/>
                    <path d="M187.488,246.766c9.6-2.004,16.834-10.533,16.834-20.719c0-11.67-9.494-21.165-21.165-21.165h-13.664
                        c-4.142,0-7.5,3.358-7.5,7.5v27.269c0,0.039,0,0.078,0,0.117v27.272c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-9.556
                        l15.331,14.929c2.967,2.89,7.717,2.828,10.605-0.141c2.89-2.967,2.827-7.716-0.141-10.605L187.488,246.766z M176.993,232.212
                        v-12.329h6.164c3.399,0,6.165,2.766,6.165,6.165s-2.766,6.164-6.165,6.164H176.993z"/>
                </g>
            </svg>,
        color: '#4D4DFF',
    },
    {
        id: 5,
        title: "Free Adapter Included",
        description: "Start using your new neon sign right away.",
        icon: <svg fill="#ffffff" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                viewBox="0 0 512 512" xmlSpace="preserve">
                <g>
                    <g>
                        <g>
                            <path d="M176.576,157.952l-7.552-7.552l15.083-15.083l22.635,22.635c4.16,4.16,10.923,4.16,15.083,0
                                c4.16-4.16,4.16-10.923,0-15.083l-30.165-30.165c-4.16-4.16-10.923-4.16-15.083,0l-60.331,60.331
                                c-4.16,4.16-4.16,10.923,0,15.083c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.024,7.509-3.115l22.635-22.635
                                l7.552,7.552c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.115C180.736,168.875,180.736,162.112,176.576,157.952z"/>
                            <path d="M297.28,369.152l30.165,30.165c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.045,7.552-3.093
                                c4.16-4.16,4.16-10.923,0-15.083l-22.635-22.635l15.083-15.083l7.531,7.531c2.091,2.069,4.821,3.115,7.552,3.115
                                c2.731,0,5.461-1.045,7.531-3.115c4.16-4.16,4.16-10.923,0-15.083l-7.531-7.531l15.083-15.104l22.635,22.635
                                c4.16,4.16,10.923,4.16,15.083,0c4.16-4.16,4.16-10.923,0-15.083l-30.165-30.187c-2.005-1.984-4.715-3.115-7.552-3.115
                                s-5.547,1.109-7.552,3.115l-60.331,60.352C293.12,358.229,293.12,364.992,297.28,369.152z"/>
                            <path d="M236.949,173.035l-29.995,29.973c-0.149,0.149-0.277,0.277-0.427,0.427l-29.952,29.952c-4.16,4.16-4.16,10.923,0,15.083
                                c4.16,4.16,10.923,4.16,15.083,0l11.968-11.968v34.581c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.645-10.667v-34.965
                                c5.568,4.309,12.352,6.635,19.52,6.635c8.555,0,16.597-3.328,22.635-9.365c12.48-12.48,12.48-32.789,0-45.269l-15.083-15.083
                                C247.851,168.875,241.109,168.875,236.949,173.035z M252.053,218.304c-2.027,2.005-4.693,3.115-7.552,3.115
                                c-2.859,0-5.525-1.109-7.552-3.136l-7.552-7.531l15.104-15.104l7.552,7.552C256.213,207.381,256.213,214.144,252.053,218.304z"/>
                            <path d="M236.949,308.8l30.165,30.187c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.045,7.552-3.136
                                c4.16-4.16,4.16-10.923,0-15.083l-22.635-22.635l15.061-15.061l7.552,7.552c2.091,2.069,4.821,3.115,7.552,3.115
                                c2.731,0,5.461-1.045,7.531-3.115c4.16-4.16,4.16-10.923,0-15.083l-7.552-7.531L304.832,256l22.613,22.635
                                c4.16,4.16,10.923,4.16,15.083,0c4.16-4.16,4.16-10.923,0-15.083l-30.165-30.165c-4.16-4.16-10.923-4.16-15.083,0l-60.331,60.331
                                C232.768,297.877,232.768,304.64,236.949,308.8z"/>
                            <path d="M432.939,118.549c-6.699-6.4-13.461-12.821-20.053-19.413c-6.613-6.613-13.077-13.397-19.499-20.139
                                C356.352,40.149,318.101,0,256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-255.979
                                C512,193.813,471.787,155.541,432.939,118.549z M377.941,93.696c6.549,6.869,13.141,13.781,19.861,20.501
                                c6.699,6.72,13.611,13.269,20.437,19.776c20.885,19.883,40.853,39.253,54.613,61.653c-6.293-1.984-12.885-3.477-19.648-4.672
                                c-0.32-0.064-0.597-0.128-0.917-0.149c-2.581-0.448-5.184-0.896-7.765-1.323c-26.816-4.352-57.259-9.301-84.949-37.013
                                c-27.691-27.712-32.64-58.133-36.992-84.949c-1.579-9.664-3.307-19.307-6.144-28.288
                                C338.752,52.971,358.101,72.896,377.941,93.696z M301.504,70.912c4.629,28.523,10.411,64.021,42.987,96.619
                                c32.576,32.597,68.075,38.357,96.619,42.987c0.427,0.064,0.768,0.128,1.195,0.213C445.867,225.472,448,240.619,448,256
                                c0,105.856-86.123,192-192,192S64,361.856,64,256S150.123,64,256,64c15.339,0,30.485,2.133,45.291,5.717
                                C301.355,70.144,301.419,70.485,301.504,70.912z M256,490.667c-129.387,0-234.667-105.28-234.667-234.667
                                S126.613,21.333,256,21.333c5.077,0,9.813,0.597,14.549,1.216c15.851,2.752,22.336,10.731,26.197,24.512
                                c-13.397-2.603-26.987-4.395-40.747-4.395C138.389,42.667,42.667,138.368,42.667,256S138.368,469.333,256,469.333
                                S469.333,373.632,469.333,256c0-13.781-1.771-27.349-4.373-40.725c13.76,3.861,21.76,10.347,24.491,26.197
                                c0.619,4.715,1.216,9.451,1.216,14.528C490.667,385.387,385.387,490.667,256,490.667z"/>
                        </g>
                    </g>
                </g>
            </svg>,
        color: '#FF5F1F',
    },
    {
        id: 6,
        title: "Customer Satisfaction",
        description: "We are dedicated to providing exceptional customer service.",
        icon:<svg fill="#ffffff" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                viewBox="0 0 512 512" xmlSpace="preserve">
                <g>
                    <g>
                        <path d="M408.274,56.527h-0.087c-4.503,0-8.109,3.65-8.109,8.153c0,4.503,3.694,8.153,8.196,8.153s8.153-3.65,8.153-8.153
                            C416.427,60.177,412.776,56.527,408.274,56.527z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M290.912,389.623l-77.599-32.332c-3.455-1.439-5.686-4.787-5.686-8.53v-16.46l14.509-8.06
                            c12.431-6.907,20.621-19.485,21.908-33.647l0.032-0.351h7.576c14.086,0,25.546-11.46,25.546-25.546v-15.703
                            c2.284-5.523,8.696-23.2,8.696-47.587c0-33.695-13.199-65.487-37.165-89.521c-9.17-9.195-19.542-16.838-30.794-22.799
                            c-0.392-27.598-28.021-49.953-61.942-49.953s-61.55,22.355-61.943,49.953c-11.252,5.96-21.624,13.603-30.794,22.799
                            c-23.966,24.034-37.165,55.825-37.165,89.521c0,24.387,6.412,42.063,8.696,47.587v15.703c0,14.086,11.46,25.546,25.546,25.546
                            h7.576l0.031,0.351c1.287,14.162,9.478,26.741,21.909,33.647l14.509,8.06v16.46c0,3.742-2.232,7.09-5.686,8.53l-77.599,32.332
                            C8.271,394.957,0,407.364,0,421.231v52.178c0,14.086,11.46,25.546,25.546,25.546h260.892c14.086,0,25.546-11.46,25.546-25.546
                            v-52.178C311.983,407.364,303.712,394.957,290.912,389.623z M260.892,264.696c0,5.095-4.145,9.24-9.24,9.24h-6.093l3.261-35.873
                            h2.832c5.095,0,9.24,4.145,9.24,9.24V264.696z M155.992,55.44c21.355,0,39.325,11.054,44.29,25.933
                            c-13.966-4.898-28.895-7.453-44.29-7.453s-30.323,2.555-44.29,7.453C116.666,66.494,134.636,55.44,155.992,55.44z M60.331,273.936
                            c-5.095,0-9.24-4.145-9.24-9.24v-17.393c0-5.095,4.145-9.24,9.24-9.24h2.832l3.261,35.873H60.331z M97.768,309.987
                            c-7.711-4.284-12.792-12.086-13.59-20.87l-6.207-68.273c10.664-0.067,29.363-0.975,51.23-5.506
                            c44.72-9.265,81.204-29.479,105.507-58.455c2.894-3.449,2.443-8.592-1.008-11.485c-3.45-2.894-8.592-2.443-11.486,1.008
                            c-53.04,63.24-151.327,58.059-152.671,57.977v0.009c-0.412-0.025-0.829-0.03-1.253,0.009c-4.484,0.408-7.789,4.373-7.381,8.857
                            l0.772,8.501h-1.35c-5.708,0-10.985,1.883-15.243,5.058c-1.478-6.864-2.694-15.508-2.694-25.41
                            c0-29.348,11.509-57.05,32.406-78.007c21.333-21.393,50.167-33.175,81.191-33.175s59.857,11.781,81.191,33.175
                            c20.897,20.955,32.406,48.659,32.406,78.007c0,9.901-1.215,18.547-2.694,25.411c-4.258-3.176-9.534-5.059-15.243-5.059h-1.35
                            l0.773-8.502c0.22-2.409-0.644-4.792-2.355-6.503l-17.393-17.393c-3.184-3.184-8.346-3.184-11.529,0
                            c-3.184,3.184-3.184,8.345,0,11.529l14.693,14.693l-5.805,63.855c-6.658,5.889-27.775,20.588-72.694,20.588
                            c-4.503,0-8.153,3.65-8.153,8.153s3.65,8.153,8.153,8.153c31.214,0,52.574-6.536,66.428-13.435
                            c-2.189,2.859-4.96,5.289-8.203,7.091l-45.291,25.162c-8.099,4.499-17.769,4.499-25.867,0L97.768,309.987z M104.943,372.341
                            c9.55-3.979,15.72-13.235,15.72-23.58v-7.402l14.476,8.043c6.529,3.626,13.69,5.441,20.853,5.441s14.324-1.813,20.853-5.441
                            l14.476-8.043v7.402c0,10.347,6.17,19.602,15.72,23.58l8.552,3.563c-15.294,17.756-30.075,26.578-40.086,30.892
                            c-11.073,4.771-18.74,5.163-19.516,5.192c-0.776-0.028-8.443-0.421-19.516-5.192c-10.011-4.313-24.791-13.136-40.086-30.892
                            L104.943,372.341z M52.178,482.65H25.546c-5.095,0-9.24-4.145-9.24-9.24v-52.178c0-2.248,0.417-4.422,1.189-6.436l27.952,22.363
                            c4.279,3.422,6.731,8.527,6.731,14.006V482.65z M243.499,451.163v31.486H68.484v-31.486c0-10.46-4.684-20.205-12.851-26.739
                            l-25.921-20.737l50.923-21.218c26.865,33.228,54.02,42.37,67.203,44.879v27.582c0,4.503,3.65,8.153,8.153,8.153
                            s8.153-3.65,8.153-8.153v-27.582c13.184-2.509,40.338-11.651,67.203-44.879l50.923,21.218l-25.921,20.737
                            C248.183,430.958,243.499,440.704,243.499,451.163z M295.677,473.41c0,5.095-4.145,9.24-9.24,9.24h-26.633v-31.486
                            c0-5.479,2.453-10.583,6.731-14.007l27.952-22.362c0.772,2.014,1.189,4.188,1.189,6.436V473.41z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M145.944,243.765c-3.896-8.085-13.292-13.311-23.939-13.311s-20.043,5.225-23.939,13.311
                            c-1.954,4.056-0.25,8.929,3.807,10.884c4.056,1.955,8.929,0.249,10.884-3.807c0.953-1.978,4.519-4.082,9.249-4.082
                            c4.73,0,8.295,2.103,9.249,4.082c1.404,2.917,4.318,4.617,7.351,4.617c1.187,0,2.393-0.261,3.533-0.81
                            C146.194,252.694,147.898,247.822,145.944,243.765z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M213.917,243.765c-3.895-8.085-13.291-13.311-23.939-13.311s-20.043,5.225-23.939,13.311
                            c-1.955,4.057-0.25,8.929,3.807,10.884c4.056,1.955,8.929,0.249,10.884-3.807c0.953-1.978,4.519-4.082,9.249-4.082
                            s8.295,2.103,9.249,4.082c1.404,2.917,4.318,4.617,7.351,4.617c1.187,0,2.393-0.261,3.533-0.81
                            C214.167,252.694,215.872,247.822,213.917,243.765z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M408.187,13.045c-57.243,0-103.813,46.57-103.813,103.813c0,30.035,12.718,57.98,35.132,77.723
                            c-11.247,18.412-23.453,29.023-23.578,29.131c-2.686,2.294-3.589,6.057-2.237,9.32c1.27,3.063,4.255,5.032,7.529,5.032
                            c0.214,0,0.43-0.009,0.647-0.025c1.341-0.107,31.956-2.721,59.114-20.983c8.851,2.4,17.984,3.616,27.207,3.616
                            c57.243,0,103.813-46.57,103.813-103.813C512,59.615,465.43,13.045,408.187,13.045z M408.187,204.365
                            c-8.93,0-17.757-1.349-26.236-4.011c-2.468-0.774-5.158-0.333-7.248,1.194c-10.038,7.327-21.056,12.056-30.517,15.084
                            c4.232-5.582,8.752-12.292,12.989-20.038c1.928-3.525,1.001-7.929-2.185-10.378c-21.805-16.751-34.31-42.032-34.31-69.358
                            c0-48.252,39.255-87.507,87.507-87.507c48.252,0,87.507,39.255,87.507,87.507C495.694,165.11,456.439,204.365,408.187,204.365z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M416.883,160.883h-0.544v-53.265h0.544c4.503,0,8.153-3.65,8.153-8.153s-3.65-8.153-8.153-8.153H399.49
                            c-4.503,0-8.153,3.65-8.153,8.153s3.65,8.153,8.153,8.153h0.544v53.265h-0.544c-4.503,0-8.153,3.65-8.153,8.153
                            c0,4.503,3.65,8.153,8.153,8.153h17.393c4.503,0,8.153-3.65,8.153-8.153C425.036,164.534,421.386,160.883,416.883,160.883z"/>
                    </g>
                </g>
            </svg>,
        color: '#00FFFF',
    },
];
