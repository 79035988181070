import React, { useEffect } from 'react';
import HeroSection from '../components/core/Product/HeroSection';
import Navbar from '../components/Navbar';
import ProductsList from '../components/core/Product/ProductsList';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';

const Products = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.search]);

  return (
    <div className='h-full w-full'>
      <div className='fixed w-full z-10'>
        <Navbar />
      </div>
      {/* <HeroSection /> */}
      <ProductsList />
      <Footer />
    </div>
  );
}

export default Products;
