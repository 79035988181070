export const allColors = {
    'RGB': {
        "color": "homeText",
        "text-shadow": "homeText"
    },
    "warm white": {
        "color": "#F6E7D2",
        "text-shadow": 
           `rgb(255, 255, 255) 0px 0px 5px,
            rgb(255, 255, 255) 0px 0px 10px,
            rgb(255, 253, 207) 0px 0px 20px, 
            rgb(255, 253, 207) 0px 0px 30px, 
            rgb(255, 253, 207) 0px 0px 40px, 
            rgb(255, 253, 207) 0px 0px 55px,
            rgb(255, 253, 207) 0px 0px 75px`
    },
    'red': {
        "color": "#FF2A4D",
        'text-shadow':
            `rgb(255, 255, 255) 0px 0px 5px, 
            rgb(255, 255, 255) 0px 0px 10px, 
            rgb(255, 42, 77) 0px 0px 20px, 
            rgb(255, 42, 77) 0px 0px 30px, 
            rgb(255, 42, 77) 0px 0px 40px, 
            rgb(255, 42, 77) 0px 0px 55px, 
            rgb(255, 42, 77) 0px 0px 75px`
    },
    'cool white': {
        "color": "#E1E3E6",
        'text-shadow':
            `rgb(255, 255, 255) 0px 0px 5px, 
            rgb(255, 255, 255) 0px 0px 10px, 
            rgb(225, 227, 230) 0px 0px 20px, 
            rgb(225, 227, 230) 0px 0px 30px, 
            rgb(225, 227, 230) 0px 0px 40px, 
            rgb(225, 227, 230) 0px 0px 55px, 
            rgb(225, 227, 230) 0px 0px 75px`
    },
    'golden yellow': {
        "color": "#FFD62E",
        'text-shadow':
            `rgb(255, 255, 255) 0px 0px 5px, 
            rgb(255, 255, 255) 0px 0px 10px, 
            rgb(255, 214, 46) 0px 0px 20px, 
            rgb(255, 214, 46) 0px 0px 30px, 
            rgb(255, 214, 46) 0px 0px 40px, 
            rgb(255, 214, 46) 0px 0px 55px, 
            rgb(255, 214, 46) 0px 0px 75px`
    },
    'lemon yellow': {
        "color": "#FFF97C",
        'text-shadow':
            `rgb(255, 255, 255) 0px 0px 5px, 
            rgb(255, 255, 255) 0px 0px 10px, 
            rgb(255, 249, 124) 0px 0px 20px, 
            rgb(255, 249, 124) 0px 0px 30px, 
            rgb(255, 249, 124) 0px 0px 40px, 
            rgb(255, 249, 124) 0px 0px 55px, 
            rgb(255, 249, 124) 0px 0px 75px`
    },
    'green': {
        "color": "#37F713",
        "text-shadow":
        `rgb(255, 255, 255) 0px 0px 5px, 
        rgb(255, 255, 255) 0px 0px 10px, 
        #37F713 0px 0px 20px, 
        #37F713 0px 0px 30px, 
        #37F713 0px 0px 40px, 
        #37F713 0px 0px 55px, 
        #37F713 0px 0px 75px`,
    },
    'Pink Purple': {
        "color": "#FF10F0",
        "text-shadow":
        `rgb(255, 255, 255) 0px 0px 5px, 
        rgb(255, 255, 255) 0px 0px 10px, 
        #FF10F0 0px 0px 20px, 
        #FF10F0 0px 0px 30px, 
        #FF10F0 0px 0px 40px, 
        #FF10F0 0px 0px 55px, 
        #FF10F0 0px 0px 75px`,
    },
    'Blue': {
        "color": "#4D4DFF",
        "text-shadow":
        `rgb(255, 255, 255) 0px 0px 5px, 
        rgb(255, 255, 255) 0px 0px 10px, 
        #4D4DFF 0px 0px 20px, 
        #4D4DFF 0px 0px 30px, 
        #4D4DFF 0px 0px 40px, 
        #4D4DFF 0px 0px 55px, 
        #4D4DFF 0px 0px 75px`,
    },
    'Ice Blue': {
        "color": "#1acef6",
        "text-shadow":
        `rgb(255, 255, 255) 0px 0px 5px, 
        rgb(255, 255, 255) 0px 0px 10px, 
        #1acef6 0px 0px 20px, 
        #1acef6 0px 0px 30px, 
        #1acef6 0px 0px 40px, 
        #1acef6 0px 0px 55px, 
        #1acef6 0px 0px 75px`,
    },
    
    // 'orange': {
    //     "color": "#FF8D02",
    //     'text-shadow':
    //         `rgb(255, 255, 255) 0px 0px 5px, 
    //         rgb(255, 255, 255) 0px 0px 10px, 
    //         rgb(255, 141, 2) 0px 0px 20px, 
    //         rgb(255, 141, 2) 0px 0px 30px, 
    //         rgb(255, 141, 2) 0px 0px 40px, 
    //         rgb(255, 141, 2) 0px 0px 55px, 
    //         rgb(255, 141, 2) 0px 0px 75px`
    // },
    // 'light-red': {
    //     "color": "#FF7575",
    //     'text-shadow':
    //         `rgb(255, 255, 255) 0px 0px 5px, 
    //         rgb(255, 255, 255) 0px 0px 10px, 
    //         rgb(255, 117, 117) 0px 0px 20px, 
    //         rgb(255, 117, 117) 0px 0px 30px,
    //         rgb(255, 117, 117) 0px 0px 40px, 
    //         rgb(255, 117, 117) 0px 0px 55px, 
    //         rgb(255, 117, 117) 0px 0px 75px`
    // },
    // 'pink': {
    //     "color": "#FF90FF",
    //     'text-shadow':
    //         `rgb(255, 255, 255) 0px 0px 5px, 
    //         rgb(255, 255, 255) 0px 0px 10px, 
    //         rgb(255, 144, 255) 0px 0px 20px, 
    //         rgb(255, 144, 255) 0px 0px 30px, 
    //         rgb(255, 144, 255) 0px 0px 40px, 
    //         rgb(255, 144, 255) 0px 0px 55px, 
    //         rgb(255, 144, 255) 0px 0px 75px`
    // },
    // 'cotton-candy': {
    //     "color": "#EAA4FF",
    //     'text-shadow':
    //         `rgb(255, 255, 255) 0px 0px 5px, 
    //         rgb(255, 255, 255) 0px 0px 10px, 
    //         rgb(234, 164, 255) 0px 0px 20px, 
    //         rgb(234, 164, 255) 0px 0px 30px, 
    //         rgb(234, 164, 255) 0px 0px 40px, 
    //         rgb(234, 164, 255) 0px 0px 55px, 
    //         rgb(234, 164, 255) 0px 0px 75px`
    // },
    // 'deep-pink': {
    //     "color": "#FF5CE8",
    //     'text-shadow':
    //         `rgb(255, 255, 255) 0px 0px 5px, 
    //         rgb(255, 255, 255) 0px 0px 10px, 
    //         rgb(255, 92, 232) 0px 0px 20px, 
    //         rgb(255, 92, 232) 0px 0px 30px, 
    //         rgb(255, 92, 232) 0px 0px 40px, 
    //         rgb(255, 92, 232) 0px 0px 55px, 
    //         rgb(255, 92, 232) 0px 0px 75px`
    // },
    // 'purple': {
    //     "color": "#8C59FF",
    //     'text-shadow':
    //         `rgb(255, 255, 255) 0px 0px 5px, 
    //         rgb(255, 255, 255) 0px 0px 10px, 
    //         rgb(140, 89, 255) 0px 0px 20px, 
    //         rgb(140, 89, 255) 0px 0px 30px, 
    //         rgb(140, 89, 255) 0px 0px 40px, 
    //         rgb(140, 89, 255) 0px 0px 55px, 
    //         rgb(140, 89, 255) 0px 0px 75px`
    // },
    // 'deep-blue': {
    //     "color": "#0274FC",
    //     'text-shadow':
    //         `rgb(255, 255, 255) 0px 0px 5px, 
    //         rgb(255, 255, 255) 0px 0px 10px, 
    //         rgb(2, 116, 252) 0px 0px 20px, 
    //         rgb(2, 116, 252) 0px 0px 30px, 
    //         rgb(2, 116, 252) 0px 0px 40px, 
    //         rgb(2, 116, 252) 0px 0px 55px, 
    //         rgb(2, 116, 252) 0px 0px 75px`
    // },
}