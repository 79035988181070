import { apiConnector } from "../apiConnector";
import { toast } from "react-hot-toast"
import {orderEndpoints} from '../apis'
import { setUser } from "../../slices/profileSlice";
import { setCart, setTotalItems } from "../../slices/cartSlice";
import { setOrder } from "../../slices/paymentSlice";
const {
    CREATE_ORDER,
    UPDATE_ORDER,
    GET_ORDERS,
    PLACE_ORDER
} = orderEndpoints;

export const createOrder = async(data, token, dispatch, user) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {   
        const response = await apiConnector('POST', CREATE_ORDER, 
                data,
                {Authorization: `Bearer ${token}`},
            )
        console.log("CREATE ORDER API RESPONSE....", response);
        if(!response?.data?.success) {
            throw new Error("Could not Create Order");
        }
        dispatch(setUser({...user, order: response.data?.data?.userDetails?.orders, product: response.data?.data?.userDetails?.product}))
        dispatch(setOrder(response.data?.data?.userDetails?.orders))
        
        result = response.data?.data?.orderDetails;
    } catch(error) {
        console.log("CREATE ORDER API ERROR", error);
        toast.error("Server Issue Please try later");
        result = false;
    }
    toast.dismiss(toastId);
    return result;
}

export const updateOrder = async(data, token) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {   
        const response = await apiConnector('PUT', UPDATE_ORDER, 
                data,
                {Authorization: `Bearer ${token}`},
            )
        console.log("UPDATE ORDER API RESPONSE....", response);
        if(!response?.data?.success) {
            throw new Error("Could not Update Order");
        }
        
        result = response.data?.data;
    } catch(error) {
        console.log("UPDATE ORDER API ERROR", error);
        toast.error("Server Issue Please try later");
        result = false;
    }
    toast.dismiss(toastId);
    return result;
}

export const getOrders = async(token) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {   
        const response = await apiConnector('GET', GET_ORDERS, 
                {},
                {Authorization: `Bearer ${token}`},
            )
        console.log("GET ORDERS API RESPONSE....", response);
        if(!response?.data?.success) {
            throw new Error("Could not get Order");
        }
        result = response.data?.data;

    } catch(error) {
        console.log("GET CART API ERROR", error);
        toast.error("Server Issue Please try later");
        result = null;
    }
    toast.dismiss(toastId);
    return result;
}

export const placeOrder = async(data, token) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {   
        const response = await apiConnector('PUT', PLACE_ORDER, 
                data,
                {Authorization: `Bearer ${token}`},
            )
        console.log("PLACE ORDER API RESPONSE....", response);
        if(!response?.data?.success) {
            throw new Error("Could not Place Order");
        }
        
        result = response.data?.data;
    } catch(error) {
        console.log("PLACE ORDER API ERROR", error);
        toast.error("Server Issue Please try later");
        result = false;
    }
    toast.dismiss(toastId);
    return result;
}

