import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    signupData: null,
    loading: false,
    token: sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null,
    // token: null,
    isGoogleAuth: false,
}

const authSlice = createSlice({
    name:"auth",
    initialState: initialState,
    reducers: {
        setSignupData(state, value) {
            state.signupData = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
        setToken(state, value) {
            state.token = value.payload;
        },
        setGoogleAuth(state, value) {
            state.isGoogleAuth = value.payload;
        }
    },
});

export const {setSignupData, setLoading, setToken, setGoogleAuth} = authSlice.actions;

export default authSlice.reducer