import React from 'react';
import NotFoundGIF from "../assests/NotFoundGIF.gif"
const NotFound = () => {
  return (
    <div className='flex flex-col items-center justify-center h-screen bg-black'>
        <h2 className='text-6xl font-semibold font-Poppins text-white'>No Such Page Exist</h2>
        <img src={NotFoundGIF} alt="" className='w-52' />
    </div>
  );
}

export default NotFound;
