import { createSlice } from "@reduxjs/toolkit";
import toast, { Toast } from "react-hot-toast";

const initialState = {
    // totalItems: sessionStorage.getItem("totalItems") ? JSON.parse(sessionStorage.getItem("totalItems")) : 0,
    totalItems: 0,
    // cart: sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : [],
    cart: [],
    total: sessionStorage.getItem("total") ? JSON.parse(sessionStorage.getItem("total")) : 0,
};

const cartSlice = createSlice( {
    name:"cart",
    initialState: initialState,
    reducers:{
        setTotalItems(state, value) {
            state.totalItems = value.payload;
        },
        // Add to cart
        setCart: (state, actions) => {
            // const product = actions.payload;
            state.cart = actions.payload;
            // If course is not in the cart, add it to the cart
            // state.cart.push(product);
            // Update the total quantity and price
            // state.totalItems++;
            // state.total += product.price;
            // // Update to sessionStorage
            // sessionStorage.setItem("cart", JSON.stringify(state.cart));
            // sessionStorage.setItem("total", JSON.stringify(state.total));
            // sessionStorage.setItem("totalItems", JSON.stringify(state.totalItems));
            // // show toast
            // toast.success("Course added to cart");
        },
        // removefromcart
        removeFromCart: (state, action) => {
            const courseId = action.payload;
            const index = state.cart.findIndex( (item) => item.id === courseId);

            if(index >= 0){
                // If the course is found in the cart, remove it
                state.total -= state.cart[index].price
                state.cart.splice(index, 1);
                state.totalItems--;
                // Update to sessionStorage
                sessionStorage.setItem("cart", JSON.stringify(state.cart));
                sessionStorage.setItem("total", JSON.stringify(state.total));
                sessionStorage.setItem("totalItems", JSON.stringify(state.totalItems));
                // Show toast
                toast.success("Course removed from cart")
            }
        },
        // resetcart
        resetCart: (state) => {
            state.cart= []
            state.total = 0
            state.totalItems = 0

            // Update to sessionStorage
            sessionStorage.removeItem("cart")
            sessionStorage.removeItem("total")
            sessionStorage.removeItem("totalItems")
        }
    }
})

export const {setTotalItems, resetCart, setCart, removeFromCart} = cartSlice.actions;
export default cartSlice.reducer;



