import React from 'react';

const AdminDashboard = () => {
  return (
    <div>
        <div>
            <h2 className='text-white'>Dashboard</h2>
            <div>
                
            </div>
        </div>
    </div>
  );
}

export default AdminDashboard;
