import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddOn } from '../../../slices/productSlice';

const AddOnCards = ({header, para}) => {
    const {addOn} = useSelector((state) => state.product);
    const dispatch = useDispatch();
  return (
    <div className='w-full'>
        <h2 className='text-lg font-semibold font-Poppins'>Addon</h2>
        <button
            onClick={() => {
                if(addOn?.includes(header)){
                    if(header !== "Remote Controller"){
                        dispatch(setAddOn(addOn.filter((item) => item !== header)));
                    }
                }
                else {
                    dispatch(setAddOn([...addOn, header]));
                }
            }}
            disabled={header === "Remote Controller" && true}
            className={`${header === "Remote Controller" ? "cursor-not-allowed" : "cursor-pointer"} p-[1px] border-2 border-black rounded-lg 
                ${addOn?.includes(header) ? 'bg-gradient-to-r from-red-500 via-blue-500 to-purple-500 text-white ' : 'bg-white text-white'}
                w-full
                `
            }
        >
            <div className='p-2 bg-black rounded-lg'>
                <h2 className='text-lg'>
                    {header}
                </h2>
                <p className='text-green-500 font-Lato'>
                    {para}
                </p>
            </div>
        </button>
    </div>
  );
}

export default AddOnCards;
