import React from 'react';
import SignupForm from './SignupForm';
import LoginForm from './LoginForm';
import SignupImage from "../../../assests/SignupImage.jpg"
import Navbar from '../../Navbar';


const Template = ({data, isSignup}) => {
  return (
    <div className='text-white flex w-full h-screen overflow-hidden bg-neonBlack'>
        <div className='absolute w-full top-0'>
            <Navbar />
        </div>
        <div className='w-full h-full flex max-w-[1180px] mx-auto items-center justify-between'>
            {/* Left Info */}
            <div className='sm:flex hidden flex-col gap-y-2 relative'>
                <div className='absolute -left-30 -top-[8.5rem] 2xl:-left-44 md:-top-[10.5rem] opacity-35'>
                    <img src={SignupImage} alt="" className='w-[80%] md:w-[80%] h-[60%] lg:w-full  z-0 border rounded-lg '/>
                </div>
                <div className='2xl:ml-0 ml-20 w-[55%] text-white flex flex-col gap-y-3'>
                    <h2 className='uppercase text-lg md:text-xl lg:text-2xl font-semibold z-10'>
                        {data.Heading}
                    </h2>

                    <h3 className='text-3xl md:text-3xl lg:text-4xl font-bold leading-tight z-10'>
                        {data['Sub-Heading']}
                    </h3>
                </div>
            
            </div>

            {/* Right Form*/}
            <div className='px-5 sm:w-[60%] sm:block flex w-full justify-center'>
                {
                    isSignup 
                    ? (<SignupForm Heading={"Create New Account"}/>)
                    : (<LoginForm Heading={"Welcome Back"}/>)
                }
            </div>
        </div>
    </div>
  );
}

export default Template;
