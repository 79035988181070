import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { sendOtp, signUp } from '../services/operations/authAPI';
import { FaArrowLeftLong } from "react-icons/fa6";
import { GiBackwardTime } from "react-icons/gi";
import { HiEyeSlash, HiMiniEye } from 'react-icons/hi2';
import { setSignupData } from '../slices/authSlice';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const Password = () => {
    const {signupData, loading} = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm();

    const {
        firstName,
        lastName,
        email,
    } = signupData

    const handleOnSubmit = (data) => {
        if(data?.password !== data?.confirmPassword) {
            toast.error("Passsword Do Not Match");
            return;
        }
        dispatch(setSignupData({...signupData, ...data}));
        dispatch(sendOtp(email, navigate));
    }
  return (
    <div className='bg-neonBlack h-screen'>
        <div className='text-white w-11/12 mx-auto h-[550px] items-center justify-center flex overflow-hidden'>
            {loading ? (<div className='loader'></div>)
                : (
                    <div className='max-w-[500px] p-4 lg:p-8'>
                        <h2 className='text-[1.875rem] leading-[2.375rem] font-semibold'>
                            Enter Password
                        </h2>

                        <p  className='text-lg my-4 text-gray-400'>
                            Set a new password for your account. Let's make your account secure.
                        </p>

                        <form onSubmit={handleSubmit(handleOnSubmit)} className='flex flex-col gap-y-6'>
                            <div className='relative'>
                                <input 
                                    type={showPassword ? "text" : "password"}
                                    id='password' 
                                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg 
                                    border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    {...register("password", {required: true})}
                                />
                                <label htmlFor="password"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
                                    dark:bg-neonBlack px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                                    rtl:peer-focus:left-auto start-1"
                                >
                                    Password <sup>*</sup>
                                </label>
                                <div 
                                    className='text-gray-300 absolute cursor-pointer right-5 text-2xl bottom-2'
                                    onClick={() => setShowPassword((prev) => !prev)}
                                >
                                    {showPassword ? (<HiMiniEye />) : (<HiEyeSlash />) }
                                </div>
                                {errors.password && (
                                    <span className='text-red-500 text-xs absolute'>
                                        Password is required
                                    </span>
                                )}
                            </div>

                            <div className='relative'>
                                <input 
                                    type={showConfirmPassword ? "text" : "password"} 
                                    id='confirmPassword'
                                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg 
                                    border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    {...register("confirmPassword", {required: true})}
                                />
                                <label htmlFor="confirmPassword"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
                                    dark:bg-neonBlack px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                                    rtl:peer-focus:left-auto start-1"
                                >
                                    Confirm Password <sup>*</sup>
                                </label>
                                <div 
                                    className='text-gray-300 absolute cursor-pointer right-5 text-2xl bottom-2'
                                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                                >
                                    {showConfirmPassword ? (<HiMiniEye />) : (<HiEyeSlash />) }
                                </div>
                                {errors.confirmPassword && (
                                    <span className='text-red-500 text-xs absolute'>
                                        Confirm Password is required
                                    </span>
                                )}
                            </div>

                            <button type='submit'
                                className='w-full bg-yellow-400 py-[12px] px-[12px] rounded-[8px] mt-4 font-medium text-black'
                            >   
                                Confirm
                            </button>
                        </form>

                        <div className="mt-6 flex items-center justify-between">
                            <div>
                                <Link to="/login"
                                className='flex items-center gap-3'
                                >
                                    <FaArrowLeftLong />
                                    <p>Back to Login</p>
                                </Link>
                            </div>
                        </div>  
                    </div>
                )
            }
            
        </div>
    </div>
  )
}

export default Password