import { ACCOUNT_TYPE } from "../utils/constants";

export const sidebarLinks = [
  {
    id: 1,
    name: "My Profile",
    type: ACCOUNT_TYPE.NORMAL,
    path: "/dashboard/profile",
    icon: "VscAccount",
  },
  {
    id: 2,
    name: "Dashboard",
    path: "/dashboard/profile",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscDashboard",
  },
  {
    id: 3,
    name: "Cart",
    path: "/dashboard/cart",
    type: ACCOUNT_TYPE.NORMAL,
    icon:"IoCartOutline",
  },
  {
    id: 4,
    name: "Orders",
    path: "/dashboard/orders",
    icon:"IoList",
  },
  {
    id: 5,
    name: "Products",
    path: "/dashboard/products",
    type: ACCOUNT_TYPE.ADMIN,
    icon:"VscTag",
  },
  {
    id: 6,
    name: "Categories",
    path: "/dashboard/categories",
    type: ACCOUNT_TYPE.ADMIN,
    icon:"IoFolderOpenOutline",
  },
  {
    id: 7,
    name: "Customers",
    path: "/dashboard/customers",
    type: ACCOUNT_TYPE.ADMIN,
    icon:"IoPeopleOutline",
  },
];
