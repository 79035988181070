import React from 'react'
import {FaCheck} from "react-icons/fa"

// import CourseBuilderForm from './CourseBuilder/CourseBuilderForm'
// import PublishCourse from "./PublishCourse/index";
import { useSelector } from 'react-redux'
import AddressInfo from './AddressInfo';
import OrderSummary from './OrderSummary';
import Payment from './Payment';

const RenderSteps = () => {
    const {step} = useSelector((state) => state.payment);
    // const step = 1;
    const steps = [
        {
            id: 1,
            title: "Address",
        },
        {
            id: 2,
            title: "Order Summary",
        },
        {
            id: 3,
            title: "Payment",
        },
    ]

    return (
        <div>
            <div className='relative mb-2 w-full flex justify-center'>
                {
                    steps.map( (item) => (
                        <>
                            <div className='flex  flex-col items-center'>
                                <button className={`grid cursor-pointer aspect-square w-[34px] place-items-center rounded-full border 
                                ${step === item.id 
                                ? "bg-yellow-300 border-yellow-50 text-black font-bold" 
                                : "border-gray-700 bg-gray-800 text-gray-300"} 
                                ${step > item.id && "bg-yellow-300 text-yellow-300" }`}>

                                {
                                    step > item.id ? 
                                    (<FaCheck className='font-bold text-gray-900' />) : 
                                    (item.id)
                                }

                                </button>
                                <div 
                                    className='flex sm:min-w-[120px] flex-col items-center gap-y-2 text-center px-2'
                                    key={item.id}
                                >

                                    <p
                                        className={`text-sm
                                        ${step >= item.id ? "text-yellow-200" : "text-gray-400" }`}
                                    >
                                        {item.title}
                                    </p>
                                
                                </div>
                            </div>

                            {/* Add Dashes between the labels */}
                            {item.id !== steps.length && (
                                <>
                                    <div
                                        className={`h-[calc(34px/2)] w-[43%] border-dashed border-b-2 
                                        ${step > item.id ? "border-yellow-200 " : "border-gray-500"}`}    
                                    >
                                    </div>
                                </>
                            )}
                        </>
                    ))}
            </div>
                        
            {step === 1 && <AddressInfo />}
            {step === 2 && <OrderSummary />}
            {step === 3 && <Payment /> }

        </div> 


  )
}

export default RenderSteps