import React from 'react';
import VerticalSwiper from './VerticalSwiper';
import { motion } from 'framer-motion';
const HowToInstall = () => {
  return (
    <div className='text-white bg-black flex justify-between py-8'>
      <div className='w-full max-w-[1380px] mx-auto h-full'>
        <div className="w-full flex items-center justify-between h-full relative">

          {/* Line and SVG */}
          <div className='flex flex-col justify-start items-center gap-y-6 place-self-start'>
            <motion.div className='relative '
              initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.3}} viewport={{once: false}}
            >
              <svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                viewBox="0 0 26 26" xmlSpace="preserve">
                <g>
                  <g>
                    <path fill="#ffffff" d="M22.934,11.19V8.705l-2.955-0.482c-0.176-0.699-0.45-1.357-0.813-1.961l1.723-2.456l-1.756-1.755
                      l-2.424,1.743c-0.604-0.366-1.267-0.646-1.971-0.82l-0.516-2.941h-2.484L11.26,2.965c-0.706,0.173-1.371,0.448-1.977,0.812
                      L6.866,2.051L5.11,3.806l1.7,2.431C6.442,6.846,6.163,7.51,5.985,8.22L3.066,8.705v2.484l2.916,0.516
                      c0.176,0.71,0.456,1.375,0.824,1.985l-1.723,2.409l1.756,1.757l2.434-1.704c0.608,0.365,1.271,0.642,1.977,0.815l0.488,2.934
                      h2.484l0.521-2.941c0.701-0.178,1.363-0.457,1.967-0.824l2.451,1.721l1.755-1.757l-1.749-2.429
                      c0.362-0.604,0.637-1.263,0.811-1.964L22.934,11.19z M13,13.431c-1.913,0-3.464-1.55-3.464-3.464c0-1.912,1.551-3.463,3.464-3.463
                      s3.463,1.551,3.463,3.463C16.464,11.881,14.913,13.431,13,13.431z"/>
                  </g>
                  <path fill="#ffffff" d="M24,18.967v4c0,0.551-0.448,1-1,1H3c-0.552,0-1-0.449-1-1v-4H0v4c0,1.656,1.344,3,3,3h20
                    c1.656,0,3-1.344,3-3v-4H24z"/>
                </g>
              </svg>
              <span className='absolute left-0 top-0 h-full w-full bg-[#7ee787] blur-lg'></span>
            </motion.div>
            <motion.div style={{background: 'linear-gradient(#d2a8ff, #a371f7 10%, #196c2e 70%, #2ea043 80%, #56d364)'}}
              className='max-md:w-[2px] w-[3px] max-md:h-[650px] max-sm:h-[750px] max-ssm:h-[900px] max-sssm:h-[1160px] rounded-md line'
              initial={{height: 0}} whileInView={{height: 500}} transition={{duration: 0.7, delay: 0.6}}              
            ></motion.div>
            <motion.div className='relative '
              initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.3}} viewport={{once: false}}
            >
              <svg width="20px" height="20px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                <path d="M35 26.6139L15.1463 7.31395C13.9868 6.18686 12.1332 6.21308 11.0062 7.3725C10.9652 7.41459 10.9256 7.45789 10.8873 7.50235C9.74436 8.82907 9.8228 10.814 11.0669 12.0463L21.091 21.9763" stroke="#ffffff" strokeWidth="4" stroke-linecap="round"/>
                <path d="M21.0909 21.9762L10.1773 11.1548C8.88352 9.87195 6.8201 9.80176 5.44214 10.9937C4.17554 12.0893 4.03694 14.0043 5.13256 15.2709C5.17411 15.3189 5.21715 15.3656 5.26164 15.411L16.2553 26.6138" stroke="#ffffff" strokeWidth="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.2553 26.6138L10 20.5001C8.73766 19.2104 6.67317 19.1745 5.36682 20.4197C4.07445 21.6515 4.02538 23.6978 5.25721 24.9902C5.26288 24.9961 5.26857 25.002 5.27429 25.0079C14.5039 34.5444 19.294 39.0486 21.091 40.5535C24 42.9898 29.7351 44.0001 32.7305 42.0001C35.7259 40.0001 38.4333 37.1545 39.7183 34.3288C40.4833 32.6466 41.9692 27.4596 44.1759 18.768C44.6251 16.9987 43.5549 15.2002 41.7856 14.751C41.7627 14.7452 41.7397 14.7396 41.7167 14.7343C39.8835 14.3106 38.0431 15.4117 37.5499 17.2274L35 26.6138" stroke="#ffffff" strokeWidth="4" stroke-linecap="round"/>
                <path d="M31.7159 12.666C31.004 11.6026 30.1903 10.6131 29.2887 9.71151C28.3871 8.80992 27.3976 7.9962 26.3342 7.28431C25.8051 6.9301 25.2577 6.6011 24.6937 6.29903C24.133 5.99872 23.5559 5.72502 22.9641 5.47963" stroke="#ffffff" strokeWidth="4" stroke-linecap="round"/>
                <path d="M5.19397 33.7763C5.84923 34.8754 6.61005 35.9062 7.46322 36.8537C8.31639 37.8012 9.26192 38.6656 10.2866 39.4322C10.7964 39.8136 11.3259 40.1708 11.8733 40.502C12.4175 40.8312 12.9795 41.1348 13.5576 41.4108" stroke="#ffffff" strokeWidth="4" stroke-linecap="round"/>
              </svg>
              <span className='absolute left-0 top-0 h-full w-full bg-[#7ee787] blur-lg'></span>
            </motion.div>
          </div>

          {/* How To Install & Swiper */}
          <div className="w-full flex items-center justify-between h-full relative md:px-20 pb-20">

            {/* How to Install (Left) */}
            <motion.div className='w-[45%] flex flex-col gap-y-3'
              initial={{opacity: 0, x: -38}} whileInView={{opacity: 1, x:0}} transition={{duration: 0.3, delay: 0.3}} viewport={{once: false}} 

            >
              <p
                style={{fontFamily: "Inter"}}   
                className=' text-[#EF476F] text-xs sm:text-lg w-[80%] font-semibold'>
                Steps to Install Your Sign
              </p>
              <h2 
                style={{fontFamily: "Londrina Outline"}}
                className='text-xl sm:text-6xl md:text-7xl lg:text-8xl font-bold '
              >
                How to Install
              </h2>
              <p className='text-gray-400 text-[0.55rem] sm:text-sm'>
                Thank you for choosing Neongasmic for your custom neon sign needs! Follow these easy steps to install your sign using screws
              </p>
            </motion.div>

            {/* Swiper (Right) */}
            <motion.div className='w-[50%]'
              initial={{opacity: 0, x: 38}} whileInView={{opacity: 1, x:0}} transition={{duration: 0.3, delay: 0.3}} viewport={{once: false}} 

            >
              <VerticalSwiper />
            </motion.div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToInstall;
