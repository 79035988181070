import React from 'react';
import Template from '../components/core/Auth/Template';

const data = {
    Heading: "Join for free",
    "Sub-Heading": "Unleash the traveler inside You, Enjoy your Dream Vacation",
};

const Signup = () => {
  return (
    <Template
        data={data}
        isSignup={true}
    />
  );
}

export default Signup;
