import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowDown } from "react-icons/fa6";
import { setEditAddress, setStep } from '../../../slices/paymentSlice';
import CustomeBG from "../../../assests/Customebg.jpg" 
import {allColors} from '../../../data/gradient-colors'

const OrderSummary = () => {
    const {currentOrder} = useSelector((state) => state.payment);
    const dispatch = useDispatch();

    const changeAddress = () => {
        dispatch(setEditAddress(true));
        dispatch(setStep(1));
    }

  return (
    <div className='text-white pb-10'>
        <div className='flex flex-col gap-y-4 px-6'>
            {/* Delivery Details */}
        
            <div className='w-full bg-gray-950 px-4 py-2 rounded-md flex flex-col'>
                <div className='flex justify-between items-center'>
                    <h2 className='font-bold tracking-wider text-lg'>Delivery to: </h2>
                    <button
                        onClick={() => changeAddress()}
                        className='border border-[#CFFF04] px-4 py-2 rounded-lg text-[#CFFF04]'
                    >
                        Change
                    </button>
                </div>
                <p className='font-bold tracking-wider mt-2'>{currentOrder?.fullName}</p>
                <p className='mt-1 text-lg tracking-wider'>
                    {currentOrder?.address + 
                    " " + currentOrder?.city +
                    " " + currentOrder?.pinCode}
                </p>
                <p className='mt-1 '>{currentOrder?.phoneNumber}</p>
            </div>
            
            <div className='flex flex-col lg:flex-row w-full justify-between relative'>
                {/* Product */}
                {
                    !currentOrder?.type ? (
                        <div className='flex flex-col gap-y-4'>
                            {
                                currentOrder?.products.map((product, index) => (
                                    <div className='flex lg:w-full gap-x-5'>
                                        <div className='w-36 sm:w-56 flex flex-col gap-y-2'>
                                            {product.type !== 'Text'
                                                ? (
                                                <img src={product.image} 
                                                    alt={product.text} 
                                                    className='sm:w-56 sm:h-56 object-fill'
                                                />
                                                )
                                                : (
                                                    <div className='relative w-full h-56 flex items-center justify-center '>
                                                    <img src={CustomeBG} alt="" className='sm:w-full object-fill absolute z-0 sm:h-56 w-full h-[70%]' />
                                                    <div
                                                        className='w-[30%] z-10 absolute left-8 top-16 sm:top-16 sm:left-16'
                                                    >
                                                        <p 
                                                        style={
                                                        {fontFamily: product?.font, textShadow: allColors[product?.color]['text-shadow']}
                                                        } 
                                                        className={`${allColors[product?.color]['text-shadow'] === 'homeText' && "homeText"}  text-base md:text-xl text-white tracking-wide`}
                                                        >
                                                        {product?.text}
                                                        </p>
                                                    </div>
                                                    </div>
                                                )
                                            }
                                            {product?.type !== 'Text' && (
                                                    <p className='text-center border border-white py-1 font-bold'>
                                                        Qty: {product.quantity}
                                                    </p>
                                                )
                                            }
                                        </div>
                                        {/* Details */}
                                        <div>
                                            <p className='text-xl font-semibold font-Poppins'>{product.text}</p>
                                            <p className='font-Poppins font-light mt-1'>
                                                {product?.type === 'Text' ? "Sign" : product?.type }
                                            </p>
                                            <div className='flex flex-col mt-3 gap-y-1'>
                                                {
                                                    product?.type !== 'Text' && (
                                                        <div className='flex items-center gap-x-3'>
                                                            <div className='flex items-center text-green-400 font-semibold font-Poppins'>
                                                                <FaArrowDown />
                                                                <p>
                                                                    {product.discount}% Off
                                                                </p>
                                                            </div>
                                                            
                                                            <s className='text-gray-400 font-Lato'>
                                                                ₹ {Number(product.totalAmount) + Number(((product.discount/100) * product.totalAmount))}                        
                                                            </s>
                                                        </div>
                                                    )
                                                }
                                                
                                                <p className='font-Lato text-lg font-semibold'>₹ {product.price}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                    :(
                        <div className='flex lg:w-[45%] gap-x-5'>
                            <div className='w-36 sm:w-56 flex flex-col gap-y-2'>
                                {currentOrder.type !== 'Text'
                                    ? (
                                    <img src={currentOrder.image} 
                                        alt={currentOrder.text} 
                                        className='sm:w-56 sm:h-56 object-fill'
                                    />
                                    )
                                    : (
                                        <div className='relative w-full h-56 flex items-center justify-center '>
                                        <img src={CustomeBG} alt="" className='sm:w-full object-fill absolute z-0 sm:h-56 w-full h-[70%]' />
                                        <div
                                            className='w-[30%] z-10 absolute left-8 top-16 sm:top-16 sm:left-16'
                                        >
                                            <p 
                                            style={
                                            {fontFamily: currentOrder?.font, textShadow: allColors[currentOrder?.color]['text-shadow']}
                                            } 
                                            className={`${allColors[currentOrder?.color]['text-shadow'] === 'homeText' && "homeText"}  text-base md:text-xl text-white tracking-wide`}
                                            >
                                            {currentOrder?.text}
                                            </p>
                                        </div>
                                        </div>
                                    )
                                }
                                {currentOrder?.type !== 'Text' && (
                                        <p className='text-center border border-white py-1 font-bold'>
                                            Qty: {currentOrder.quantity}
                                        </p>
                                    )
                                }
                            </div>
                            {/* Details */}
                            <div>
                                <p className='text-xl font-semibold font-Poppins'>{currentOrder.text}</p>
                                <p className='font-Poppins font-light mt-1'>
                                    {currentOrder?.type === 'Text' ? "Sign" : currentOrder?.type }
                                </p>
                                <div className='flex flex-col mt-3 gap-y-1'>
                                    {
                                        currentOrder?.type !== 'Text' && (
                                            <div className='flex items-center gap-x-3'>
                                                <div className='flex items-center text-green-400 font-semibold font-Poppins'>
                                                    <FaArrowDown />
                                                    <p>
                                                        {currentOrder.discount}% Off
                                                    </p>
                                                </div>
                                                
                                                <s className='text-gray-400 font-Lato'>
                                                    ₹ {Number(currentOrder.totalAmount) + Number(((currentOrder.discount/100) * currentOrder.totalAmount))}                        
                                                </s>
                                            </div>
                                        )
                                    }
                                    
                                    <p className='font-Lato text-lg font-semibold'>₹ {currentOrder.totalAmount}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
                
                {/* Price Details */}
                <div className='bg-gray-950 p-5 rounded-md w-full lg:w-[30%] lg:fixed top-[22rem] right-56   '>
                    <div className='flex flex-col'>
                        <h2 className='text-lg font-Poppins font-semibold'>
                            Price Details
                        </h2>
                        <div className='mt-4 flex flex-col gap-y-2'>
                            <div className='flex justify-between items-center font-Lato'>
                                <p>Price ({currentOrder?.productId.length ? currentOrder?.productId.length + " items": 1 + " item"})</p>
                                {
                                    !currentOrder?.type || currentOrder?.type === 'Text'
                                    ? <p>₹ {currentOrder.totalAmount}</p>
                                    : <p>₹ {Number(currentOrder.totalAmount) + Number(((currentOrder.discount/100) * currentOrder.totalAmount))}</p>
                                }
                            </div>
                            {
                                currentOrder?.type && currentOrder?.type !== 'Text' &&   (
                                    <div className='flex justify-between items-cente font-Lator'>
                                        <p>Discount</p>
                                        <p className='text-green-400 font-semibold'>
                                            - ₹ {Number(((currentOrder.discount/100) * currentOrder.totalAmount))}
                                        </p>
                                    </div>
                                )
                            }
                            
                            <div className='flex justify-between items-center font-Lator'>
                                <p>Delivery Charges</p>
                                <p className='text-green-400 font-semibold'>
                                    Free Delivery
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center justify-between py-2 border-y my-3 border-gray-400 text-lg font-bold font-Lato'>
                            <p>
                                Total Amount
                            </p>
                            <p>
                                ₹ {currentOrder.totalAmount}
                            </p>
                        </div>
                        {
                            !currentOrder?.type || currentOrder?.type !== 'Text' && (
                                <div className='flex items-center justify-center text-green-400'>
                                    You will save ₹ {Number(((currentOrder.discount/100) * currentOrder.totalAmount))} on this Order
                                </div>
                            )
                        }
                    </div>

                    <button 
                        onClick={() => dispatch(setStep(3))}
                        className='bg-[#CFFF04] text-black py-2 px-4 rounded-lg font-extrabold font-Lato mt-3 w-full hover:bg-opacity-95 hover:scale-95 transition-all duration-300'>
                        Payment
                    </button>
                    
                </div>
            </div>

        </div>
    </div>
  )
}

export default OrderSummary