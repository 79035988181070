export const pricesList = {
    1:{
        length: 6,
        height: 5,
        price:500,
    },
    2: {
        length: 6,
        height: 5,
        price:600,
    },
    3: {
        length: 9,
        height: 6,
        price:750,
    },
    4: {
        length: 12,
        height: 6,
        price:800,
    },
    5: {
        length: 15,
        height: 8,
        price:950,
    },    
}