import React from 'react';

const CartAndBuy = ({customeStyle, cartStyle, buyStyle, handleAddToCart, handleBuyNow}) => {
    
  return (
    <div className={`${customeStyle} flex gap-x-4`}>
        <button
            onClick={handleAddToCart}
            className={`${customeStyle} ${cartStyle} border border-black rounded-lg text-lg font-Poppins font-semibold hover:bg-opacity-85 hover:scale-95 transition-all duration-300`}
        >
            Add to Cart
        </button>

        <button
            onClick={handleBuyNow}
            className={`${customeStyle} ${buyStyle} border border-black rounded-lg text-lg font-Poppins font-semibold hover:bg-opacity-85 hover:scale-95 transition-all duration-300`}
        >
            Buy Now
        </button>
    </div>
  );
}

export default CartAndBuy;
