import { motion } from "framer-motion";
import { FaInstagram } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { CiFacebook } from "react-icons/ci";


export default function ContactUs() {
  const data = [
    {
      imgData: [
        {
          id: 0,
          icon: <FaInstagram className="hover:text-pink-500 transition-all duration-300"/>,
          link: "https://www.instagram.com/neongasmic/",
        },
        {
          id: 1,
          icon: <FaWhatsapp className="hover:text-green-500 transition-all duration-300"/>,
          link: "https://wa.me/+918950794302",
        },
        {
          id: 2,
          icon: <CiFacebook className="hover:text-blue-500 transition-all duration-300"/>,
          link: "https://www.facebook.com/people/Neongasmic/61552678838860/",
        },
      ],
    },
  ];
  return (
    <>
      <div className="relative flex flex-col min-h-screen md:flex-row text-white bg-black">
        <div className="flex flex-col items-center w-full md:w-1/2 mt-36">
          <div className="relative w-56 rounded-full h-28">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/25 from-70% to-yellow-600/50 rounded-full blur-xl"></div>
            <motion.div
              initial={{ x: "-7rem", opacity: 0 }}
              animate={{ x: "0", opacity: 1 }}
              transition={{
                duration: 4,
                type: "spring",
                delay: 0.5,
              }}
              className="z-10 flex flex-col h-full font-semibold text-center sm:text-left sm:font-medium "
            >
              <h2 className="text-3xl">Contact Us</h2>
            </motion.div>
          </div>
        </div>
        <div className="w-full p-12 mt-2 mr-20 text-center md:w-1/2 sm:mt-20 sm:text-left">
          <motion.p
            initial={{ x: "7rem", opacity: 0 }}
            animate={{ x: "0", opacity: 1 }}
            transition={{
              duration: 1,
              type: "spring",
              delay: 0.5,
            }}
            className="text-3xl text-[#ff10f0]"
          >
            Welcome to Neongasmic
          </motion.p>
          <motion.p
            initial={{ x: "7rem", opacity: 0 }}
            animate={{ x: "0", opacity: 1 }}
            transition={{
              duration: 2,
              type: "spring",
              delay: 0.5,
            }}
            className="pt-8 text-xl"
          >
            At Neongasmic, we specialize in creating stunning custom neon signs, MDF signs, and UV printed signs that bring your vision to life. Our products are perfect for personalizing your home, business, or special events with unique and vibrant designs.
          </motion.p>
          {/* {/* <div className="flex mt-5 "> */}
          <motion.a
            initial={{ x: "7rem", opacity: 0 }}
            animate={{ x: "0", opacity: 1 }}
            transition={{
              duration: 3,
              type: "spring",
              delay: 0.5,
            }}
            href="#"
            className="flex flex-col mt-5 sm:text-xl text-start"
          >
            <div>
              Join us on Social's
            </div>
            <div className="text-base flex flex-col gap-y-1 mt-2">
              <p className="cursor-default">
                Phone No: +91 8950794302
              </p>
              <a href="mailto:neongasmicofficial@gmail.com">
                Email:- neongasmicofficial@gmail.com
              </a>
            </div>
            <img src="HeroSection/arrow-right.svg" alt="" />
          </motion.a>

          <motion.div
            initial={{ x: "7rem", opacity: 0 }}
            animate={{ x: "0", opacity: 1 }}
            transition={{
              duration: 4,
              type: "spring",
              delay: 0.5,
            }}
            className="w-40 sm:w-72 mt-3 sm:mt-5 h-0.5 bg-gradient-to-r from-blue-500 via-blue-500 to-yellow-600/50"
          ></motion.div>
          <div className="py-2 mt-4 flex items-start gap-5 social-icons">
            {data.map((item) => {
              return item.imgData.map((value, i) => (
                <motion.a
                  initial={{ y: "7rem", opacity: 0 }}
                  animate={{ y: "0", opacity: 1 }}
                  transition={{
                    duration: 3,
                    type: "spring",
                    delay: 0.5,
                  }}
                  key={value.id}
                  href={value.link}
                  target="_blank"
                >
                  <div className="text-white text-4xl">
                    {value.icon}

                  </div>
                </motion.a>
              ));
            })}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}