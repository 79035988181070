import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCart } from '../../../../services/operations/productAPI';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete } from "react-icons/md";
import ProductBg1 from "../../../../assests/Product-bg1.jpg" 
import {allColors} from '../../../../data/gradient-colors'
import { getOrders } from '../../../../services/operations/orderAPI';
const Orders = () => {
  const [loading, setLoading] = useState(false);
  const {user} = useSelector((state) => state.profile);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    (async() => {
      setLoading(true);
      const response = await getOrders(user?.token);
      if(response) {
        setOrders(response);
        setLoading(false);
      }

    })();
  }, [dispatch, user?.token]);


  return (
    <div className='flex w-full justify-between gap-x-6 text-neonWhite'>
      {
        loading 
        ? (
          <div className='w-full flex items-center justify-center h-[calc(100vh-7rem)] overflow-hidden'>
            <div className='loader'></div>
          </div>
        )
        : (
          <>
            <div className='w-full sm:w-[90%]'>
              <h2 className='text-3xl sm:text-4xl font-semibold'>Orders</h2>

              <div className='w-full sm:px-6 py-8'>
                <div className='w-full grid grid-cols-4 text-sm sm:text-lg font-semibold gap-x-2 sm:gap-x-5 text-center'>
                  <p>Items</p>
                  <p>Total</p>
                  <p>Order</p>
                  <p>Payment</p>
                </div>
                {
                  orders?.length > 0
                  ? (
                    <>
                      {
                        orders.map((order) => (
                          <div key={order?.id} className='w-full grid grid-cols-4 gap-x-2 sm:gap-x-5 place-items-center text-center py-2 h-[120px]'>

                            <div className='font-semibold text-xs sm:text-lg md:text-[1.18rem] flex justify-between items-center gap-x-4 '>
                              <span>
                                {order?.productId?.length}
                              </span>
                            </div>
                            <p className='font-semibold text-xs sm:text-base lg:text-[1.18rem]'>₹ {order?.totalAmount}</p>
                            <div 
                              className={`${order?.orderStatus === 'Incomplete' ? "bg-[#ff10f0]" : order?.orderStatus === 'Making' ? "bg-[#FF3131]" : "bg-[#CFFF04]" } 
                                font-semibold text-[0.60rem] lg:text-[1.30rem] cursor-pointer p-1 sm:p-2 rounded-full hover:bg-opacity-80 transition-all duration-300`}
                            >
                              {order?.orderStatus}
                            </div>
                            <div 
                              className={`${order?.paymentStatus === 'Pending' ? "bg-[#FF3131]" : "bg-[#CFFF04]" } 
                              font-semibold text-[0.60rem] lg:text-[1.30rem] cursor-pointer p-1 sm:p-2 rounded-full hover:bg-opacity-80 transition-all duration-300`}
                            >
                              {order?.paymentStatus}
                            </div>
                          </div>
                        ))
                      }
                    </>
                  )
                  : (
                    <div className='flex flex-col items-center justify-center gap-y-2 h-full'>
                      <p className='text-center text-lg py-3 '>
                        No Order
                      </p>
                      <button 
                        onClick={() => navigate('/products?filter=events')}
                        className='border border-white hover:border-neonBlue px-3 py-2 rounded-lg transition duration-300'
                      >
                        Explore Products
                      </button>
                    </div>
                  )
                }
              </div>
            </div>

            {/* <div className='flex flex-col justify-between  overflow-hidden rounded-md border-[1px] border-gray-700 text-white
              gap-x-4 backdrop-blur-md px-3 shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg isolate w-[30%]'
            >
              <h2 className='px-8 py-4 border-b border-b-gray-400'>
                Order Summary
              </h2>

              <div className='py-4 px-8 flex flex-col gap-y-3'>
                <div className='flex w-full justify-between'>
                  <p>Subtotal</p>
                  <p>price</p>
                </div>

                <div className='flex w-full justify-between'>
                  <p>Shipping</p>
                  <p>price</p>
                </div>
              </div>

              <div className='px-8 py-4 border-t border-gray-400 flex justify-between'>
                <p>Total</p>
                <p>price</p>
              </div>
            </div> */}
          </>
        )
      }
      
    </div>
  );
}

export default Orders;
