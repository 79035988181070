import {apiConnector} from "../apiConnector"
import {toast} from "react-hot-toast"
import {profileEndpoints} from '../apis'
import { setUser } from "../../slices/profileSlice";

const {
    GET_PROFILE
} = profileEndpoints;

export const getProfile = async( profileId, token, user, dispatch) => { 
    const toastId = toast.loading("Loading...");
    let result = [];
    try {   
        const response = await apiConnector('POST', GET_PROFILE, 
            {id: profileId},
            {Authorization: `Bearer ${token}`},
        )
        console.log("GET PROFILE API RESPONSE....", response);
        if(!response?.data?.success) {
            throw new Error("Could not get Profile");
        }
        dispatch(setUser({...user, cart: response.data?.data?.cart, products: response.data?.data?.products, orders: response.data?.data?.orders}));
        result = response.data?.data;
    } catch(error) {
        console.log("GET PROFILE API ERROR", error);
        toast.error("Server Issue Please try later");
        result = null;
    }
    toast.dismiss(toastId);
    return result;
}