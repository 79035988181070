import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import OrdersAndInvoice from './OrdersAndInvoice';
import { getProfile } from '../../../../services/operations/profileAPI';
import { AiOutlineShoppingCart } from 'react-icons/ai';

const Profile = () => {
  const {user} = useSelector((state) => state.profile)
  const [loading, setLoading] = useState(false)
  const [profileDetails, setProfileDetails] = useState({})
  const dispatch = useDispatch();

  useEffect(() => {
    const getProfileDetails = async() => {
      setLoading(true);
      const response = await getProfile(user?.profileDetails, user?.token, user, dispatch);      
      if(response) {
        setLoading(false);
        setProfileDetails(response);
      }
    }
    getProfileDetails();
  }, [])

  return (
    <div className='flex flex-col xl:flex-row items-center w-full justify-between gap-y-6 xl:gap-y-0 gap-x-6'>

      {loading 
        ? (
            <div className='w-full flex items-center justify-center h-[calc(100vh-7rem)] overflow-hidden'>
              <div className="loader"></div>
            </div>
          )
        :(
          <>
            {/* User Details */}
            <div className='overflow-hidden border-[1px] border-gray-700 text-white h-[28rem]
              backdrop-blur-md  shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg isolate w-full xl:w-[35%] flex flex-col items-center justify-center gap-y-4 px-5 py-5 rounded-2xl'
            >
              {/* User Image, Name, Edit Profile */}
              <div className='flex flex-col items-center gap-y-2 w-full'>
                <img 
                  src={user?.image} 
                  alt="Profile" 
                  className='rounded-full w-20 border border-white p-1'
                />
                <h2
                  className='text-lg'
                >
                  {user?.firstName}{" "} {user?.lastName}
                </h2>
              </div>

              {/* Email, Mobile No, Address */}
              <div className='flex flex-col w-full gap-y-4'>
                <div className='rounded-lg px-3 py-3 bg-neonViolet'>
                  <p className='text-sm text-gray-300'>Email</p>
                  <p className='font-semibold'>{user?.email}</p>
                </div>

              </div>
            </div>
            
            <div className='w-full flex flex-col gap-y-5'>
              {/* Cards */}
              <div className='w-full flex justify-between items-center gap-x-3 '>
                <div className='flex gap-x-2 overflow-hidden border-[1px] border-gray-700 text-white
                  gap-y-4 backdrop-blur-md  shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg 
                  isolate px-5 py-6 sm:py-8 rounded-xl w-[30%] justify-between sm:h-[150px]'
                >
                  <div className='flex flex-col justify-start gap-y-2'>
                    <p className='text-xl sm:text-2xl lg:text-3xl'>
                      {user?.products.length}
                    </p>
                    <p className='text-base sm:text-lg lg:text-xl font-Lato tracking-wider'>
                      All Products
                    </p>
                  </div>

                  <div>
                    <svg className='w-12 h-12 lg:w-24 lg:h-24 hidden sm:block' 
                      viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#FF3131">
                      <rect x="8" y="8" width="12" height="12"/>
                      <rect x="26" y="8" width="12" height="12"/>
                      <rect x="26" y="44" width="12" height="12"/>
                      <rect x="44" y="8" width="12" height="12"/>
                      <rect x="8" y="26" width="12" height="12"/>
                      <rect x="26" y="26" width="12" height="12"/>
                      <rect x="44" y="26" width="12" height="12"/>
                      <rect x="8" y="44" width="12" height="12"/>
                      <rect x="44" y="44" width="12" height="12"/>
                    </svg>
                  </div>
                </div>

                <div className='flex gap-x-2 overflow-hidden border-[1px] border-gray-700 text-white
                  gap-y-4 backdrop-blur-md  shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg 
                  isolate px-5 py-6 sm:py-8 rounded-xl w-[30%] justify-between sm:h-[150px]'>
                  <div className='flex flex-col justify-start gap-y-2'>
                    <p className='text-2xl lg:text-3xl'>
                      {profileDetails?.orders?.length}
                    </p>
                    <p className='text-lg lg:text-xl font-Lato tracking-wider'>Orders</p>
                  </div>

                  <div>
                    <svg fill="#ff10f0" className='w-12 h-12 lg:w-24 lg:h-24 hidden sm:block' 
                      version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                      viewBox="0 0 512 512" xmlSpace="preserve">
                      <g>
                        <g>
                          <path d="M476.158,286.938l-13.259-53.035c3.625-0.77,6.345-3.986,6.345-7.839v-8.551c0-18.566-15.105-33.67-33.67-33.67h-60.392
                                v-17.637c0-9.136-7.432-16.568-16.568-16.568H246.32l68.24-27.296c4.111-1.644,6.11-6.31,4.466-10.421
                                c-1.644-4.11-6.307-6.111-10.421-4.466l-55.874,22.349c17.026-10.924,33.871-22.947,40.284-31.355
                                c12.485-16.369,9.323-39.843-7.046-52.328c-16.369-12.486-39.843-9.323-52.328,7.046c-9.122,11.962-21.158,45.573-28.948,69.258
                                c-7.79-23.683-19.826-57.296-28.948-69.258c-12.484-16.369-35.959-19.53-52.328-7.046c-16.369,12.484-19.53,35.958-7.046,52.328
                                c6.413,8.409,23.257,20.431,40.284,31.355l-55.874-22.349c-4.113-1.647-8.777,0.355-10.421,4.466
                                c-1.645,4.111,0.355,8.777,4.466,10.421l68.24,27.296H50.772c-9.136,0-16.568,7.432-16.568,16.568v145.37
                                c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-145.37c0-0.295,0.239-0.534,0.534-0.534h307.841
                                c0.295,0,0.534,0.239,0.534,0.534v145.372c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-9.088h94.566
                                c0.009,0,0.016,0.002,0.025,0.002s0.017-0.001,0.026-0.001c11.636,0.009,21.516,7.647,24.908,18.171h-24.928
                                c-4.427,0-8.017,3.589-8.017,8.017v17.102c0,13.851,11.268,25.119,25.119,25.119h9.086v35.273h-20.962
                                c-6.886-19.882-25.787-34.205-47.982-34.205s-41.097,14.322-47.982,34.205h-3.86V345.78c0-4.427-3.589-8.017-8.017-8.017
                                c-4.427,0-8.017,3.589-8.017,8.017v60.392H192.817c-6.886-19.882-25.787-34.205-47.982-34.205s-41.097,14.322-47.982,34.205
                                H50.772c-0.295,0-0.534-0.239-0.534-0.534v-17.637h34.739c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017H8.017
                                c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h26.188v17.637c0,9.136,7.432,16.568,16.568,16.568h43.304
                                c-0.002,0.178-0.014,0.355-0.014,0.534c0,27.995,22.777,50.772,50.772,50.772s50.772-22.777,50.772-50.772
                                c0-0.18-0.012-0.356-0.014-0.534h180.67c-0.002,0.178-0.014,0.355-0.014,0.534c0,27.995,22.777,50.772,50.772,50.772
                                c27.995,0,50.772-22.777,50.772-50.772c0-0.18-0.012-0.356-0.014-0.534h26.203c4.427,0,8.017-3.589,8.017-8.017v-85.511
                                C512,307.564,496.423,290.022,476.158,286.938z M172.9,121.059c-31.623-19.651-41.003-28.692-43.78-32.334
                                c-7.123-9.339-5.319-22.732,4.021-29.855c3.85-2.936,8.388-4.355,12.893-4.355c6.422,0,12.776,2.886,16.963,8.376
                                c7.755,10.168,19.9,44.391,27.918,69.052C185.504,128.757,179.284,125.028,172.9,121.059z M218.473,131.942
                                c8.018-24.66,20.163-58.882,27.917-69.052c7.123-9.339,20.516-11.142,29.855-4.02c9.34,7.123,11.143,20.516,4.021,29.855
                                c-2.777,3.641-12.157,12.683-43.778,32.333C230.105,125.026,223.885,128.756,218.473,131.942z M375.182,199.875h60.392
                                c9.725,0,17.637,7.912,17.637,17.637v0.534h-78.029V199.875z M375.182,286.456V234.08h71.235l13.094,52.376H375.182z
                                    M144.835,457.479c-19.155,0-34.739-15.584-34.739-34.739s15.584-34.739,34.739-34.739c19.155,0,34.739,15.584,34.739,34.739
                                S163.99,457.479,144.835,457.479z M427.023,457.479c-19.155,0-34.739-15.584-34.739-34.739s15.584-34.739,34.739-34.739
                                c19.155,0,34.739,15.584,34.739,34.739S446.178,457.479,427.023,457.479z M495.967,354.865h-9.086
                                c-5.01,0-9.086-4.076-9.086-9.086v-9.086h18.171V354.865z"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M144.835,406.172c-9.136,0-16.568,7.432-16.568,16.568s7.432,16.568,16.568,16.568c9.136,0,16.568-7.432,16.568-16.568
                              S153.971,406.172,144.835,406.172z"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M427.023,406.172c-9.136,0-16.568,7.432-16.568,16.568s7.432,16.568,16.568,16.568c9.136,0,16.568-7.432,16.568-16.568
                              S436.159,406.172,427.023,406.172z"/>
                        </g>
                      </g>
                      <g>
                          <g>
                              <path d="M332.96,371.967H213.244c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017H332.96
                                  c4.427,0,8.017-3.589,8.017-8.017S337.388,371.967,332.96,371.967z"/>
                          </g>
                      </g>
                      <g>
                          <g>
                              <path d="M127.733,337.763H25.119c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h102.614
                                  c4.427,0,8.017-3.589,8.017-8.017S132.16,337.763,127.733,337.763z"/>
                          </g>
                      </g>
                      <g>
                          <g>
                              <path d="M127.733,218.046H93.528c-4.427,0-8.017,3.589-8.017,8.017v68.409c0,4.427,3.589,8.017,8.017,8.017
                                  s8.017-3.589,8.017-8.017v-26.188h17.637c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017h-17.637V234.08h26.188
                                  c4.427,0,8.017-3.589,8.017-8.017C135.749,221.636,132.16,218.046,127.733,218.046z"/>
                          </g>
                      </g>
                      <g>
                          <g>
                              <path d="M190.822,272.043c8.023-5.255,13.337-14.317,13.337-24.602c0-16.209-13.186-29.395-29.395-29.395h-21.378
                                  c-4.427,0-8.017,3.589-8.017,8.017v68.409c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017v-17.637h13.346l14.722,22.083
                                  c1.545,2.317,4.086,3.571,6.677,3.571c1.529,0,3.073-0.436,4.439-1.348c3.685-2.455,4.68-7.433,2.223-11.116L190.822,272.043z
                                      M174.764,260.802h-13.361V234.08h13.361c7.368,0,13.361,5.993,13.361,13.361C188.125,254.809,182.132,260.802,174.764,260.802z"
                                  />
                          </g>
                      </g>
                      <g>
                          <g>
                              <path d="M256,286.456h-26.188v-18.198c0.177,0.012,0.354,0.027,0.534,0.027h17.102c4.427,0,8.017-3.589,8.017-8.017
                                  s-3.589-8.017-8.017-8.017h-17.102c-0.181,0-0.357,0.015-0.534,0.027V234.08H256c4.427,0,8.017-3.589,8.017-8.017
                                  c0-4.427-3.589-8.017-8.017-8.017h-34.205c-4.427,0-8.017,3.589-8.017,8.017v68.409c0,4.427,3.589,8.017,8.017,8.017H256
                                  c4.427,0,8.017-3.589,8.017-8.017S260.427,286.456,256,286.456z"/>
                          </g>
                      </g>
                      <g>
                          <g>
                              <path d="M315.858,286.456H289.67v-18.171h9.086c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017h-9.086V234.08h26.188
                                  c4.427,0,8.017-3.589,8.017-8.017c0-4.427-3.589-8.017-8.017-8.017h-34.205c-4.427,0-8.017,3.589-8.017,8.017v68.409
                                  c0,4.427,3.589,8.017,8.017,8.017h34.205c4.427,0,8.017-3.589,8.017-8.017S320.285,286.456,315.858,286.456z"/>
                          </g>
                      </g>
                    </svg>
                  </div>
                </div>

                <div className='flex gap-x-2 overflow-hidden border-[1px] border-gray-700 text-white
                  gap-y-4 backdrop-blur-md  shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg 
                  isolate px-5 py-6 sm:py-8 rounded-xl w-[30%] justify-between sm:h-[150px]'>
                  <div className='flex flex-col justify-start gap-y-2'>
                    <p className='text-2xl lg:text-3xl'>
                      {user?.cart?.length}
                    </p>
                    <p className='text-lg lg:text-xl font-Lato tracking-wider'>Carts</p>
                  </div>

                  <div>
                    <AiOutlineShoppingCart className='hidden sm:block text-4xl md:text-5xl lg:text-8xl text-[#CFFF04]'/>
                  </div>
                </div>
              </div>
              
              <OrdersAndInvoice orders={profileDetails?.orders} cart={profileDetails?.cart}/>
            </div>
          </>
        )
      }
    </div>
  );
}

export default Profile;
