import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HeadingAndPara from '../components/core/Legal/HeadingAndPara';

const REFUND_POLICY = [
  {
    heading: "Shipping and Delivery",
    para: `We offer free and safe Pan-India shipping.
    Estimated delivery times may vary based on your location.
    We are not responsible for delays caused by customs clearance or other external factors.`,
  },
  {
    heading: "Returns and Refunds",
    para: `Due to the custom nature of our products, we do not accept returns unless the product is defective or damaged upon arrival.
    If you receive a defective or damaged product, please contact us within 7 days of receipt with photographic evidence.`,
  },
  {
    heading: "Warranty",
    para: `We offer a 2-year warranty on our neon signs against defects in materials and workmanship.
    The warranty does not cover damage due to misuse, neglect, or unauthorized modifications.`,
  },
  
]

const RefundPolicy = () => {
  return (
    <div className='bg-black text-white/90 text-white'>
      <div className='fixed w-full bg-black z-10'>
        <Navbar />
      </div>
      <div className='w-[1080px] mx-auto pt-28 flex flex-col gap-y-4'>
        <h3
          style={{fontFamily: "Inter"}}
          className='text-4xl font-semibold mb-5'
        >
          Refund Policy  
        </h3>
        <HeadingAndPara heading="Introduction" paragraph="Welcome to Neongasmic! These terms and conditions outline the rules and regulations for the use of Neongasmic's website." />

        
        <div
          style={{fontFamily: "Inter"}}
          className='text-base'
        >
          <h3 className='font-bold text-lg'>Definitions</h3>
          <div>
            <p>
              "Website": Refers to www.neongasmic.com and any related services.
            </p>
            <p>
              "We", "Us", "Our": Refers to Neongasmic.
            </p>
            <p>
              "You", "User": Refers to the individual or entity using our services.
            </p>
            <p>
              "Products": Refers to the custom neon signs, MDF signs, and UV printed signs available for sale on our website.
            </p>
          </div>
        </div>

        {
          REFUND_POLICY.map((item, index) => (
            <HeadingAndPara heading={item.heading} paragraph={item.para}/>
          ))
        }

        <div
          style={{fontFamily: "Inter"}}
          className='text-base mb-20'
        >
          <h3 className='font-bold text-lg'>
            Contact Information
          </h3>
          <p>
            If you have any questions about these terms and conditions, please contact us at:
          </p>
          <p>
            Email: {" "} 
            <a href="mailto:neongasmicofficial@gmail.com"
              className='text-blue-400 underline'
            >
              neongasmicofficial@gmail.com
            </a>
          </p>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default RefundPolicy;
