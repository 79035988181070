import React from 'react';
import { useSelector } from 'react-redux';
import { ACCOUNT_TYPE } from '../../../../utils/constants';
import AdminDashboard from './AdminDashboard';
import Profile from './Profile';

const MyProfile = () => {
  const {user} = useSelector((state) => state.profile);
  return (
    <div className='text-white bg-black '>
      {user?.accountType == ACCOUNT_TYPE.ADMIN
        ? (<AdminDashboard />) 
        : (<Profile />)
      }
    </div>
  );
}

export default MyProfile;
