import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Navigation } from 'swiper/modules';


export default function Slider({children}) {

  return (
    <div className='w-full h-full pb-10'>
      <div className='w-full max-w-[1380px] mx-auto '>

        <div className='mt-8 w-full flex justify-start'>
          <Swiper
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={4}
            spaceBetween={50}
            loop={true}
            autoplay={{
              delay: 1800,
              disableOnInteraction: false,
            }}
            modules={[ Autoplay]}
            className="mySwiper ml-52 w-full py-12 "
          >
            {children}
          </Swiper>
          
        </div>
      </div>
    </div>
  );
}
