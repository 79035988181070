export const PRODUCT_DROPDOWN = [
    {
        name: 'MDF & Acrylic Logos',
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087250/HomePageMedia/gfdvdgje9s87gm4cuvrf.jpg",
        link: "/products?filter=mdf-and-acrylic-logos"
    },
    {
        name: 'Events',
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087238/HomePageMedia/jumn3xoq0bb22c6mwhxx.jpg",
        link: "/products?filter=events"
    },
    {
        name: 'Cafe & Restaurants',
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087225/HomePageMedia/kx4ubz0rppgn8c4iimkb.jpg",
        link: "/products?filter=cafe-restaurants"
    },
    {
        name: 'Others',
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087199/HomePageMedia/trrjlezylmhds3aqxhbn.jpg",
        link: "/products?filter=others"
    },
]

export const CUSTOME = [
    {
        name: "Logo",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087199/HomePageMedia/cld2td4uuew7gskojijn.jpg",
        link: "/custome?filter=logo"
    },
    {
        name: "Signs",
        image: "https://res.cloudinary.com/dnxiis9tk/image/upload/v1715087199/HomePageMedia/fbjm0f3hvsh9fyguej3f.jpg",
        link: "/custome?filter=signs"
    },
]