import GooglePayButton from '@google-pay/button-react';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setStep } from '../../../slices/paymentSlice';
import { IoMdArrowRoundBack } from "react-icons/io";
import { placeOrder, updateOrder } from '../../../services/operations/orderAPI';
import { useNavigate } from 'react-router-dom';

const Payment = () => {
    const dispatch = useDispatch();
    const {currentOrder} = useSelector((state) => state.payment)
    const {user} = useSelector((state) => state.profile)
    const [paymentMethod, setPaymentMethod] = useState('cod')
    const navigate = useNavigate();
 
 
    // Global key for canMakepayment cache.
    const canMakePaymentCache = 'canMakePaymentCache';

//     /**
//      * Check whether can make payment with Google Pay or not. It will check session storage
//      * cache first and use the cache directly if it exists. Otherwise, it will call
//      * canMakePayment method from PaymentRequest object and return the result, the
//      * result will also be stored in the session storage cache for future usage.
//      *
//      * @private
//      * @param {PaymentRequest} request The payment request object.
//      * @return {Promise} a promise containing the result of whether can make payment.
//      */
    function checkCanMakePayment(request) {
    // Check canMakePayment cache, use cache result directly if it exists.
    if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
        return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
    }

    // If canMakePayment() isn't available, default to assume the method is
    // supported.
    var canMakePaymentPromise = Promise.resolve(true);

    // Feature detect canMakePayment().
    if (request.canMakePayment) {
        canMakePaymentPromise = request.canMakePayment();
    }

    return canMakePaymentPromise
        .then((result) => {
            // Store the result in cache for future usage.
            sessionStorage[canMakePaymentCache] = result;
            return result;
        })
        .catch((err) => {
            console.log('Error calling canMakePayment: ' + err);
        });
    }
   



/**
* Show the payment request UI.
*
* @private
* @param {PaymentRequest} request The payment request object.
* @param {Promise} canMakePayment The promise for whether can make payment.
*/
function showPaymentUI(request, canMakePayment) {
 if (!canMakePayment) {
   handleNotReadyToPay();
   return;
 }

 // Set payment timeout.
 let paymentTimeout = window.setTimeout(function() {
   window.clearTimeout(paymentTimeout);
   request.abort()
       .then(function() {
         console.log('Payment timed out after 20 minutes.');
       })
       .catch(function() {
         console.log('Unable to abort, user is in the process of paying.');
       });
 }, 20 * 60 * 1000); /* 20 minutes */

 request.show()
     .then(function(instrument) {

       window.clearTimeout(paymentTimeout);
       processResponse(instrument); // Handle response from browser.
     })
     .catch(function(err) {
       console.log(err);
     });
}
 


//     /** Launches payment request flow when user taps on buy button. */
    function onBuyClicked() {
        if (!window.PaymentRequest) {
        console.log('Web payments are not supported in this browser.');
        return;
        }
    
        // Create supported payment method.
        const supportedInstruments = [
        {
            supportedMethods: ['https://tez.google.com/pay'],
            data: {
            pa: 'neongasmic@kotak',
            pn: 'Neongasmic',
            tr: '1234ABCD',  // Your custom transaction reference ID
            url: 'https://neongasmic.com/success',
            mc: '5699', //Your merchant category code
            tn: 'Purchase in Neongasmic',
            },
        }
        ];
    
        // Create order detail data.
        const details = {
        total: {
            label: 'Total',
            amount: {
            currency: 'INR',
            value: '10.01', // sample amount
            },
        },
        displayItems: [{
            label: 'Original Amount',
            amount: {
            currency: 'INR',
            value: '10.01',
            },
        }],
        };
    
        // Create payment request object.
        let request = null;
        try {
        request = new PaymentRequest(supportedInstruments, details);
        } catch (e) {
        console.log('Payment Request Error: ' + e.message);
        return;
        }
        if (!request) {
        console.log('Web payments are not supported in this browser.');
        return;
        }
    
        var canMakePaymentPromise = checkCanMakePayment(request);
        canMakePaymentPromise
            .then((result) => {
            showPaymentUI(request, result);
            })
            .catch((err) => {
            console.log('Error calling checkCanMakePayment: ' + err);
            });
    }

	
   
/**
* Process the response from browser.
*
* @private
* @param {PaymentResponse} instrument The payment instrument that was authed.
*/
    function processResponse(instrument) {
    // var instrumentString = instrumentToJsonString(instrument);
    // console.log(instrumentString);

    // fetch('/buy', {
    // method: 'POST',
    // headers: new Headers({'Content-Type': 'application/json'}),
    // body: instrumentString,
    // })
    //     .then(function(buyResult) {
    //     if (buyResult.ok) {
    //         return buyResult.json();
    //     }
    //     console.log('Error sending instrument to server.');
    //     })
    //     .then(function(buyResultJson) {
    //     completePayment(instrument, buyResultJson.status, buyResultJson.message);

    //     })
    //     .catch(function(err) {
    //     console.log('Unable to process payment. ' + err);
    //     });
    }

        


// /**
// * Notify browser that the instrument authorization has completed.
// *
// * @private
// * @param {PaymentResponse} instrument The payment instrument that was authed.
// * @param {string} result Whether the auth was successful. Should be either
// * 'success' or 'fail'.
// * @param {string} msg The message to log in console.
// */
    function completePayment(instrument, result, msg) {
    instrument.complete(result)
        .then(function() {
        console.log('Payment succeeds.');
        console.log(msg);
        })
        .catch(function(err) {
        console.log(err);
        });
    }



//     /** Handle Google Pay not ready to pay case. */
    function handleNotReadyToPay() {
        alert('Google Pay is not ready to pay.');
    }


    
    /**
     * Notify browser that the instrument authorization has completed.
     *
     * @private
     * @param {PaymentResponse} instrument The payment instrument that was authed.
     * @param {string} result Whether the auth was successful. Should be either
     * 'success' or 'fail'.
     * @param {string} msg The message to log in console.
     */
    function completePayment(instrument, result, msg) {
        instrument.complete(result)
            .then(function() {
            console.log('Payment succeeds.');
            console.log(msg);
            })
            .catch(function(err) {
            console.log(err);
            });
    }



    const handlePlaceOrder = async({paymentMethod}) => {
        console.log("Gpay Clicked")
        onBuyClicked();

        // const updates = {
        //     orderId: currentOrder.orderId,
        //     paymentMethod: paymentMethod === 'cod' ? "Cash on Delivery" : "Google Pay",
        //     orderStatus: "Placed",
        //     paymentStatus: paymentMethod === 'cod' ? "Pending" : "Paid",
        // }
        // const response = await placeOrder(updates, user?.token);
        // if(response) {
        //     sessionStorage.removeItem('currentOrder');
        //     sessionStorage.removeItem('step');
        //     navigate('/success');
        // }
    }
  
  return (
    <div>
        <div className='text-white flex flex-col gap-y-6 sm:gap-y-0 sm:flex-row justify-between gap-x-6 px-6'>
            <div className='sm:w-[40%] flex flex-col justify-between items-start'>
                <div className='flex flex-col gap-y-3 w-full'>
                    <h2 className='text-2xl font-semibold font-Poppins'>
                        Payment Method
                    </h2>
                    <div className='flex flex-col gap-y-4 w-full    '>
                        <div 
                            className='flex items-center gap-x-2 cursor-pointer p-2 bg-gray-900 rounded-md'
                            onClick={() => setPaymentMethod('cod')}
                        >
                            <div 
                                type="radio" 
                                value="Cash on Delivery"
                                id="cod"
                                className={`${paymentMethod === 'cod' ? "bg-blue-500" : "bg-black" } w-3 h-3 border border-white rounded-full`}
                            />
                            <p className='text-lg font-Lato tracking-wider'>
                                Cash on Delivery
                            </p>
                        </div>
			{/*
                         <GooglePayButton
                            environment="PRODUCTION"
                            paymentRequest={{
                                apiVersion: 2,
                                apiVersionMinor: 0,
                                allowedPaymentMethods: [
                                {
                                    type: 'CARD',
                                    parameters: {
                                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                    allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                    },
				    tokenizationSpecification: {
				    	type: "PAYMENT_GATEWAY",
					parameters: {
						gateway: "cybersource",
						gatewayMerchantId: "bcr2dn4ts723dktk",
					},

				    },
                                },
                                ],
                                merchantInfo: {
                                merchantId: 'BCR2DN4TS723DKTK',
                                merchantName: 'Neongasmic',
                                },
                                transactionInfo: {
                                totalPriceStatus: 'FINAL',
                                totalPriceLabel: 'Total',
                                totalPrice: `${currentOrder.totalAmount}`,
                                currencyCode: 'INR',
                                countryCode: 'IN',
                                },
                            }}
                            onLoadPaymentData={paymentRequest => {
                                console.log('load payment data', paymentRequest);
                                handlePlaceOrder('gpay')
                            }}
                            /> 
			   */}

	                {/*<button
                            onClick={() => handlePlaceOrder('gpay')}
                            className='bg-black text-white rounded-md w-full text-center py-2'>
                            Gpay
                        </button>*/}

                    </div>


                </div>

                <button
                    onClick={() => dispatch(setStep(2))}
                    className=' w-full sm:w-auto text-white p-2 rounded-md mt-4 text-lg bg-gray-800 flex items-center gap-x-2 hover:bg-opacity-90 hover:scale-95 transition-all duration-300'
                >
                    <IoMdArrowRoundBack />
                    <p>
                        Order Summary
                    </p>
                </button>
            </div>

            <div className='bg-gray-950 p-5 rounded-md w-full sm:w-[50%] lg:w-[40%]'>
                <div className='flex flex-col'>
                    <h2 className='text-lg font-Poppins font-semibold'>
                        Price Details
                    </h2>
                    <div className='mt-4 flex flex-col gap-y-2'>
                        <div className='flex justify-between items-center font-Lato'>
                            <p>Price ({currentOrder?.productId.length ? currentOrder?.productId.length + " items": 1 + " item"})</p>
                            {
                                !currentOrder?.type || currentOrder?.type === 'Text'
                                ? <p>₹ {currentOrder.totalAmount}</p>
                                : <p>₹ {Number(currentOrder.totalAmount) + Number(((currentOrder.discount/100) * currentOrder.totalAmount))}</p>
                            }
                        </div>
                        {
                            currentOrder?.type && currentOrder?.type !== 'Text' &&   (
                                <div className='flex justify-between items-cente font-Lator'>
                                    <p>Discount</p>
                                    <p className='text-green-400 font-semibold'>
                                        - ₹ {Number(((currentOrder.discount/100) * currentOrder.totalAmount))}
                                    </p>
                                </div>
                            )
                        }
                        
                        <div className='flex justify-between items-center font-Lator'>
                            <p>Delivery Charges</p>
                            <p className='text-green-400 font-semibold'>
                                Free Delivery
                            </p>
                        </div>
                    </div>

                    <div className='flex items-center justify-between py-2 border-y my-3 border-gray-400 text-lg font-bold font-Lato'>
                        <p>
                            Total Amount
                        </p>
                        <p>
                            ₹ {currentOrder.totalAmount}
                        </p>
                    </div>
                    {
                        !currentOrder?.type || currentOrder?.type !== 'Text' && (
                            <div className='flex items-center justify-center text-green-400'>
                                You will save ₹ {Number(((currentOrder.discount/100) * currentOrder.totalAmount))} on this Order
                            </div>
                        )
                    }
                </div>

                
                <button 
                    onClick={() => handlePlaceOrder('cod')}
                    className='bg-[#CFFF04] text-black py-2 px-4 rounded-lg font-extrabold font-Lato mt-3 w-full hover:bg-opacity-95 hover:scale-95 transition-all duration-300'>
                    Place Order
                </button>
                
            </div>
            
        </div>
        
    </div>
  )
}

export default Payment
