import React from 'react';
import Logo from "../assests/Logo.png"
import { Link } from 'react-router-dom';
import { CUSTOME, PRODUCT_DROPDOWN } from '../data/dropDownData';
import { IoMdMail } from "react-icons/io";

const LEGAL = [
    {
        name: "Terms & Conditions",
        link: "/term-and-conditions"
    },
    {
        name: "Refund Policy",
        link: "/refund-policy"
    },
    {
        name: "Shipping Policy",
        link: "/shipping-policy"
    },
]

const FOOTER_DATA = [
    {
        name: 'Product',
        data: PRODUCT_DROPDOWN
    },
    {
        name: 'Custome',
        data: CUSTOME
    },
    {
        name: 'Legal',
        data: LEGAL
    }
]

const Footer = () => {
  return (
    <div className='w-full py-20 bg-[#202329]'>
        <div className='w-full max-w-[1280px] mx-auto text-white flex flex-col items-center gap-y-16'>
            <div className='w-full flex flex-col sm:flex-row sm:gap-y-0 gap-y-8 items-center justify-between gap-x-8 px-10'>
                <div className='flex flex-col items-center gap-y-3 px-6'>
                    <div className='flex items-center gap-x-2'>
                        <img 
                            src={Logo} 
                            alt="" 
                            className="w-10 lg:w-12 rounded-full" 
                        />
                        <h2 className='font-bold tracking-[0.3rem] text-lg lg:text-xl'>
                            NEONGASMIC
                        </h2>
                    </div>
                    <p 
                        style={{fontFamily: 'Inter'}}
                        className='text-sm sm:text-left  text-center'>
                        The World's Most Affordable Signs.
                    </p>
                </div>
                <div className='grid grid-cols-2 lg:grid-cols-4  gap-y-6 lg:gap-y-0 gap-x-10 lg:gap-x-10'>
                    {
                        FOOTER_DATA.map((item, index) => (
                            <div key={index}>
                                <h2 style={{fontFamily: 'Inter'}}
                                    className='font-semibold '
                                >
                                    {item.name}
                                </h2>
                                {item.data.map((item, index) => (
                                    <Link 
                                        to={item.link}
                                        key={index}
                                        className='block my-2 text-gray-400 hover:text-neonBlue transition-all duration-300'
                                        style={{fontFamily: 'Inter'}}
                                    >
                                        {item.name}
                                    </Link>
                                ))}            
                            </div>
                        ))
                    }
                    <div className='w-full flex flex-col gap-y-3 pr-5'>
                        <h2 className='font-semibold uppercase'>Get in Touch</h2>
                        <a  
                            href="mailto:neongasmicofficial@gmail.com"
                            className='flex items-center gap-x-2 text-gray-400 hover:text-neonBlue transition-all duration-300 group text-lg'
                        >
                            <IoMdMail />
                            <p
                                className='text-gray-400 hover:text-neonBlue group-hover:text-neonBlue transition-all duration-300'
                                style={{fontFamily: 'Inter'}}
                            >
                                Contact Us
                            </p>
                        </a>
                    </div>
                </div>
            </div>
            <p className='px-10'>
                Copyright © 2024 Sorting Neongasmic Pvt Ltd. All Rights Reserved.
            </p>
        </div>
    </div>
  );
}

export default Footer;
