import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fontStyle: "New Cursive",
    text: "Neongasmic",
    color: 'warm white',
    price: 0,
    size: "Small",
    description: "",
    addOn: ['Remote Controller'],
};

const productSlice = createSlice({
    name: "product",
    initialState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducers: {
        setFontStyle(state, value) {
            state.fontStyle = value.payload;
        },
        setText(state, value) {
            state.text = value.payload;
        },
        setColor(state, value) {
            state.color = value.payload;
        },
        setPrice(state, value) {
            state.price = value.payload;
        },
        setSize(state, value) {
            state.size = value.payload;
        },
        setDesc(state, value) {
            state.description = value.payload;
        },
        setAddOn(state, value) {
            state.addOn = value.payload;
        }
    }
})

export const {setFontStyle, setText, setColor, setPrice, setSize, setDesc, setAddOn} = productSlice.actions;

export default productSlice.reducer;
