import React from 'react';
import { TiTick } from "react-icons/ti";
import {motion} from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar';
const Success = () => {
    const navigate = useNavigate();
  return (
    <div className='relative'>
        <div className='absolute w-full'>
            <Navbar />
        </div>
        <div 
            className='w-full h-screen bg-black text-white flex flex-col items-center justify-center
            gap-y-8'
        >    
            <motion.div 
                initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.3, delay: 0.3}}
                className='flex flex-col items-center justify-center gap-y-8 text-4xl sm:text-6xl md:ext-[6rem] leading-none'
            >
                <p className='text-green-500 md:w-[60%] text-center mx-auto font-Lato tracking-wider '>
                    Order Placed Successfully
                </p>
                <TiTick 
                    className='text-white bg-green-500 rounded-full'
                />
            </motion.div>
            <button
                onClick={() => navigate('/dashboard/orders')}
                className='bg-gray-800 px-4 py-2 rounded-md text-white text-lg hover:bg-opacity-90 hover:scale-95 transition-all duration-300'    
            >
                My Orders
            </button>
        </div>
    </div>
  );
}

export default Success;
