import React from 'react';
import Template from '../components/core/Auth/Template';

const data = {
    Heading: "WelCome Back",
    "Sub-Heading": "Let's start all-over again",

};

const Login = () => {
  return (
    <Template
      data={data}
    />
  );
}

export default Login;
