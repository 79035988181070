import { apiConnector } from "../apiConnector";
import { toast } from "react-hot-toast"
import {productEndpoints} from '../apis'
import { setUser } from "../../slices/profileSlice";
import { setCart, setTotalItems } from "../../slices/cartSlice";
const {
    ADD_CART, REMOVE_CART, GET_CART, CREATE_PRODUCT
} = productEndpoints;

export const addCart = async(data, token, dispatch, user) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {   
        const response = await apiConnector('POST', ADD_CART, 
                data,
                {Authorization: `Bearer ${token}`},
            )
        console.log("ADD CART API RESPONSE....", response);
        if(!response?.data?.success) {
            throw new Error("Could not Add to Cart");
        }
        toast.success("Added to Cart");
        dispatch(setUser({...user, cart: response.data?.data?.updatedUserDetails?.cart}))
        dispatch(setCart(response.data?.data?.updatedUserDetails?.cart))
        dispatch(setTotalItems(response.data?.data?.updatedUserDetails?.cart?.length))

        result = response.data?.data;
    } catch(error) {
        console.log("ADD CART API ERROR", error);
        toast.error("Server Issue Please try later");
    }
    toast.dismiss(toastId);
    return result;
}

export const getCart = async(token, dispatch) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {   
        const response = await apiConnector('GET', GET_CART, 
                {},
                {Authorization: `Bearer ${token}`},
            )
        console.log("GET CART API RESPONSE....", response);
        if(!response?.data?.success) {
            throw new Error("Could not get Cart");
        }
        result = response.data?.data;
        dispatch(setCart(response.data?.data))
        dispatch(setTotalItems(response.data?.data?.length))

    } catch(error) {
        console.log("GET CART API ERROR", error);
        toast.error("Server Issue Please try later");
        result = null;
    }
    toast.dismiss(toastId);
    return result;
}

export const removeCart = async(productId, token, dispatch, user) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {   
        const response = await apiConnector('DELETE', REMOVE_CART, 
                {productId},
                {Authorization: `Bearer ${token}`},
            )
        console.log("REMOVE CART API RESPONSE....", response);
        if(!response?.data?.success) {
            throw new Error("Could not Remove from Cart");
        }
        toast.success("Removed from Cart");
        dispatch(setUser({...user, cart: response.data?.data?.cart, products: response.data?.data?.products}))
        dispatch(setCart(response.data?.data?.cart))
        dispatch(setTotalItems(response.data?.data?.cart?.length))

        result = response.data?.data;
    } catch(error) {
        console.log("REMOVE CART API ERROR", error);
        toast.error("Server Issue Please try later");
    }
    toast.dismiss(toastId);
    return result;
}


export const createProduct = async(data, token, dispatch) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {   
        const response = await apiConnector('POST', CREATE_PRODUCT, 
                data,
                {Authorization: `Bearer ${token}`},
            )
        console.log("CREATE PRODUCT API RESPONSE....", response);
        if(!response?.data?.success) {
            throw new Error("Could not Create Product");
        }
        toast.success("Product Created");
        result = response.data?.data;
    } catch(error) {
        console.log("CREATE PRODUCT API ERROR", error);
        toast.error("Server Issue Please try later");
    }
    toast.dismiss(toastId);
    return result;
}