import React from 'react'

const AnimatedInput = ({type, id, label, register, errors}) => {
  return (
    <div className='relative'>
        <input 
            type={type} 
            id={id}
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md   
            border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
            dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
            placeholder=" " 
            {...register(id, {required: true})}
        />
        <label htmlFor={id}
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
            -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
            dark:bg-black px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
            peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
            peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
            rtl:peer-focus:left-auto start-1"
        >
            {label} <sup>*</sup>
        </label>
        
        {errors[id] && (
            <span className='text-red-500 text-xs absolute'>
                {label} is required
            </span>
        )}
    </div>
  )
}

export default AnimatedInput