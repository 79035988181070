export const HowToInstall = [
    {
        heading: 'Unpack Your Sign:',
        description: "Carefully unpack your neon sign and power adapter.",
        icon: "",
        color: "#FF3131"
    },
    {
        heading: "Choose the Location:",
        description: "Select a location with a nearby power outlet. Ensure the surface is suitable for mounting.",
        icon: "",
        color: "#ff10f0"
    },
    {
        heading: "Mark the Position:",
        description:  "Hold the sign against the wall at the desired location.Use a level to make sure it’s straight.Mark the positions of the pre-drilled holes with a pencil.",
        icon: "",
        color: "#CFFF04"
    },
    {
        heading: "Drill Pilot Holes:",
        description: "Using a drill bit appropriate for your wall anchors, drill pilot holes at the marked positions.Insert wall anchors into the holes if mounting on drywall or plaster.",
        icon: "",
        color: "#4D4DFF"
    },
    {
        heading: "Mount the Sign:",
        description: "Align the sign’s pre-drilled holes with the pilot holes or wall anchors.Insert screws through the holes and into the wall anchors or directly into the wall.Tighten the screws with a screwdriver until the sign is securely mounted.",
        icon: "",
        color: "#FF5F1F"
    },
    {
        heading: "Connect the Power:",
        description: `Plug the adapter into the sign’s power connector.Connect the adapter to a power outlet.Switch on your sign to enjoy its vibrant glow !`,
        icon: "",
        color: "#39FF14"
    },
    {
        heading: "Additional Tips:",
        description: "Use appropriate screws and anchors based on your wall type.Ensure the sign is level before tightening the screws completely.For heavier signs, consider using additional support.",
        icon: "",
        color: "#00FFFF"
    },

]