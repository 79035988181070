export const ACCOUNT_TYPE = {
    ADMIN: "Admin",
    NORMAL :"Normal",
}

export const SPECIAL_FONTS = ['Nevada', 'Neon Tokyo', 'Sci-fi', 'Avante']
export const LOVE_NEON = 'LoveNeon'
export const GRUPPO = 'Gruppo'
export const POIRET_ONE = 'Poiret one'

export const SIZE = {
    MEDIUM: 'Medium',
    LARGE: 'Large',
}
