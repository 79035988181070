import React from 'react';
import { allColors } from '../../../data/gradient-colors';
import { useDispatch, useSelector } from 'react-redux';
import { setColor } from '../../../slices/productSlice';


const SelectColor = () => {
  const {color} = useSelector((state) => state.product);
  const dispatch = useDispatch();


  return (
    <div className='relative w-full h-full'>
      <div className='sticky top-0 left-0 right-0 font-semibold font-Lato text-sm sm:text-base  py-3 flex items-center gap-x-1'>
        <p className='text-white '>
          Choose Color : 
        </p>
        <p className='capitalize text-[#CFFF04]'>{color}</p>
      </div>

      <div className='grid overflow-y-scroll sm:grid-cols-5 grid-cols-3 gap-x-3 gap-y-4 h-[150px] pr-7'>
        {
          Object.entries(allColors).filter((entry) => entry[0] != 'RGB').map((entry, key) => {
              return (
                <div 
                  key={key}
                  className='flex flex-col items-center gap-y-2 w-full h-full relative'
                >  
                  <div 
                    className={`${color === entry[0] ? "border-neonViolet" : "border-black"} p-1 flex justify-center border-2 rounded-full`}
                    onClick={() => {dispatch(setColor(entry[0]))}}
                  >
                    <div
                      style={{background: entry[1]['color']}} 
                      className={`${entry[1]['color'] === "homeText" ? "bg-gradient-to-r from-yellow-300 via-red-500 to-blue-700": ""} w-6 h-6 sm:w-12 sm:h-12 rounded-full cursor-pointer`}
                    >
                      {" "}
                    </div>
                  </div>
                  <p className={`${color === entry[0] ? "text-neonBlue" : "text-white/50"} text-xs sm:text-sm font-semibold text-center capitalize`}>
                    {entry[0]}
                  </p>
                </div>
              )
          })
        }
      </div>

      <div 
        className='flex flex-col items-start gap-y-2 w-full h-full relative mt-3'
      >  
        <p className={`text-white text-xs sm:text-lg font-semibold text-center capitalize pl-1`}>
          RGB
        </p>
        <div 
          className={`${color === "RGB" ? "border-neonViolet" : "border-black"} p-1 flex justify-center border-2 rounded-lg`}
          onClick={() => {dispatch(setColor("RGB"))}}
        >
          <div
            style={{background: allColors["RGB"]['color']}} 
            className={`${allColors["RGB"]['color'] === "homeText" ? "bg-gradient-to-r from-yellow-300 via-red-500 to-blue-700": ""} w-12 h-6 sm:w-32 sm:h-12 rounded-lg cursor-pointer`}
          >
            {" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectColor;
