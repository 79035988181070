import React from 'react'
import RenderSteps from '../components/core/Order/RenderSteps'
import Navbar from '../components/Navbar'
const Order = () => {
  return (
    <div className='bg-black h-screen overflow-x-hidden'>
      <div>
        <Navbar />     
      </div>
      <div className='w-full max-w-[1080px] mx-auto py-10 '>
        <RenderSteps />
      </div>
    </div>
  )
}

export default Order