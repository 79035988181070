import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AddOnCards from './AddOnCards';

const AddOn = () => {
  const {text, color, addOn} = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const characters = text.toString().split("").filter((letter) => letter !== " ");
  
  return (
    <div>
      <div className='grid grid-cols-2 bg-black gap-x-3 gap-y-3'>
        <AddOnCards header="Waterproof" 
          para={
            characters?.length < 11
              ? '₹ 500'
              : '₹ 1000'   
          }
        />
    
        {color !== 'RGB' 
          ?(
            <AddOnCards header="Dimmer" 
              para="₹ 400"
            />
          )
          :(
            <>
              
              <AddOnCards header="Mobile Controller" 
                para="₹ 800"
              />

              <AddOnCards header="Remote Controller" 
                para="Free"
              />

            </>
          )
        }

      </div>
    </div>
  )
}

export default AddOn