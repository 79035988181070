import React, { useRef, useState } from 'react'
import { VscDashboard, VscSignOut } from "react-icons/vsc"
import { AiOutlineShoppingCart } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import {logout} from "../services/operations/authAPI"
import { Link } from 'react-router-dom';
import { IoList } from "react-icons/io5";

import useOnClickOutside from '../hooks/useOnClickOutside';

const ProfileDropDown = () => {
  const {user} = useSelector( (state) => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpen(false));

  let settings = [
    ['Profile', <VscDashboard className='text-lg' />, '/dashboard/profile'], 
    ['Cart', <AiOutlineShoppingCart className='text-lg' />, '/dashboard/cart'], 
    ['Orders', <IoList className='text-lg' />,'/dashboard/orders'], 
  ];
  if(!user) return null;

  return (
    <button className='relative z-10' onClick={() => setOpen(true)}>
      <div className='flex items-center gap-x-1'>
        <img 
        src={user?.image} 
        alt={`profile-${user?.firstName}`} 
        className='aspect-square w-[40px] rounded-full object-cover border border-neonPink p-1'
        />
      </div>

      {
        open && (
          <div
          onClick={(e) => e.stopPropagation()}
          className='absolute top-[118%] w-40 right-0 z-[1000]  overflow-hidden rounded-md border-[1px] border-gray-700 text-white
            flex flex-col justify-center gap-y-2 py-5 backdrop-blur-md  shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg isolate'
          ref={ref}
          >
            {settings.map((setting, index) => ( 
              <Link to={setting[2]} key={index} onClick={ () => setOpen(false)}>
                <div className='flex w-36 items-center gap-x-1 py-[10px] px-4 text-base text-white/80 
                  hover:bg-black/20 hover:text-white rounded-lg'
                >
                  {setting[1]}
                  {setting[0]}
                </div>
              </Link>
            ))}

            <div
            onClick={() => {
              dispatch(logout(navigate))
              setOpen(false)
            }}
            className='flex w-full items-center gap-x-1 py-[10px] px-4 text-sm text-richblack-100 hover:bg-richblack-700 hover:text-richblack-25'
            >
              <VscSignOut className='text-lg' />
              Logout
            </div>
          </div>
        )
      }
    
    </button>
  )
}

export default ProfileDropDown