import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Orders from '../Orders';
import Cart from '../Cart';
import { useSelector } from 'react-redux';

const OrdersAndInvoice = ({orders, cart}) => {
    const [isOrders, setIsOrders] = useState(true);
    const {user} = useSelector((state) => state.profile);
    const navigate = useNavigate();
  return (
    <div className='hidden md:block w-full overflow-hidden border-[1px] border-gray-700 text-white
        gap-y-4 backdrop-blur-md  shadow-lg ring-1 ring-black/5 bg-black/40 backdrop:blur-lg isolate rounded-xl px-6 py-6'>
        <div className='flex gap-x-1 items-center border-b-2  border-b-white pb-3'>
            <p 
                onClick={() => setIsOrders(true)}
                className={`${isOrders ? "text-white " : "text-gray-400"} cursor-pointer px-3 py-1`}
            >
                Orders
            </p>
            <p
                onClick={() => setIsOrders(false)}
                className={`${isOrders ? "text-gray-400" : "text-white"} cursor-pointer px-3 py-1`}
            > 
                Cart    
            </p>
        </div>

        <div>
            {
                isOrders 
                ? (
                    <div className='px-3 pt-4'>
                        {
                            orders?.length === 0
                            ? (
                                <div className='flex flex-col items-center gap-y-2'>
                                    <p className='text-center text-lg'>
                                        No Orders
                                    </p>
                                    <button 
                                        onClick={() => navigate('/products?filter=events')}
                                        className='border border-white px-3 py-2 rounded-lg'
                                    >
                                        Explore Products
                                    </button>
                                </div>
                            )
                            : (
                                <div className='h-[280px] w-full overflow-y-scroll'>
                                    <Orders />
                                </div> 
                            )
                        }     
                    </div>
                ) 
                : (
                    <div className='px-3 pt-4'>
                        {
                            user?.cart?.length === 0
                            ? (
                                <div className='flex flex-col items-center gap-y-2'>
                                    <p className='text-center text-lg'>
                                        No Cart
                                    </p>
                                    <button 
                                        onClick={() => navigate('/products?filter=events')}
                                        className='border border-white px-3 py-2 rounded-lg'
                                    >
                                        Explore Products
                                    </button>
                                </div>
                            )
                            : (
                                <div>
                                    <Cart isSummary={false}/> 
                                </div> 
                            )
                        }     
                    </div>
                )
            }

        </div>
    </div>
  );
}

export default OrdersAndInvoice;
