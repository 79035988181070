import React from 'react';
import Sidebar from '../components/core/Dashboard/Sidebar';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';

const Dashboard = () => {
  return (
    <div>
        <Navbar />
      <div className='relative flex min-h-[calc(100vh - 3.5rem)] w-screen'>
        <Sidebar /> 
        <div className='h-[calc(100vh - 3.5rem)] overflow-auto w-full bg-black'>
          <div className='mx-auto w-11/12 py-10'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
