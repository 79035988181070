import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiEyeSlash, HiMiniEye } from 'react-icons/hi2';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../../services/operations/authAPI';
import { decodeJwt } from 'jose';
import toast from 'react-hot-toast';

const LoginForm = ({Heading}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm()
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOnSubmit = async(data) => {
      const {email, password} = data;
      dispatch(login(email, password, false, navigate));
    }    

    const handleGoogleLogin = (credentialResponse) => {
        const decode = decodeJwt(credentialResponse.credential);     
   
        const email = decode?.email;
        const password = null;
        const isVerified = decode?.email_verified;
        dispatch(login(email, password, isVerified, navigate));
    }

  return (
    <div className='flex flex-col sm:items-start items-center gap-y-6 w-full'>
        <h2 className='text-5xl sm:text-left text-center font-semibold text-wrap w-full'>
            {Heading}
        </h2>

        <form
            className="flex flex-col gap-7 w-full"
            onSubmit={handleSubmit(handleOnSubmit)}
        >
            <div className='relative'>
                <input 
                    type="email" 
                    id='email'
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg 
                    border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" " 
                    {...register("email", {required: true})}
                />
                <label htmlFor="email"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
                    dark:bg-neonBlack px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                    rtl:peer-focus:left-auto start-1"
                >
                    Email <sup>*</sup>
                </label>
                
                {errors.email && (
                    <span className='text-red-500 text-xs absolute'>
                        Email is required
                    </span>
                )}
            </div>

            <div className='relative'>
                <input 
                    type={showPassword ? "text" : "password"}
                    id='password' 
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg 
                    border border-gray-300 appearance-none dark:text-white dark:border-gray-600 
                    dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" " 
                    {...register("password", {required: true})}
                />
                <label htmlFor="password"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform 
                    -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white bg-transparent 
                    dark:bg-neonBlack px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 
                    rtl:peer-focus:left-auto start-1"
                >
                    Password <sup>*</sup>
                </label>
                <div 
                    className='text-gray-300 absolute cursor-pointer right-5 text-2xl bottom-2'
                    onClick={() => setShowPassword((prev) => !prev)}
                >
                    {showPassword ? (<HiMiniEye />) : (<HiEyeSlash />) }
                </div>
                {errors.password && (
                    <span className='text-red-500 text-xs absolute'>
                        Password is required
                    </span>
                )}
            </div>
            <Link to="/forgot-password">
                <p className='mt-1 ml-auto max-w-max text-xs text-blue-100 hover:text-blue-500 transition-all duration-200'>
                Forgot Password 
                </p>
            </Link>

            <button 
                type="submit" 
                className="bg-[#CFFF04] text-black py-2 px-4 rounded-lg font-extrabold font-Lato mt-3 w-full hover:bg-opacity-95 hover:scale-95 transition-all duration-300"
            >
                Login
            </button>
        </form>
    </div>
  );
}

export default LoginForm;
