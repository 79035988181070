import React from 'react';
import {motion} from "framer-motion"
import { WhyNeongasmic } from '../../../data/whyNeongasmic';
const WhyUs = () => {
  return (
    <div className='text-white bg-black flex justify-between'>
      <div className='w-full max-w-[1380px] mx-auto h-full'>
        <div className="w-full flex items-center justify-between h-full relative">
          {/* Line and SVG */}
          <div className='flex flex-col justify-start items-center gap-y-6 place-self-start h-full'>
            <motion.div className='relative '
              initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.3}} viewport={{once: false}}
            >
              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="1.5"/>
                  <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="white" strokeWidth="1.5" stroke-linecap="round"/>
                <circle cx="12" cy="16" r="1" fill="white"/>
              </svg>
              <span className='absolute left-0 top-0 h-full w-full bg-[#7ee787] blur-lg'></span>
            </motion.div>
            <motion.div style={{
                background: 'linear-gradient(#d2a8ff, #a371f7 10%, #196c2e 70%, #2ea043 80%, #56d364)',
              }}
              className='max-md:w-[2px] w-[3px] h-[1010px]  md:h-[660px] lg:h-[590px] xl:h-[560px] rounded-md line'
              initial={{scaleY: 0}} whileInView={{scaleY: 1, transformOrigin: "top"}} transition={{duration: 0.7, delay: 0.6}}              
            ></motion.div>
          </div>
          
          {/* Why Us */}
          <div className='w-full flex flex-col items-center gap-y-6 '>
            <motion.h2
              initial={{opacity: 0, y: 38}} whileInView={{opacity: 1, y:0}} transition={{duration: 0.3, delay: 0.3}} viewport={{once: false}} 
              style={{fontFamily: 'Great Vibes', fontWeight: 600}}
              className='text-7xl sm:text-8xl md:text-9xl'
            >
              Why Us
            </motion.h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4 place-items-center justify-items-center py-10 px-10'>
              {
                WhyNeongasmic.map((item, index) => ( 
                  <div key={index} className='w-full p-[1px] border h-full rounded-lg bg-gradient-to-r from-red-500 via-blue-500 to-purple-500'>
                    <div className='flex items-center gap-x-3 justify-center bg-black h-full w-full px-4 py-6 rounded-lg'>
                      <motion.div className='flex items-center justify-center w-12 h-12 rounded-full  relative'
                        initial={{scale: 0}} whileInView={{scale: 1}} transition={{duration: 0.3, delay: 0.3}}
                      > 
                        <div 
                          style={{background: item.color}}
                          className={`w-full h-full absolute blur-xl`}></div>
                        {item.icon}
                      </motion.div>
                      <motion.div className='max-md:w-[80%] w-[70%]'
                        initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.3, delay: 0.3}}
                      >
                        <h3 className='text-xl font-Lato'>{item.title}</h3>
                        <p className='text-gray-400'>{item.description}</p>
                      </motion.div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
            
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
